import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { initVideosPage } from './InitializePlugins';
import axios from 'axios';
import BackgroundImage from 'react-background-image-loader';
import { Globals } from './constants';
import {VideoCardFourByThree, space2hyphen} from './HelperFunctions';

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
        customCssTransition: 'h-transition',
        isLoading: true,
        allVideosClass: '',
        featuredClass: '',
        archivesClass: '',
        filter: '',
        apiPath: Globals.ApiUrl+'api/v1/',
        page: 1,
        per_page: 5,
        pagesEnd: false,
        apiResp: [],
        font1p3: { fontSize: '1.3em'},
        font27px: {fontSize: '27px' },
        height268: { height: '238px' },
        height234: { height: '207px' },
        cursorPointer: {cursor: 'pointer'}
    }
    this.externalScriptsHandler = initVideosPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.windowLoadListener = false;
  
};

componentDidMount=async()=>{
        let filter;

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    
        if (document.readyState === 'complete') { // if document has finished loading all the scripts
          this.externalScriptsHandler.call(); // call videos swipe initialization
        } else { // if document is still loading scripts i.e readyState === 'loading'
          this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
          window.addEventListener('load', this.externalScriptsHandler); // add listener
        };


        if(this.props.type && this.props.type == 'allVideos'){
            filter = 'allVideos';
            this.setState({allVideosClass: 'border-bottom border-danger', featuredClass:'', archivesClass:''});
        }else if(this.props.type && this.props.type == 'featured'){
            filter= 'featured';
            this.setState({featuredClass: 'border-bottom border-danger', allVideosClass:'', archivesClass:''});
        }else if(this.props.type && this.props.type == 'archives'){
            filter = 'archives';
            this.setState({archivesClass: 'border-bottom border-danger', allVideosClass:'', featuredClass:''});
        }else{
            filter='allVideos';
            this.setState({allVideosClass: 'border-bottom border-danger', featuredClass:'', archivesClass:''});
        }
        this.setState({filter: filter});

        let apiPath = this.state.apiPath,
            page = this.state.page,
            per_page = this.state.per_page,
            resp= [],
            videoType,
            url = apiPath+"user_api/get_new_series?page="+page+"&per_page="+per_page+"&telco="+this.props.telco
            if(filter == 'archives' || filter == 'featured'){
                videoType = (filter=='archives')? 'archive': 'in_house_content';
                url = apiPath+"video/get_by_video_type/"+videoType+"/"+page+"/"+per_page+"?telco="+this.props.telco
            }
        try{
            resp = await axios.get(url);
        }
        catch(error){
            console.log('Error fetching all videos');
            window.location.href = '/404notfound';
        }

        if(resp.status == 200){
            const apiResp = this.state.apiResp.slice();
            if(filter == 'archives' || filter == 'featured'){
                resp.data.videos.forEach( (video) => {apiResp.push(video);})
            }else{
                resp.data.series.forEach( (series) => {apiResp.push(series);})
            }
            
                        
            this.setState({apiResp: apiResp, customCssTransition: 'v-transition', isLoading: false}, () => {console.log(filter+' fetched state set', this.state.apiResp)});
        }else{
            console.log('Error fetching all videos!');
            window.location.href = '/404notfound';
        }

    }
   
 componentWillUnMount=()=> {
    if (this.windowLoadListener){
        window.removeEventListener('load', this.externalScriptsHandler); //remove listener if listening
    } 
  }
 
  getContainers() {
    if(this.state.filter == 'archives' || this.state.filter == 'featured'){
        return this.state.apiResp.map( (video, i) => {
            return <VideoCardFourByThree key={'vc-'+i} data={video}></VideoCardFourByThree>
        })
    }else{
        return this.state.apiResp.map( (series, i) => {
            return <VideosContainer key={'vc-'+i} height268={this.state.height268} height234={this.state.height234} data={series} ></VideosContainer>
        })
    }
    
  }

  async nextPage(e){
    if(!this.state.pagesEnd){
        let elem = document.getElementsByClassName('loading-more-results');
        if(elem.length)
            elem[0].classList.remove('d-none');

        let apiPath = this.state.apiPath,
        page = this.state.page + 1,
        filter = this.state.filter,
        per_page = this.state.per_page,
        videoType,
        resp= [],
        url = apiPath+"user_api/get_new_series?page="+page.toString()+"&per_page="+per_page+"&telco="+this.props.telco
        if(filter == 'archives' || filter == 'featured'){
            videoType = (filter=='archives')? 'archive': 'in_house_content';
            url = apiPath+"video/get_by_video_type/"+videoType+"/"+page+"/"+per_page+"?telco="+this.props.telco
        }

        try {
            resp = await axios.get(url);
        }
        catch(err) {
        console.log("error fetching next page");
        };

        if(resp.status == 200){
            if(elem.length)
                elem[0].classList.add('d-none');

            const apiResp = this.state.apiResp.slice();

            if(filter == 'archives' || filter == 'featured'){
                if (resp.data.videos.length===0){
                    this.setState({pagesEnd: true});
                }else{
                    resp.data.videos.forEach( (video) => {apiResp.push(video);})
                }
            }else{
                console.log('lookhere', resp);
                if (resp.data.series.length===0){
                    this.setState({pagesEnd: true});
                }else{
                    resp.data.series.forEach( (series) => {apiResp.push(series);})
                }
                
            }

            this.setState({page: page, apiResp: apiResp}, () => console.log("Next page state set", this.state));
        }else{
            if(elem.length)
                elem[0].classList.add('d-none');
            console.log("error fetching next page");
        }

    }
  }

  render() {
    return (

      <section id="main-section" className="align-self-start">
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }

        <div className={this.state.customCssTransition}>

        <div className="card p-3">
            <div className="d-flex overflow-x-auto">
                <h4 className={this.state.allVideosClass+" font-weight-semi-bold p-2 m-f-14"}>
                    <Link className="alert-heading" to="/videos">All Videos</Link>
                </h4>
                <h4 className={this.state.featuredClass+" font-weight-semi-bold p-2 ml-4 m-f-14"}>
                    <Link className="alert-heading" to="/videos/featured">Featured</Link>
                </h4>
                <h4 className={this.state.archivesClass+" font-weight-semi-bold p-2 ml-4 m-f-14"}>
                    <Link className="alert-heading" to="/videos/archives">Archives</Link>
                </h4>
            </div>
            
        </div>

          <div id="all-videos" className="mt-2">


          <div className="card px-3 py-0">

              {/* <div className="search row d-none">
                  <div className="col-10 col-sm-11 col-md-8 col-lg-7 pr-1">
                      <input type="search" id="search-videos" className="nostyle rounded-18 border border-lgiht-grey text-primary py-2 px-4 w-100" style={this.state.font1p3} placeholder="Search videos by series name or by year" />    
                  </div>
                  <div className="col-2 col-sm-1 col-md-4 col-lg-5 pl-0 py-1">
                      <button id="btn-calendar" href="javascript:;" className="calendar-my nostyle text-primary" data-date-format="yyyy-mm-dd" data-date="2018-11-06" style={this.state.cursorPointer}>
                          <i className="fa fa-fw" aria-hidden="true" title="Choose Date" style={this.state.font27px}></i>
                      </button>    
                  </div>
              </div> */}

              {this.getContainers()}
              
                

                {/* <VideosContainerWCounter
                    height268={this.state.height268}
                    height234={this.state.height234}
                ></VideosContainerWCounter> */}

          </div> 
          {/* <!-- .card --> */}
      </div>

        {
            (!this.state.pagesEnd && this.state.apiResp.length) ?
            (
            <div>
                <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
            </div>
            )
            : <div></div>
        }

        </div>
      </section>


    );
  } 
}

const VideosList = ( props ) => (
    // <div>
    // {console.log(props.data.video.match_obj)}
    // </div>
    <Link className="text-primary"  to={"/series/"+props.seriesId+"/"+props.seriesName+"/videos/"+props.data.video.id}>
        <div className="vc-item px-0 pr-1">
            <BackgroundImage className="vc-item-img sixteen-by-nine bg-center-cover  position-relative" src={props.data.video.thumb} placeholder={Globals.placeholderPlain4x3}>
               <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div> 
            </BackgroundImage>
            {/* <div className="vc-item-img sixteen-by-nine bg-center-cover" style={{backgroundImage: 'url('+props.data.video.thumb+')'}}></div> */}
            <div className="vc-item-h1 mt-3 mb-2 px-2 text-nowrap text-truncate m-f-12">
                {props.data.video.match_obj.title} 
                {/* -  v Pak U19 W */}
            </div>
            <div className="vc-item-h2 px-2 truncate-v2 m-f-13">
                {props.data.video.title}
                {/* Day: 5 Session 5 Highlights Session 5 Highlights */}
            </div>
        </div>
    </Link>
)

const VideosContainerWCounter = ( props ) => (
    <div className="videos-collection-container">
        <div className="videos-collection mt-4">

            <div className="vc-header mb-2">
                <div className="vc-title text-nowrap text-truncate">
                        West Indies tour of india 2018/19
                </div>
                <div className="row align-items-end">
                    <div className="vc-title col-6 col-md-10 col-lg-9 col-xl-10 text-nowrap text-truncate">
                        <span className="vc-count">999 Videos</span>
                    </div>
                    <div className="vc-all col-6 col-md-2 col-lg-3 col-xl-2 text-right text-nowrap text-truncate">
                        <a href="#">View All</a>
                    </div>
                </div>
            </div>

            <div className="vc-list">

                

                <div className="vc-item px-0 pr-1">
                    <div className="vc-item-img sixteen-by-nine bg-center-cover" ></div>
                    <div className="vc-item-h1 mt-3 px-2 text-nowrap text-truncate">
                        5th Test - Aus v Pak 
                    </div>
                    <div className="vc-item-h2 px-2 truncate-v2">
                        Day: 5 Session 5 Highlights 
                    </div>
                </div>
                
                <div className="clearfix"></div>

            </div>

        </div>
    </div>
);

const getVideosList = (props) => {
    return props.data.videos.map( (video, i) => {
        if(video){
            return <VideosList key={'vl-'+i} data={video} seriesId={props.data.id} seriesName={space2hyphen(props.data.title)}></VideosList>
        }
    })
}
              
const VideosContainer = (props) => (
        <div className="latest-videos videos-collection-container py-2" style={props.height268}>
            <div className="videos-collection mt-2">

                <div className="vc-header mb-2">
                    <div className="d-flex align-items-end">
                        <div className="vc-title text-truncate m-f-14">
                            {props.data.short_name}
                        </div>
                        <div className="vc-all  text-right ml-auto text-truncate m-f-13">
                        <Link className="text-primary"  to={"/series/"+props.data.id+"/"+space2hyphen(props.data.title)+"/videos"}>
                            View All
                        </Link>
                        </div>
                    </div>
                </div>
            
                <div className="vc-list" style={props.height234}>

                {getVideosList(props)}


                {/* <div className="vc-item px-0 pr-1">
                    <div className="vc-item-img sixteen-by-nine bg-center-cover" style={props.image}>

                    </div>
                    <div className="vc-item-h1 mt-3 px-2 text-nowrap text-truncate">
                        5th Test - Aus v Pak 
                    </div>
                    <div className="vc-item-h2 px-2 truncate-v2">
                        Day: 5 Session 5 Highlights 
                    </div>
                </div> */}

                <div className="clearfix"></div>

                </div>
            </div>

        </div>
);

export default Videos;
