import React, { Component } from 'react';
import {autoLogin} from './HelperFunctions';


import { Globals } from './constants';
import {Helmet} from "react-helmet";


class Tests extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidMount(){
      autoLogin();
    }

  render() {
    return(
      <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        yolo
      </section>
    )
  }

}


export default Tests;
