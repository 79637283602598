import React, { Component } from 'react';
import JWPlayerOnScrollViewResize from './JWPlayerOnScrollViewResize';
import { SeriesNav } from './Series';
import { VideoCardDescription, getVideoPath } from './HelperFunctions';
import ReactJWPlayer from 'react-jw-player';
import {Globals} from './constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToggleSwitch } from './MatchVideos';

class SingleVideo extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            isChecked: false,
            allVideosClass: '',
            featuredClass: '',
            archivesClass: '',
            videoIndex: 0,
            videos: [],
            videoId: this.props.match.params.videoId,
            seriesId: this.props.match.params.id,
            videoType: '',
            page: 1,
            pagesEnd: false

        };
        this.setAutoplay = this.setAutoplay.bind(this);
        this.playerReady = this.playerReady.bind(this);
        this.videoLoad = this.videoLoad.bind(this);
        this.nextVideoLoad = this.nextVideoLoad.bind(this);
        this.calendarSelect = this.calendarSelect.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.singleVideoCase = this.singleVideoCase.bind(this);
        this.isMobile = window.innerWidth < 992;
        if (!localStorage.getItem('user')) window.location.href = '/login';

    };

    setAutoplay(e){
        let isChecked = this.state.isChecked
        let elem = document.getElementsByName('checkbox');
        if(elem.length){
            elem = elem[0]
            if(elem.checked){
                isChecked = 1;
            }else{
                isChecked = 0;
            }
            this.setState({isChecked: isChecked});
            localStorage.setItem('autoplay', isChecked)
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log("Next props", nextProps, "previous", this.props)
        if (nextProps.match.params.videoId!==this.props.match.params.videoId) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });

            this.singleVideoCase(nextProps.match.params.videoId);
        }
    }

    async singleVideoCase(videoId) {
        

        const url = Globals.ApiUrl+"api/v1/user_api/get_single_video?id="+videoId+"&telco="+this.props.telco;
        let resp1, resp2;
        try {
            resp1 = await axios.get(url);
            if (resp1.status!==200 || resp1.data.video===null) window.location.href = '/404notfound';
            if(resp1.data.video.video_type=='in_house_content')
                this.setState({featuredClass: 'border-bottom border-danger', allVideosClass:'', archivesClass:''});
            else if(resp1.data.video.video_type=='archive')
                this.setState({archivesClass: 'border-bottom border-danger', allVideosClass:'', featuredClass:''});
            else
                this.setState({allVideosClass: 'border-bottom border-danger', featuredClass:'', archivesClass:''});
            console.log("single video", resp1);
        }
        catch(err) {
            console.log(err);
            window.location.href = '/404notfound';
        }
        const videoType = (resp1.data && resp1.data.video) ? resp1.data.video.video_type : '';
        const url2 = Globals.ApiUrl+"api/v1/video/get_by_video_type/"+videoType+"/"+this.state.page+"/10?telco="+this.props.telco
        try {
            resp2 = await axios.get(url2);
            if (resp2.status!==200) window.location.href = '/404notfound';
            console.log("Single video related", resp2);
        }
        catch (err) {
            console.log(err);
            window.location.href = '/404notfound';
        };

        let singleVideo = [];
        singleVideo.push(resp1.data.video);
        const mergedVideos = singleVideo.concat(resp2.data.videos);

        this.setState({ videos: mergedVideos, videoId: videoId, videoType: videoType }, () => console.log("Set state ", this.state))

    }

    async componentDidMount() {
        //update nav
        // document.querySelector('#main-nav ul.navbar-nav #rankings.nav-item').classList.remove('active');
        // document.querySelector('#main-nav ul.navbar-nav #stories.nav-item').classList.remove('active');
        // document.querySelector('#main-nav ul.navbar-nav #videos.nav-item').classList.remove('active');
        // document.querySelector('#main-nav ul.navbar-nav #series.nav-item').classList.remove('active');
        // document.querySelector('#main-nav ul.navbar-nav #feeds.nav-item').classList.remove('active');

        // document.querySelector('#main-nav ul.navbar-nav #videos.nav-item').classList.add('active');

        let isChecked = localStorage.getItem('autoplay');
        if(isChecked == 1){
            this.setState({isChecked: true});
        }

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

        this.singleVideoCase(this.state.videoId);

        

    }
    calendarSelect(e) {
        console.log(e);
    };
    playerReady(e) {
        if (!this.isMobile)
            JWPlayerOnScrollViewResize();
    }
    videoLoad(e) {
        console.log(e);
        if (e.index!==0) {
            const videosCopy = this.state.videos.slice(1, this.state.videos.length);
            console.log("Videos copy", videosCopy, "old videos", this.state.videos);
            this.setState({ videos: videosCopy }, () => console.log("Next Video Loaded state", this.state));
        }
    }
    nextVideoLoad(e) {
        let elem = document.getElementsByName('checkbox')
        if(elem){
            elem = elem[0];
            if(elem.checked){
            let videoIndex = this.state.videoIndex;
                let videoElem = document.getElementsByClassName('mvlist');
                if(videoElem[videoIndex]){
                    if(this.state.videoId !== 'v'){
                        let v = videoIndex+ 1;
                        this.setState({videoIndex: v})
                    }
                    
                    videoElem[videoIndex].click();
                }
                
            }
        }
    }


    async nextPage(e) {

        if (!this.state.pagesEnd) {
        let elem = document.getElementsByClassName('loading-more-results');
        if(elem.length)
            elem[0].classList.remove('d-none');
      
          const page = this.state.page + 1;
          const url = Globals.ApiUrl+"api/v1/video/get_by_video_type/"+this.state.videoType+"/"+this.state.page+"/10?page="+page.toString()+"&per_page=10&telco="+this.props.telco
          let resp;
          try {
            resp = await axios.get(url);
            console.log("Next page", resp);
          }
          catch(err) {
            console.log("error fetching next page");
          };

          if (resp.status === 200 && resp.data && resp.data.videos.length > 0) {
            if(elem.length)
            elem[0].classList.add('d-none');

            const moreVideos = this.state.videos.concat(resp.data.videos);  

            this.setState({page: page, videos: moreVideos}, () => console.log("Next page state set", this.state));

          } else {
            if(elem.length)
                elem[0].classList.add('d-none');

            if (resp.data.length===0) this.setState({pagesEnd: true});

            console.log("Api Error or no results");
          };

        }

    }



  render() {

    let playlist = [], title = '', description = '', date = '';
    if (this.state.videos.length) {
        playlist = this.state.videos.map( obj => ( obj
         ? { file: obj.video_file } : '') );
        title = this.state.videos[0].title;
        description = this.state.videos[0].match_desc;
        date = this.state.videos[0].created_at;
    }
    
    
    return (

        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">

        <div className="card p-3">
            <div className="d-flex overflow-x-auto">
                <h4 className={this.state.allVideosClass+" font-weight-semi-bold p-2 m-f-14"}>
                    <Link className="alert-heading" to="/videos">All Videos</Link>
                </h4>
                <h4 className={this.state.featuredClass+" font-weight-semi-bold p-2 ml-4 m-f-14"}>
                    <Link className="alert-heading" to="/videos/featured">Featured</Link>
                </h4>
                <h4 className={this.state.archivesClass+" font-weight-semi-bold p-2 ml-4 m-f-14"}>
                    <Link className="alert-heading" to="/videos/archives">Archives</Link>
                </h4>
            </div>
            
        </div>
          
          <div className="card p-3 mt-2">
            {
                (this.state.videos.length) ?
                <div className="player-container">
                    <div className="player-position">
                        <ReactJWPlayer
                            playerId='player'
                            playerScript='https://content.jwplatform.com/libraries/IDzF9Zmk.js'
                            // playlist={playlist}
                            file = {getVideoPath(this.state.videos[0])}
                            onReady={this.playerReady}
                            onVideoLoad={this.videoLoad}
                            onOneHundredPercent={this.nextVideoLoad}
                        />
                    </div>
                </div> : <h5></h5>
            }
            <div className="px-2 pt-3 pb-0 d-flex">
                <VideoCardDescription title={title} description={description} date={date} showDescription={true} showDate={true}></VideoCardDescription>
                
            </div>
            {(this.state.videos.length) ?
            <div className="d-flex">
                    <div className="ml-auto navbar-expand">
                        <ul className="navbar-nav mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a data-original-title="Facebook" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href}>
                                <i className="fa fa-facebook"></i></a></li>
                        <li className="nav-item">
                            <a data-original-title="twitter" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" href={"http://www.twitter.com/share?text="+title+"&amp;url="+window.location.href+"/"}>
                                <i className="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a data-original-title="google-plus" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" href={"https://plus.google.com/share?url="+window.location.href+"/"}>
                                <i className="fa fa-google-plus google"></i>
                            </a>
                        </li>
                        </ul>
                    </div>
                </div>
                
            : <div></div> }
            <div className="text-right d-flex mb-3">
                {/* <Dropdown tagChange={this.tagChange}></Dropdown> */}
                <div className="dropdown my-3">&nbsp;</div>
                <ToggleSwitch checked={this.state.isChecked} setAutoplay={this.setAutoplay}></ToggleSwitch>
            </div> 

            {
                this.state.videos.map( (obj, i) => 
                    (i!==0) ?
                    <Link to={"/video/"+obj.id} key={"sv-"+i} className="d-flex p-2 mvlist">
                        <div className="w-30 pr-3">
                            <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: "url(" + obj.thumb + ")"}}></div>
                        </div>
                        <VideoCardDescription title={obj.title} description={obj.match_desc}
                         showDescription={true} showDate={true}></VideoCardDescription>
                    </Link> : <div key={"sv-"+i}></div>
                    )
            }
            
          </div>
          {
            (!this.state.pagesEnd && this.state.videos.length) ?
            (
            <div>
                <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" height="30" alt="" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
            </div>
            )
            : <div></div>
          }
          
        </section>


    );
  }
}


// const ToggleSwitch = () => (
//     <label className="switch mb-0">
//       <input type="checkbox" />
//       <span className="slider round"></span>
//     </label>
// );

const Dropdown = () => (
    <div className="dropdown custom-box">
        <button className="fa btn btn-secondary dropdown-toggle br-2rem px-3 py-2 mw-11rem d-flex justify-content-between
         align-items-center bg-white text-dark videos-menu" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <h5 className="font-weight-normal app-font mb-0 text-secondary">All Videos</h5>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="/">Action</a>
            <a className="dropdown-item" href="/">Another action</a>
            <a className="dropdown-item" href="/">Something else here</a>
        </div>
    </div>
)

export default SingleVideo;
