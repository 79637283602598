import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Globals } from './constants';
import { getUnsubPath } from './HelperFunctions';
import Axios from 'axios';

class Nav extends Component {
    constructor(props){
        super(props);
        this.state = {
            unsubBtnTxt : "Unsubscribe",
            unsubBtnDisabled : false,
            unsubPath: ""
        }
        this.getUserIcon = this.getUserIcon.bind(this);
        this.getUnsubButton = this.getUnsubButton.bind(this);
        this.unsub = this.unsub.bind(this);
    }

    componentDidMount() {
        
    }

    getTelcoLogo(){
        return(
                (Globals.telcoLogo)?
                (
                    <span className="telco-logo ml-3 ml-lg-4">
                        <img src={Globals.telcoLogo} alt="" />
                    </span>
                ):
                ('')
        )
        
    }

    getUserIcon(){
        if(this.props.userLoggedIn){
            return (
                <div className=" form-inline ml-auto p0 order-1 order-lg-2">

                    <a href="javascript:;" className="user" data-toggle="modal" data-target="#welcomeModal">
                        <img src="/images/profile_icon.png" alt="" />
                    </a>

                    {this.getTelcoLogo()}
                    
                </div>
            )
        }else{
            return(
                <div className="form-inline ml-auto p0 order-1 order-lg-2">


                    <Link to="/login" className="user ">
                        <img src="/images/profile_icon.png" alt="" />
                    </Link>     

                    {this.getTelcoLogo()}
                    
                </div>
            )
        }
    }

    logout(e){
        localStorage.removeItem('user');
        localStorage.removeItem('user_logged_in');
        window.location.reload();
    }

    getUnsubButton() {
        if(this.props.userLoggedIn){
            let unsubPathObj = getUnsubPath(JSON.parse(localStorage.getItem('user')));
            if(unsubPathObj.status === 1 && unsubPathObj.path){
                return(
                    <div className="button">
                        <button type="button" className="btn btn-lg btn-primary allow-login mt-2" style={{position: 'relative', zIndex: '1'}}  unsubpath={unsubPathObj.path} onClick={this.unsub} disabled={this.state.unsubBtnDisabled}>{this.state.unsubBtnTxt}</button>
                    </div>
                )
            }
        }
    }

    async unsub(e){
        // console.log('tahir', e.target.getAttribute('unsubpath'));
        this.setState({unsubBtnTxt: "Please wait ...", unsubBtnDisabled: true});

        let resp = {},
            url = e.target.getAttribute('unsubpath');
        try{
            resp = await Axios.get(url);
        }catch{
            console.log('tahir unsub failed');
        }

        this.logout();
        
    }


  render() {
    return (
        <nav id="main-nav" className="navbar navbar-expand-lg navbar-light py-0 shadow-md mb-2">
         <div className="container">              
            <button className="navbar-toggler p-0 collapsed" type="button" data-toggle="collapse" data-target="#navbarContent">
                <i className="fa fa-bars"></i>
            </button>

            <Link to="/" className="navbar-brand mr-auto pl-3 py-1 p-0 pb-lg-2 pt-lg-1 pl-lg-0 mr-lg-5 order-0">
                <img className="logo" src={Globals.logo} alt="" />
            </Link> 

            {this.getUserIcon()}
                         
            <div className="collapse navbar-collapse m-0 p-0 order-2 order-lg-1" id="navbarContent">
                 <div className="divider"></div>
                 <ul className="navbar-nav ml-lg-4 ml-0">
                     <li id="feeds" className={(this.props.currentPage==="/") ? 'active nav-item px-3' : 'nav-item px-3'}>
                         <Link className="nav-link"  to="/">
                            Feeds
                            <i className="fa fa-angle-right d-lg-none float-right" aria-hidden="true"></i>
                        </Link>
                         
                     </li>
                     <li id="series" className={(this.props.currentPage==="/allseries" || this.props.currentPage==="/series") 
                     ? 'active nav-item px-3' : 'nav-item px-3'}>
                         <Link className="nav-link" to="/allseries/live">Series <i className="fa fa-angle-right d-lg-none float-right" aria-hidden="true"></i></Link>

                     </li>
                     <li id="videos" className={(this.props.currentPage==="/videos" || this.props.currentPage==="/video") ? 'active nav-item px-3' : 'nav-item px-3'}>
                         <Link className="nav-link" to="/videos">Videos <i className="fa fa-angle-right d-lg-none float-right" aria-hidden="true"></i></Link>
                     </li>
                     <li id="stories" className={(this.props.currentPage==="/stories") ? 'active nav-item px-3' : 'nav-item px-3'}>
                         <Link className="nav-link" to="/stories">Stories <i className="fa fa-angle-right d-lg-none float-right" aria-hidden="true"></i></Link>
                     </li>
                     <li id="rankings" className={(this.props.currentPage==="/rankings") ? 'active nav-item px-3' : 'nav-item px-3'}>
                         <Link className="nav-link"   to="/rankings">Rankings <i className="fa fa-angle-right d-lg-none float-right" aria-hidden="true"></i></Link>
                     </li>
                 </ul>
             </div>          </div>

             <div className="modal fade" id="welcomeModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{marginTop: '150px'}} >
                    <div className="modal-content login-dialogue" >
                        <a href="javascript:;" className="modal-close text-white"  data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </a>
                        <div className="modal-body p-0" style={{backgroundColor:'#fff'}}>
                        <div className="divide2">
                            <div className="divide-content" style={{minHeight: 'auto'}} >
                            
                                <div className="text-center"  style={{ width: '100%', textAlign: 'center', zIndex: '1', paddingBottom: '10px'}}>
                                    <div className="text-center"  style={{ width: '100%', textAlign: 'center', zIndex: '1', paddingBottom: '10px'}}>
                                        <div className="inner-text-login text-left">
                                            <span className="text-jazz-login">{Globals.product}</span><br/>
                                            <span className="text-login" >Welcome: </span><span className="status">{this.props.userPhone}</span><br/>
                                            <span className="text-login">Subscription Status:  </span><span className="status">{this.props.userStatus}</span><br/>
                                            {this.props.user && this.props.user.details &&(this.props.user.details && this.props.user.details.activated_at) ? (
                                                <span><span className="text-login">Subscription Date:  </span><span className="status">{this.props.user.details.activated_at}</span><br/></span>
                                            ):""}
                                            {(this.props.user && this.props.user.details &&this.props.user.details && this.props.user.details.last_charged_time) ? (
                                                <span><span className="text-login">Last Renewal:  </span><span className="status">{this.props.user.details.last_charged_time}</span><br/></span>
                                            ):""}
                                            {(this.props.user && this.props.user.details &&this.props.user.details && this.props.user.details.sub_plan) ? (
                                                <span><span className="text-login">Price:  </span><span className="status">{this.props.user.details.sub_plan}</span><br/></span>
                                            ):""}
                                        </div>

                                        { this.getUnsubButton()}

                                        <div className="button">
                                            <button type="button" className="btn btn-lg btn-primary allow-login mt-2" style={{position: 'relative', zIndex: '1'}} data-dismiss="modal" onClick={this.logout}>Logout</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
       </nav>


    );
  }
}

export default withRouter(Nav);
