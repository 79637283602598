import React, {Component}  from 'react';
import Loading             from './common/loading';
import { Globals }         from '../constants';
import {space2hyphen}      from '../HelperFunctions';
import { Link }            from "react-router-dom";
import axios               from 'axios';

class LeftSeriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesApiPath: Globals.ApiUrl + 'api/v2/series/featured',
      seriesApiResp: [],
    //   updateApiPath: Globals.ApiUrl + 'api/news?page=1&per_page=6',
    //   updateApiResp: [],

      error: null, //by Tariq
      isLoading: true, //by Tariq
      isLoaded: false, //by Tariq
      counter: 0, //by Tariq

    //   phone: '',
    //   pin: '',
    //   inputName: 'phone',
    //   inputValue: '',
    //   phonePlaceholder: Globals.login.phonePlaceholder,
    //   pinSent: false,
    //   pinPlaceholder: Globals.login.pinPlaceholder,
    //   task: 'phone',
    //   user: [],
    //   redirect: false,
    //   btnTxt: 'Continue',
    //   btnDisbaled: false,
      // apiPath: 'https://mobisub.cricwick.mobi/main/',
    //   message: '',
    //   heading: Globals.login.enterNumber,
    //   enterPin: Globals.login.enterPin,
      // heading: 'Please Enter Your Jazz Number. Prepaid users will be charged 2.38 per day.',
    //   strings: {
    //     somethingWentWrong: 'Something went wrong. Please try again later!',
    //     invalidPin: 'Invalid Pincode!',
    //     invalidPhone: Globals.login.invalidPhone,
    //   },
    };

  }

  componentDidMount= async()=> {
    const {counter} =  this.state;
    await this.getSeriesRecords();
    if(this.state.error){
        await this.knockingNetWorking();
        await this.setState(()=> {return{counter : this.state.counter +  1}});
     }
  }

  //========================New Method By Tariq - Actual =================================
  getSeriesRecords = async () => {
    let url = this.state.seriesApiPath,
              resp;
    try {
                        resp = await axios.get(url);
                        this.setState(() => {
                        return {
                                   seriesApiResp: resp.data.featured_series,
                                           error: null,
                                       isLoading: false,
                                        isLoaded: true,
                                        counter : 0,
                               };
                        });
    }catch (error) {
                       console.log('error fetching series');
                       this.setState(() => {
                             return {error: error, isLoaded:false};
                       });
    }

}
//========================================================================

knockingNetWorking=async()=>{
  let i = 0;
  for(let i=0; i < 3; i++ ){
      await setTimeout(()=>{
        this.getSeriesRecords();
        console.log("Its seems that your Internet Connection is Slow/Disconnected......!", i, this.state.isLoaded , this.state.error.message, this.state.counter);
        i = i +  1;
        this.setState(()=> {return{counter :  i}});
      }, 15000 );

      if(this.state.isLoaded)
             return;
   }
}

//==================================End========================================
    render() {
    const {seriesApiResp, isLoading, error, counter} =  this.state;

            // if(counter===3){
            //     return(
            //             <div className="latest-updates card px-3 py-3 mt-3">
            //                  Internal Network and Internet connection error! <br/>
            //                 <button className="btn btn-primary" onClick={()=>this.getSeriesRecords()}>Retry..</button>
            //             </div>
            //           );
            if(isLoading){
                return(<div className="latest-updates card px-3 py-3 mt-3">
                                 <h5 className="pb-3">Featured Series</h5>
                                 <Loading />
                       </div>);
            }else if(error){
                return(<div className="latest-updates card px-3 py-3 mt-3">
                           <h5 className="pb-3">Latest Updates {error.message}</h5>
                            <Loading />
                      </div>);
            }else{
                return (
                  <div className="featured-series card px-3 py-3">
                    <h5 className="pb-3">Featured Series</h5>
                        <ul>
                            {seriesApiResp.map((series, i) => (
                                <li key={'fel-' + i}>
                                {/* <Link to={"/series/"+series.id}>{series.short_name}</Link> */}
                                {/*<a href={"/series/"+series.id}>{series.short_name}</a>*/}
                                {/*<Link to={"/series/"+series.id}>{series.short_name}</Link>*/}
                                <Link  to={ `/series/${series.id}/${space2hyphen(series.short_name)}`} >
                                    {series.short_name}
                                </Link>
                                </li>
                            ))}
                        </ul>
                  </div>
                );
            }
   }

}

export default LeftSeriesList;
