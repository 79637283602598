import React from 'react';
import { Link } from "react-router-dom";
import BackgroundImage from 'react-background-image-loader';
import { Globals } from './constants';
import axios from 'axios';
import { type } from 'os';
const queryString = require('query-string');

//Home
export const VideosListFourByThree = (props) => (
  <div className="card my-2 p-3">
    {
      props.data.data.map((obj, i) => <VideoCardFourByThree key={"vid-cell-"+i} data={obj}></VideoCardFourByThree>)
    }
  </div>
)

export const VideoCardFourByThree = (props) => (
<Link to={"/video/"+props.data.id} className="d-flex my-2">
   <div className="w-30 position-relative">
       {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: "url(" + props.data.thumb + ")"}}></div> */}
       <BackgroundImage className="sixteen-by-nine bg-center-cover" src={props.data.thumb} placeholder={Globals.placeholderPlain4x3}>
       <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div>
        </BackgroundImage>
   </div>
   <div className="pl-3 flex-fill">
     <VideoCardDescription data={props.data} title={props.data.title} description={props.data.match_desc}
      showDescription={true} showDate={true} showRedTitle={false}></VideoCardDescription>
   </div>
 </Link>
);

export const Rankings = (props) => (
  <div className="card my-2 p-3">

    <div className="d-flex justify-content-between align-items-center px-lg-2 pb-3">
      <h4 className="mb-0 text-uppercase font-weight-bold m-f-14">ICC Rankings - <span className="text-secondary text-uppercase">
      {
        (props.currentRankings.indexOf('test')!==-1) ? 'test' : props.currentRankings
      }
      </span></h4>

      <RankingsDropdown
       selected={props.currentRankings}
        change={props.rankingsChange}>
      </RankingsDropdown>

    </div>

    <div>
      <RankingsRow selected={props.currentRankings} title="All Rounders"
         data={props.data.data[props.currentRankings]['all_rounder']}
         title="All Rounders" key="rr-1"></RankingsRow>

      <RankingsRow selected={props.currentRankings} title="Batsmen"
         data={props.data.data[props.currentRankings]['batsmen']}
         title="Batsmen" key="rr-2"></RankingsRow>

      <RankingsRow selected={props.currentRankings} title="Bowler"
         data={props.data.data[props.currentRankings]['bowler']} key="rr-3"></RankingsRow>
    </div>



  </div>
)

export const InHouseContent = (props) => (
  <div className="card p-3 my-2">
    <h4 className="mt-2 mb-4 ml-lg-2 text-uppercase font-weight-semi-bold m-f-14">{props.data.title}</h4>
    {
      props.data.data.map( (obj, i) =>
        <HomeArchiveCard key={"h-arc-"+i} id={obj.id} image={obj.thumb} date={obj.created_at} title={obj.title}></HomeArchiveCard>
      )
    }
  </div>
);

export const MobileFeaturedSeries = (props) => (
  <div className="card mb-2 d-lg-none">
    <button className="d-flex justify-content-between align-items-center p-3 btn bg-white w-100 btn-light collapsed"
     type="button" data-toggle="collapse" data-target="#featuredSeriesMobile"
      aria-expanded="true" aria-controls="featuredSeriesMobile">
        <h4 className="text-uppercase font-weight-semi-bold mb-0 py-2 m-f-14">Featured Series</h4>
        <h4 className="font-weight-semi-bold mb-0"><i className="fa font-weight-semi-bold"></i></h4>
        <i className="fa videos-menu font-weight-semi-bold"></i>
    </button>
    <div className="collapse " id="featuredSeriesMobile">
      <FeaturedSeriesCard data={props.series}></FeaturedSeriesCard>
    </div>
  </div>
);

export const HomeArchiveCard = (props) => (
  <Link to={"/video/"+props.id} className="d-flex mb-2">

    <div className="w-30">
      {/* <div className="four-by-three bg-center-cover" style={{backgroundImage: "url(" + props.image + ")"}}></div> */}
      <BackgroundImage className="four-by-three bg-center-cover" src={props.image} placeholder={Globals.placeholder4x3}>
                                 </BackgroundImage>
    </div>
    <div className="pl-3 custom-clamp-text">
      <h5 className="font-weight-normal text-secondary m-f-12">{getDateFunc(props.date)}</h5>
      <h3 className="font-weight-semi-bold m-f-14">{props.title}</h3>
    </div>

  </Link>
);

export const RankingsDropdown = (props) => (
  <div className="dropdown">
    <button type="button" className="btn btn-light rounded-circle w-3rem h-3rem d-flex justify-content-center align-items-center
      fa rankings-dropdown dropdown-toggle" id="dropdownMenuRankings"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    </button>
    <div className="dropdown-menu dropdown-menu-right h-r-dd p-0" aria-labelledby="dropdownMenuRankings">
      <button type="button" onClick={props.change} id="odi"
       className={ (props.selected==='odi') ? "btn btn-light bg-white dropdown-item active" : "btn btn-light bg-white dropdown-item" }>ODI</button>
      <button type="button" onClick={props.change} id="t20"
       className={ (props.selected==='t20') ? "btn btn-light bg-white dropdown-item active" : "btn btn-light bg-white dropdown-item"}>T20</button>
      <button type="button" onClick={props.change} id="test_"
       className={ (props.selected==='test_') ? "btn btn-light bg-white dropdown-item active" : "btn btn-light bg-white dropdown-item"}>TEST</button>
    </div>
  </div>
);

export const RankingsRow = (props) => (
  <div className="border-top">

    <h5 className="font-weight-normal text-uppercase py-3 px-lg-2 m-f-14">{props.title}</h5>

    <div className="d-flex justify-content-around pb-3">

      {
        props.data.map( (obj, i) => <RankingsCell rank={i+1} key={"rank-r-"+i} data={obj}></RankingsCell>)
      }

    </div>

  </div>

);

export const RankingsCell = (props) => (
   <div className="w-100 text-center">
    <div className="w-65px mx-auto">
      <div className="one-by-one-square rounded-circle bg-center-cover"
       style={{backgroundImage: "url(" + props.data.player_display_picture + ")"}}></div>


    </div>
    <h4 className="text-center mt-3 font-weight-normal m-f-14">{props.rank+". "}{props.data.player_name}</h4>
  </div>
);

//series card

export const returnScorecards = (props) => props.map( (match, i) =>
        <Scorecard data={match} key={"score-c-"+i}></Scorecard>
    );

export const returnScorecardIfLiveMatch = (props) => props.map( (match, i) => (match.match_state.toLowerCase().indexOf('live') > -1)
        ? <Scorecard data={match} key={"score-c-"+i}></Scorecard>
        : <div key={"score-c"+i}></div>
    );

export const getFwImage = (data) => (data.hasOwnProperty('thumb')) ? data.thumb : data.image;

export const FullWidthCard = (props) => {
    let customLink = '';
    if (props && props.data) {
        if (props.type==='video' || props.subType==='video') {
            customLink = '/video/'+props.data.id;
        }
        else if (props.type==='news' || props.subType==='news') {

            customLink = '/stories/news/'+props.data.id;

        } else if (props.type==='articles' || props.subType==='article') {
            customLink = '/stories/articles/'+props.data.id;
        }
        if (props.type==='video' && props.seriesId) {
            customLink = '/series/'+props.seriesId+"/"+space2hyphen(props.seriesTitle)+'/videos/'+props.data.id;
        }
        if (props.type==='video' && props.seriesPropsId) {
            customLink = '/series/'+props.seriesPropsId+"/"+space2hyphen(props.seriesTitle)+'/videos/'+props.data.id;
        }
    }

    return (props.data) ?

    <Link to={customLink} className="video-card-links">
        <div className="w-100 position-relative">
            {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: "url(" + getFwImage(props.data) + ")"}}></div> */}
            <BackgroundImage className="sixteen-by-nine bg-center-cover" src={getFwImage(props.data)} placeholder={(props.subType && props.subType=='video')? Globals.placeholderPlain4x3: Globals.placeholder4x3}>
                                 </BackgroundImage>
            {props.data.hasOwnProperty('video_file') ? <div className="fa fa-play-circle play-btn-lg"></div> : <div></div>}
        </div>
        <h3 className="font-weight-semi-bold mt-3 mb-4 m-f-13">{props.data.title}</h3>
    </Link> : <div></div>
}

export const runsAndWicketsExpression = (obj, i) => {

    let str = "";

    if (obj.wickets === 10) {
        str = returnDashIfNull(obj.runs);
    }
    if (obj.declared) {
        str = returnDashIfNull(obj.runs)+'/'+returnDashIfNull(obj.wickets)+'d';
    }
    if (obj.is_followed_on) {
        str = returnDashIfNull(obj.runs)+'/'+returnDashIfNull(obj.wickets)+'f';
    }
    if (!(i===0)) {
        str = ' & '+str+' ('+returnDashIfNull(obj.overs)+')';
    }
    return str;

}

export const getRunsAndWickets = (innings) => (innings) ? innings.map( (obj, i) => runsAndWicketsExpression(obj, i) ) : '';

export const getLiveMatchButton = (props) => (
    (props.data.hasOwnProperty('match_state') && props.data.match_state.toLowerCase().indexOf('live') > -1
        && props.data.live_stream_url) ?
        <Link to={"/match/"+props.data.id+"/summary/allowStream"} className="btn btn-light w-100 rounded-0  bg-white border mb-3">

            <h5 className="text-center font-weight-bold mb-0 py-2">{props.matchButtonText || "Watch Now!"}
                <span className="text-uppercase red px-2">Live Stream</span>
                <i className="fa fa-angle-right red font-weight-bold"></i>
            </h5>

        </Link> : <div></div>
)
export const getMatchStateText = (props) => {
  let matchState = props.match_state;
  let matchBreak = props.break_type;
  if(matchBreak){
    return matchBreak.charAt(0).toUpperCase() + matchBreak.slice(1);
  }else{
    if (matchState.toLowerCase().indexOf('live') > -1) {
        return 'Live';
    } else if (matchState.toLowerCase().indexOf('over') > -1) {
        return 'Result';
    } else if (matchState.toLowerCase().indexOf('scheduled') > -1) {
        return 'Upcoming';
    };
  }

  return matchState;
}
export const getScorecard = (props) => {

    let inningOne = props.data.teamA.innings;
    let inningTwo = props.data.teamB.innings;

    if (!inningOne && !inningTwo) {
        return <MatchScorecardSection showPageLinkForStream={true} data={props.data} />
    }

    return <div className="custom-box mb-2 pb-2">
        <div className="px-2 py-2 d-flex justify-content-between">
            <h5 className="font-weight-normal m-f-12 mb-0">
            {props.data.title}
            {
                (props.data && props.data.format && props.data.format.toString().toLowerCase() === 'test' && props.data.day) ? (" - Day "+props.data.day) : ""
            }
            {

                (props.data && props.data.venue) ? (", "+props.data.venue.title) : ""
            }
            </h5>
            <h5 className="red mb-0 m-f-12">
              {getMatchStateText(props.data)}
            </h5>
        </div>
        <div className="px-2 d-flex justify-content-between">
            <h5 className="text-uppercase m-f-14 font-weight-bold mb-0">{props.data.teamA.short_name}</h5>
            <h5 className="mb-0 m-f-14 font-weight-bold ">
            {
                getRunsAndWickets(props.data.teamA.innings)
            }

            </h5>
        </div>
        <div className="px-2 d-flex justify-content-between">
            <h5 className="text-uppercase m-f-14 font-weight-bold mb-0">{props.data.teamB.short_name}</h5>
            <h5 className="mb-0 m-f-14 font-weight-bold">
            {
                getRunsAndWickets(props.data.teamB.innings)
            }
            </h5>
        </div>
        {
            (props.data.detail || props.data.match_news || props.data.match_result)?
            <h4 className="px-2 text-center m-f-14 mt-2 mb-0 font-weight-semi-bold"
            dangerouslySetInnerHTML={{__html: props.data.match_news || props.data.match_result}}>
            </h4> :<div></div>
        }

        {/* {
            getLiveMatchButton(props)

        } */}
    </div>
}

export const Scorecard = (props) => (
        <div>
            {
                (props.data.match_state.toLowerCase().indexOf('live') > -1 || props.data.match_state.toLowerCase().indexOf('over') > -1) ?
                <Link className="scorecard-link" to={"/match/"+props.data.id+"/summary/1"}>{getScorecard(props)} </Link> :
                getScorecard(props)
            }
                {
                    getLiveMatchButton(props)

                }
        </div>

);


export const NewsBulletins = (props) => (
    (props.news.length) ?
    <ul className="p-3 my-3 custom-box news-bulletins">
        {props.news.map( (obj, i) =>
            <li key={"nb-"+i} style={{listStylePosition: 'insidse'}}>
                <Link to={"/stories/news/"+obj.id} className="my-2 my-lg-3 m-f-13 font-weight-semi-bold"> {obj.title}</Link>

            </li>
        )}
    </ul> : <div></div>
);

export const getDate = (props) => {
    let datestr,
    date = '';
    if (typeof props.data !== 'undefined' && props.data.created_at) {
        datestr = props.data.created_at;
        date = new Date(datestr).toDateString().split(' ');
        date = date[2]+" "+date[1]+", "+date[3];
    }    return date;
};

export const getDateFunc = (props) => {
    let datestr,
    date = '';
    if (typeof props !== 'undefined' && props) {
        datestr = props;
        date = new Date(datestr).toDateString().split(' ');
        date = date[2]+" "+date[1]+", "+date[3];
    }    return date;
};

export const getDateWithoutYear = (props) => {
    let datestr,
    date = '';
    if (typeof props !== 'undefined' && props) {
        datestr = props;
        date = new Date(datestr).toDateString().split(' ');
        date = date[2]+" "+date[1];
    }
    return date;
};

export const getTimeInAmPm = (props) => {
    let datestr,
    date = '';
    if (typeof props !== 'undefined' && props) {
        datestr = props;
        date = new Date(datestr).toTimeString().split(' ');
        date = date[0].split(':');
        let hours = parseInt(date[0]);
        const amPmStr = (hours >= 12) ? 'pm' : 'am';
        if (hours >= 12) hours = hours - 12;
        // console.log("Hours", hours);
        date = hours+':'+date[1]+amPmStr;
    }
    return date;
}

export const space2hyphen = ( props ) => {
    let title = props
    if(title){
        title = title.replace(/\s/g , "-");
        title = title.replace(/\//g , "_");
    }
    return title;
}
export const hyphen2space = ( props ) => {
    let title = props
    if(title){
        title = title.replace(/\-/g , " ");
        title = title.replace(/\_/g , "/");
    }
    return title;
}

export const showRedTitle = (props) => {
    let str;
    if (props.showRedTitle) {
        (str = <h5 className="font-weight-normal text-secondary m-f-12 mb-0 text-nowrap-no-lh series-heading">
        {props.matchTitle || props.data.series_name}</h5>);
    }

    return str;
 };

 export const VideoCardDescription = (props) => (
    <div className="custom-clamp-text flex-fill">
        <div className="d-flex justify-content-between">
            {showRedTitle(props)}
            <p className="font-weight-normal text-secondary mb-0 m-f-11 text-nowrap-no-lh mw-75px">
                {props.showDate ? getDate(props)  : ""}
            </p>
        </div>
        <h4 className="font-weight-semi-bold mb-0 m-f-12 d-block text-nowrap-no-lh">{props.title}</h4>
        <h5 className="font-weight-normal mb-0 d-block text-nowrap-no-lh m-f-12">
            {props.showDescription ? props.description : ""}</h5>
    </div>
 );

export const checkThumbType = (props) => (props.hasOwnProperty('thumb')) ? props.thumb : props.image;

export const VideoCard = (props) => {

    let customLink = ''
    if (props.matchId) {
        customLink = "/match/"+props.matchId+"/videos/"+props.id;
    } else if (props.seriesId) {
        customLink = "/series/"+props.seriesId+"/"+space2hyphen(props.seriesTitle)+"/videos/"+props.id;
    } else if (props.seriesPropsId) {
        customLink = "/series/"+props.seriesPropsId+"/"+space2hyphen(props.seriesTitle)+"/videos/"+props.id;

    }
    else {
        customLink = "/video/"+props.id;
    }

    return <Link to={customLink} className="d-flex mb-2 video-card-links">
        <div className="w-25 position-relative">

            {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: "url(" + checkThumbType(props) + ")"}}></div> */}
            <BackgroundImage className="sixteen-by-nine bg-center-cover " src={checkThumbType(props)} placeholder={Globals.placeholderPlain4x3}>
            <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div>
            </BackgroundImage>

        </div>
        <div className="pl-2 flex-fill">
            <VideoCardDescription title={props.title} matchTitle={props.matchTitle} description={props.description}
             showDescription={props.showDescription}></VideoCardDescription>
        </div>
    </Link>
}

export const NewsCard = (props) => {

    return <Link to={"/stories/articles/"+props.id} className="d-flex justify-content-between mb-2">

        <div className="pr-3 custom-clamp-text">
            <h3 className="font-weight-semi-bold m-f-13">{props.title}</h3>
            <h5 className="font-weight-normal text-secondary m-f-11">{getDateFunc(props.date)}</h5>
        </div>
        <div className="w-20">
            {/* <div className="one-by-one bg-center-cover" style={{backgroundImage: "url(" + props.image + ")"}}></div> */}
            <BackgroundImage className="one-by-one bg-center-cover" src={props.image} placeholder={Globals.placeholder4x3}>
                                 </BackgroundImage>
        </div>
    </Link>
}

//Left Section

export const FeaturedSeriesCard = (props) => (
    <div className="left-section">
        <div className="featured-series card  py-3">
            <ul className="mb-0">
                {getFeaturedSeriesCardList(props)}

            </ul>
        </div>


    </div>
);

export const getFeaturedSeriesCardList = (props) => {
    return props.data.map( (series, i) => {
        return <li key={'fscl'+i}><Link to={"/series/"+series.id+"/"+space2hyphen(series.short_name)}>{series.short_name}</Link></li>
    })
}

// match

export const returnDashIfNull = (props) => (!props) ? '0' : props;

export const MatchRunsAndWicketsExpression = (obj, i, inningsLength, noSpanClass) => {

    let str = returnDashIfNull(obj.runs);

    if (obj.wickets !== 10) {
        str = str+'/'+returnDashIfNull(obj.wickets);
    }
    if (obj.declared) {
        str = str+'d';
    }
    if (obj.is_followed_on) {
        str = str+'f';
    }
    if (!(i===0 || i===1)) {
        if(noSpanClass){
            str = " <span>&</span> "+str;
        }else{
            str = " <span className='text-medium-grey'>&</span> "+str;
        }
    }
    if (i===inningsLength-1) {
        str = str +' ('+returnDashIfNull(obj.overs)+')';
    }
    return str;

}
export const getFlagUrl = (team) => {
    let flagUrl = "/images/flag-placeholder.jpg"
    let teamFlagUrl = (team.full_flag_url) ? team.full_flag_url : team.flag_url;
    if(teamFlagUrl && !teamFlagUrl.includes('missing')){
        flagUrl = teamFlagUrl;
    }
    return flagUrl
}

export const MatchScorecardInningsDetails = (props, teamNumber, callFromMainCarousel) => {
    let team1_innings = 0, team2_innings = 0, total_innings=0;
    // let flagClass = (callFromMainCarousel) ? 'd-none w-20px mr-2' : 'w-20px mr-2';
    let flagClass = 'w-20px mr-2';
    if(props.innings){
        props.innings.forEach(function (inning){
            if(inning.batting_team_id === props.team_1_id){
                team1_innings++;
            }else{
                team2_innings++;
            }
            total_innings++;
        })
    }

    let flagUrl;
    if(teamNumber===1 && props.hasOwnProperty('teamA')){
        flagUrl = getFlagUrl(props.teamA)
    }
    if(teamNumber===2 && props.hasOwnProperty('teamB')){
        flagUrl =getFlagUrl(props.teamB)
    }

    let teamANameClass, teamBNameClass;
    if(teamNumber===1 && props.hasOwnProperty('teamA')){
        if(props.match_state && props.match_state.toLowerCase() === 'over'){
            //to be decided home list view not giving match_won_by_id to highlight winning team
        }else{

        }
        if( props.innings && (props.innings.length && props.team_1_id == props.innings[props.innings.length-1].batting_team_id) ){teamANameClass="text-black"}else{teamANameClass='text-medium-grey'}
    }else if(teamNumber===2 && props.hasOwnProperty('teamB')){
        if( props.innings && (props.innings.length && props.team_2_id == props.innings[props.innings.length-1].batting_team_id) ){teamBNameClass="text-black"}else{teamBNameClass='text-medium-grey'}
    }


    return (<div className="px-2 pb-1 d-flex justify-content-between align-items-center">

        <div className="d-flex">
            <div className={flagClass}>
                <div className="one-by-one-square bg-center-cover"
                style={{backgroundImage: 'url( '+flagUrl+')', backgroundRepeat:'no-repeat', backgroundPosition: 'center 2px'}}></div>
            </div>
            <h5 className="text-uppercase align-self-center mb-0 m-f-12">
            {
                (teamNumber===1 && props.hasOwnProperty('teamA')) ?
                (
                    <span className={teamANameClass} >
                    {props.teamA.short_name }
                    </span>
                ): ''
            }
            {
                (teamNumber===2 && props.hasOwnProperty('teamB')) ?
                (
                    <span className={ teamBNameClass } >
                    {props.teamB.short_name}
                    </span>
                ) : ''
            }
            {
                (teamNumber===1 && props.hasOwnProperty('team_1')) ? props.team_1.team.short_name : ''
            }
            {
                (teamNumber===2 && props.hasOwnProperty('team_2')) ? props.team_2.team.short_name : ''
            }
            </h5>
        </div>

        <h5 className="mb-0 m-f-12">
        {
            (props.innings) ? props.innings.map( (obj, i) => {
                if (teamNumber===1 && obj.batting_team_id===props.team_1_id) {
                    return (
                        <span key={"ing-det-"+i}
                            className={
                                (props.innings && props.innings[props.innings.length-1].batting_team_id == props.team_1_id && ( ( (i == 0 || i== 1) && team1_innings < 2 ) || ( (i==2 || i== 3) && team1_innings >=2 )) ) ?
                                'text-black':'text-medium-grey'
                                }
                        >
                            <span dangerouslySetInnerHTML={{__html: MatchRunsAndWicketsExpression(obj, i, props.innings.length)}}></span>
                        </span>
                    )
                } else if (teamNumber===2 && obj.batting_team_id===props.team_2_id) {
                    return (
                        <span key={"ing-det-"+i}
                            className={
                                (props.innings && props.innings[props.innings.length-1].batting_team_id == props.team_2_id && ( ( (i == 0 || i== 1) && team2_innings < 2 ) || ( (i==2 || i== 3) && team2_innings >=2 )) ) ?
                                'text-black':'text-medium-grey'
                            }
                        >
                            <span dangerouslySetInnerHTML={{__html: MatchRunsAndWicketsExpression(obj, i, props.innings.length)}}></span>
                        </span>
                    )
                };
                return false;
            }) : ''
        }
        </h5>
    </div>)
}
export const getLiveMatchButtonOnPage = (props) => {
    
    if (props.data.hasOwnProperty('match_state') && props.data.match_state.toLowerCase().indexOf('live') > -1
    && props.data.live_stream_url && localStorage.getItem('user')) {
        return <button onClick={props.playLiveStream} className="btn btn-light w-100 rounded-0 bg-white border-bottom-0">

            {/* <h5 className="text-center font-weight-bold mb-0 py-2">
                <span className="text-uppercase red px-2">Play Stream</span>
                <i className="fa fa-angle-right red font-weight-bold"></i>
            </h5> */}
            <h5 className="text-center font-weight-bold mb-0 py-2">{props.matchButtonText || "Watch Now!"}
                <span className="text-uppercase red px-2">Live Stream</span>
                <i className="fa fa-angle-right red font-weight-bold"></i>
            </h5>

        </button>
    } else if (props.data.hasOwnProperty('match_state') && props.data.match_state.toLowerCase().indexOf('live') > -1
    && props.data.live_stream_url && !localStorage.getItem('user')) {
        return <Link to={"/match/"+props.data.id+"/summary/allowStream"} className="btn btn-light w-100 rounded-0 bg-white border-bottom-0">

            {/* <h5 className="text-center font-weight-bold mb-0 py-2">
                <span className="text-uppercase red px-2">Play Stream</span>
                <i className="fa fa-angle-right red font-weight-bold"></i>
            </h5> */}
            <h5 className="text-center font-weight-bold mb-0 py-2">{props.matchButtonText || "Watch Now!"}
                <span className="text-uppercase red px-2">Live Stream</span>
                <i className="fa fa-angle-right red font-weight-bold"></i>
            </h5>

        </Link>
    }

}

export const MatchScorecardSection = (props) => {

    const data = props.data;
    const title = data ? data.title : '';
    const matchState = data ? data : '';

    return <div className="custom-box px-0 py-1 mb-3">
            <div className={"px-2"}>
                <div className="px-2 py-2 d-flex justify-content-between">
                    <h5 className="font-weight-normal mb-0 m-f-14">
                        {title}
                            {/* {
                                (data.format && data.format.toString().toLowerCase() === 'test' && data.day) ? (" - Day "+data.day+" - Session "+data.session+" ") : ""
                            } */}
                        {

                            (data.venue) ? (", "+data.venue.title) : ""
                        }
                    </h5>
                    {
                        (matchState.match_state.toLowerCase().indexOf('scheduled') > -1) ?
                        <h5 className="text-secondary mb-0 m-f-14">{getMatchStateText(matchState)}</h5>
                        :
                        <h5 className="red mb-0 m-f-14">{getMatchStateText(matchState)}</h5>

                    }
                </div>
                {
                    ( data.match_state && data.match_state.toString().toLowerCase() == 'live' && data.match_start)?
                    (<h6 className="px-2 text-secondary font-weight-normal m-f-12">{ getDateFunc(data.match_start)}</h6>):
                    (<div></div>)
                }

                <div className="mb-2">
                {
                    MatchScorecardInningsDetails(data, 1)
                }
                {
                    MatchScorecardInningsDetails(data, 2)
                }
                </div>
                {
                    (data.match_state.toLowerCase() !== 'scheduled') ?
                    // <h4 className="px-2 text-center mb-2 m-f-14"
                    // dangerouslySetInnerHTML={{__html: data.detail || data.match_news || data.match_result || data.match_status}}>
                    <h4 className="px-2 text-center mb-2 m-f-14">
                    { (data.format.toLowerCase() === "test") ?
                     ((data.match_state.toLowerCase() === "live") ? "Day "+data.day+((data.break_type.trim() == "") ? " - Session "+data.session : "")+((data.match_status) ? " - " : "")+" "+((data.match_status) ? data.match_status : "") : ((data.match_result) ? data.match_result : ((data.detail) ? data.detail : "")))
                    : data.detail || data.match_news || data.match_result || data.match_status
                     } </h4> :
                    <h4 className="px-2 text-center mb-2 m-f-14">{getDateWithoutYear(data.match_start)+", "+getTimeInAmPm(data.match_start)}</h4>
                }

            </div>
        {
            (props.showPageLinkForStream) ? "" : getLiveMatchButtonOnPage(props)
        }

    </div>
}

export const getCRR = (innings) => (innings) ? innings.map(obj => (obj.innings_order === innings.length) ? obj.run_rate : '') : '';
const getBallsInOver = (overs) => {
  let ov = overs.toString().split('.')[0];
  let balls = overs.toString().split('.')[1];
  if (!balls) balls = 0;
  return (parseInt(ov) * 6) + parseInt(balls);
}
export const MatchHighlightBar = (props) => {

    const partnership = props.data.partnership || props.data.partnership_and_bowlers;
    const teamA = props.data.teamA || (props.data.team_1 && props.data.team_1.team);
    const teamB = props.data.teamB || (props.data.team_2 && props.data.team_2.team);

    return <div>
        {
            (partnership) ?
                <div className="d-flex justify-content-between overflow-x-auto py-3">
                    <h5 className="text-secondary font-weight-normal mb-0 px-2 m-f-12">CRR <span className="pl-1 text-black">
                        {getCRR(props.data.innings)}</span>
                    </h5>

                    <h5 className="text-secondary font-weight-normal mb-0 px-2 m-f-12">
                      Last {partnership.overs_played} Ov (RR)
                    <span className="pl-1 text-black">
                    { !(partnership.score_covered===0 || partnership.overs_played===0)
                      ? ( ( parseInt(partnership.score_covered)/getBallsInOver(partnership.overs_played))*6 ).toFixed(2)
                      : 0
                    }
                    {/* {
                        (partnership.score_covered/partnership.overs_played).toString().substring(0, 4)
                    // } */}
                    </span></h5>

                    <h5 className="text-secondary font-weight-normal mb-0 px-2 m-f-12">Last Wkt
                        <span className="pl-1 text-black">
                        {
                            (partnership.fall_of_wickets && partnership.fall_of_wickets.length) ?
                            partnership.fall_of_wickets[partnership.fall_of_wickets.length-1].out_batsman.player.name+" "
                            : ''
                        }
                        ({
                            (partnership.fall_of_wickets && partnership.fall_of_wickets.length) ?
                            partnership.fall_of_wickets[partnership.fall_of_wickets.length-1].out_batsman.batsman.runs_scored
                            : ''
                        })
                        </span>
                    </h5>

                    <h5 className="text-secondary font-weight-normal mb-0 px-2 m-f-12">Toss <span className="pl-1 text-black">{
                        (props.data.toss_won_by_id === props.data.team_1_id) ? teamA.short_name+" " : teamB.short_name+" "
                    }
                    ({
                        (props.data.toss_won_by_id === props.data.innings[0].batting_team_id) ? 'Batting' : 'Fielding'
                    })</span></h5>
                </div>
            : <div></div>

        }

    </div>
};

export const MatchNavActiveClasses = (category, path) => {
    let str = "";
    (category === path) ?
    (str = "font-weight-semi-bold p-2 mr-4 border-bottom border-danger") :
    (str = "font-weight-semi-bold p-2 mr-4");
    return str;
};

export const MatchNav = (props) => (
    <div className="d-flex overflow-x-auto">
        <Link to={"/match/"+props.matchId+'/summary/1'}>
            <h4 className={MatchNavActiveClasses(props.category, "summary")+' m-f-14'}>Summary</h4>
        </Link>
        <Link to={"/match/"+props.matchId+"/scorecard/1"}>
            <h4 className={MatchNavActiveClasses(props.category, "scorecard")+' m-f-14'}>Scorecard</h4>
        </Link>
        <Link to={"/match/"+props.matchId+"/videos/v"}>
            <h4 className={MatchNavActiveClasses(props.category, "videos")+' m-f-14'}>Videos</h4>
        </Link>
        <Link to={"/match/"+props.matchId+"/ballbyball/1"}>
            <h4 className={MatchNavActiveClasses(props.category, "ballbyball")+' m-f-14'}>Ball by Ball</h4>
        </Link>
        <Link to={"/match/"+props.matchId+"/squads/1"}>
            <h4 className={MatchNavActiveClasses(props.category, "squads")+' m-f-14'}>Squads</h4>
        </Link>
    </div>
);

export const BallByBallDetail = (props) => (
    <div className="d-flex flex-mw-auto mx-3 mt-2 p-3 border-bottom">
        <div className="text-center ">
            <BallByBallSingle data={props.data}></BallByBallSingle>
            <h5 className="font-weight-normal mb-0 mt-1 m-f-12">{props.data.title}</h5>
        </div>
        <div className="px-3">
            <h5 className="font-weight-normal m-f-12 mh-auto">{props.data.commentary}</h5>
        </div>
    </div>
);

export const BallByBall = (props) => (
    <div onScroll={props.scroll} className="d-flex border-bottom overflow-x-auto bbb-container">
        {
            (props.loadingPreviousBalls) ?
            <div className="border-right p-3 d-flex align-items-center"><img src="/images/loader_v2.gif" height="30" alt="" /></div>
            : <div></div>
        }
        {
            (props.data) ? props.data.map( (obj, i) => <BallByBallOver key={"bbb-over-"+i}
             ballByBallSelect={props.ballByBallSelect}
             data={obj}></BallByBallOver> ) : ''
        }
    </div>
);

export const BallByBallOver = (props) => {
    const data = props.data;
    const balls = data.balls;
    const overNumber = data.title.split(' ')[1];
    return <div id={overNumber} onClick={ () => props.ballByBallSelect(overNumber)} className="border-right p-3">
        <h5 className="mb-3 px-1 text-capitalize m-f-13">{"Over "+overNumber}</h5>
        <div className="d-flex">
            {
                balls.map( (obj, i) => <BallByBallSingle key={"bbb-single-"+i} data={obj}></BallByBallSingle> )
            }
        </div>
    </div>
};

export const getBall = (props) => {
    if (props.wicket) {
        return 'wkt';
    } else if (props.wide_ball) {
        return props.extra_wide_runs.toString()+'wd';
    } else if (props.no_ball) {
        return props.runs_scored.toString()+'nb';
    }  else if (props.extra_bye) {
        return props.extra_bye.toString()+'B'
    } else if (props.extra_leg_bye) {
        return props.extra_leg_bye.toString()+'lb'
    }  else if (props.extra_penalty_runs) {
        return props.extra_penalty_runs.toString()+'ex'
    };
    return props.runs_scored;
};

export const BallByBallSingle = (props) => (
    <div className="rounded-circle border custom-ball-size d-flex justify-content-center align-items-center mx-1">
        {getBall(props.data)}
    </div>
);

export const MatchScorecardTable = (props) => (
    (props.data.partnership) ?
    <div>
        <table className="border-top mb-3">
            <tbody>
                <MatchScorecardTitle></MatchScorecardTitle>
                <MatchScorecardRow data={props.data.partnership.batsman_1} showPlayerDetailText={props.showPlayerDetailText}></MatchScorecardRow>
                <MatchScorecardRow data={props.data.partnership.batsman_2} showPlayerDetailText={props.showPlayerDetailText}></MatchScorecardRow>

            </tbody>
        </table>
        {
            (props.data.partnership.overs_played && props.data.partnership.score_covered)?
            (<div>
                <h5 className="px-1 pl-3 mt-1 m-f-12 font-semi-bold">Partnership of {props.data.partnership.score_covered} runs from {getBallsInOver(props.data.partnership.overs_played)} balls</h5>
            </div>):<div></div>
        }
    </div>
    : <div></div>
);

export const MatchScorecardTableBowling = (props) => (
    (props.data.partnership) ?
    <table className="border-top mb-3">
        <tbody>
            <MatchScorecardTitleBowling></MatchScorecardTitleBowling>
            <MatchScorecardRowBowling data={props.data.partnership.bowler} showPlayerDetailText={props.showPlayerDetailText}></MatchScorecardRowBowling>
            <MatchScorecardRowBowling data={props.data.partnership.last_bowler} showPlayerDetailText={props.showPlayerDetailText}></MatchScorecardRowBowling>
        </tbody>
    </table>
    : <div></div>
);

export const MatchScorecardTitle = () => (
    <tr>
        <td valign="middle" className="red text-uppercase w-50 font-weight-normal py-3 px-1 pl-3">Batsmen</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">R</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">B</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">4s</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">6s</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">SR</td>
    </tr>
);

export const MatchScorecardTitleBowling = () => (
    <tr>
        <td valign="middle" className="red text-uppercase w-50 font-weight-normal py-3 px-1 pl-3">Bowlers</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">O</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">M</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">R</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">W</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">EC</td>
    </tr>
);

export const MatchScorecardRowBowling = (props) => (

        (props.data) ?
        <tr>
            <td valign="middle" className="w-50 font-weight-normal px-1 py-1 pl-3">
            {(props.data.bowler.string_state == 'bowling') ? props.data.player.name+'*' : props.data.player.name }</td>
            <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.overs_bowled}</td>
            <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.overs_maiden}</td>
            <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.runs_given}</td>
            <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.wickets_taken}</td>
            <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.econ}</td>
        </tr> : <tr></tr>


);


export const MatchScorecardRow = (props) => (
    (props.data) ?
    <tr>
        <td valign="middle" className="w-50 font-weight-normal px-1 py-1 pl-3">
        {(props.data.batsman.string_state == 'facing') ? props.data.player.name+'*' : props.data.player.name }</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.runs_scored}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.balls_played}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.boundry_4s_scored}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.boundry_6s_scored}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.strike_rate}</td>
    </tr> : <tr></tr>
);

// Match Scorecard

export const getScorecardTitle = (inning, number, matchObj) => {
    let str = "";
    if (matchObj.team_1_id === inning.batting_team_id) str = matchObj.team_1.team.name;
    if (matchObj.team_2_id === inning.batting_team_id) str = matchObj.team_2.team.name;
    if (inning.innings_order === 1 || inning.innings_order === 2) str = str + " 1st Innings";
    else str = str + " 2nd Innings";
    return str;
};

export const CheckToShowFlag = (props) => {
    let node;
    (props.flag) ?
    (node = <div className="d-flex align-items-center">
            <div className="w-3rem mr-2">
                {/* <div className="sixteen-by-nine bg-center-cover" style={ props.flag }></div> */}
                <BackgroundImage className="sixteen-by-nine bg-center-cover" src={props.flag} placeholder={Globals.placeholder4x3}>
                                 </BackgroundImage>
            </div>
            <h4 className="mb-0">Pakistan won the toss</h4>
        </div>) :
    (node = <h4>Pakistan won the toss</h4>);
    return node;
};

export const MatchDetailRow = (props) => (
    <div className="border-bottom border-light px-2 py-3">
        <h4 className="text-secondary">Pakistan vs Australia</h4>
        {CheckToShowFlag(props)}
        {/* <h4 className="mb-0">Pakistan won</h4> */}
    </div>
);

export const MatchDetail = (props) => (
    <div className="card mt-2 p-3">
        <h4 className="font-weight-semi-bold px-2 py-3 mb-2 border-bottom">Match Details</h4>
        <MatchDetailRow></MatchDetailRow>
        <MatchDetailRow flag={props.image}></MatchDetailRow>
    </div>
);

export const CollapsableScorecard = (props) => {
    let btnClass = "d-flex justify-content-between align-items-center py-3 btn bg-white w-100 btn-light "
    if(props.thisIndex != props.inningsCount){
        btnClass += " collapsed"
    }
    return (
    <div>
        <button className={ btnClass }
         type="button" data-toggle="collapse" data-target={"#collapseExample"+props.index}
          aria-expanded="true" aria-controls={"collapseExample"+props.index}>
            <h4 className="font-weight-semi-bold mb-0"> {props.title} </h4>
            <h4 className="font-weight-semi-bold mb-0"><i className="fa videos-menu font-weight-semi-bold"></i></h4>
        </button>
        <div className={(props.thisIndex == (props.inningsCount) ) ? 'show':'' +"collapse "} id={"collapseExample"+props.index}>
            <MSTable data={props.data} showPlayerDetailText={props.showPlayerDetailText}></MSTable>
            <MSTableBowling data={props.data} showPlayerDetailText={props.showPlayerDetailText} />
            <MSTableFOW data={props.data} showPlayerDetailText={props.showPlayerDetailText} />
        </div>
    </div>
)};

export const CollapsableScorecardBowling = (props) => {
    const fow = props.data.falls_of_wickets;
    return <div>
        <button className="d-flex justify-content-between align-items-center py-3 btn bg-white w-100 btn-light"
         type="button" data-toggle="collapse" data-target={"#collapseExample"+props.index}
          aria-expanded="true" aria-controls={"collapseExample"+props.index}>
            <div className="text-left w-100">
                <h4 className="font-weight-semi-bold mb-1"> {props.title} </h4>
                <div className="d-none d-lg-block">
                    <div className="row  pr-2 pr-lg-5">
                        <div className="col-6 font-weight-normal white-space-normal text-secondary mb-0">
                        {
                            (fow && fow.length) ?
                            (
                                fow[fow.length-1].out_batsman.player.short_name + ' ' + fow[fow.length-1].out_batsman.batsman.runs_scored + ' ('
                            + fow[fow.length-1].out_batsman.batsman.balls_played + ') ' + fow[fow.length-1].out_batsman.batsman.out_details+' '
                            )
                            : ''
                        }
                        </div>
                        <div className="col-6 font-weight-normal text-right white-space-normal text-secondary mb-0">
                        {
                            (fow && fow.length > 1) ?
                            (fow[fow.length-2].out_batsman.player.short_name + ' ' + fow[fow.length-2].out_batsman.batsman.runs_scored + ' ('
                            + fow[fow.length-2].out_batsman.batsman.balls_played + ') ' + fow[fow.length-2].out_batsman.batsman.out_details )
                            : ''
                        }
                        </div>
                    </div>
                </div>
            </div>

            <h4 className="font-weight-semi-bold mb-0"><i className="fa videos-menu font-weight-semi-bold"></i></h4>
        </button>
        <div className="collapse show" id={"collapseExample"+props.index}>
            <MSTableBowling data={props.data} showPlayerDetailText={props.showPlayerDetailText} />
        </div>
    </div>
};

export const MSTable = (props) => (
    <div className="mb-4">
        <table className="border-top mb-3">
            <tbody>
                <MSTitle />
                {
                    props.data.batting_scorecard.map( (obj, i) =>
                        <MSRow key={"msr-"+i} data={obj} showPlayerDetailText={props.showPlayerDetailText} />
                    )
                }

            </tbody>
        </table>
        <table>
            <tbody>
                <tr></tr>
                <tr className="bg-light">
                    <td className="px-3 pb-0 pt-2 text-secondary font-weight-semi-bold">Extras</td>
                    <td className="px-3 pb-0 pt-2 text-secondary font-weight-semi-bold">
                        (lb {props.data.extra_leg_bye}, b {props.data.extra_bye}, wd {props.data.wide_ball}, nb {props.data.no_ball})
                    </td>
                    <td></td>
                </tr>
                <tr className="bg-light">
                    <td className="px-3 pt-1 pb-2  text-uppercase font-weight-semi-bold">Total</td>
                    <td className="px-3 pt-1 pb-2 text-secondary font-weight-semi-bold">
                        ({props.data.wickets} wickets, {props.data.overs} overs)
                    </td>
                    <td className="px-3 pt-1 pb-2 ">
                        <span className="pr-1 font-weight-semi-bold">{props.data.runs}</span>
                        {'  (' + props.data.run_rate + ' runs per over)'}</td>
                </tr>
            </tbody>
        </table>
    </div>
);

export const MSTableBowling = (props) => (
    <table className="border-top mb-3">
        <tbody>
            <MSTitleBowling/>
            {
                props.data.bowling_scorecard.map( (obj, i) =>
                    <MSRowBowling key={"msr-"+i} data={obj} showPlayerDetailText={props.showPlayerDetailText} />
                )
            }
        </tbody>
    </table>
);

export const MSTableFOW = (props) => (
    <table className="border-top mb-3">
        <tbody>
            <MSTitleFOW/>
            {
                props.data.falls_of_wickets.map( (obj, i) =>
                    <MSRowFOW key={"msr-"+i} data={obj} showPlayerDetailText={props.showPlayerDetailText} />
                )
            }
        </tbody>
    </table>
);

export const MSTitle = () => (
    <tr>
        <td valign="middle" className="red text-uppercase w-50 font-weight-normal py-3 px-1 pl-3">Batsmen</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">R</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">B</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">4s</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">6s</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">SR</td>
    </tr>
);

export const MSTitleFOW = () => (
    <tr>
        <td valign="middle" className="red text-uppercase w-50 font-weight-normal py-3 px-1 pl-3">Fall of Wickets</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">Overs</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">Score</td>
    </tr>
);

export const MSTitleBowling = () => (
    <tr>
        <td valign="middle" className="red text-uppercase w-50 font-weight-normal py-3 px-1 pl-3">Bowlers</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">O</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">M</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">R</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">W</td>
        <td valign="middle" className="text-secondary w-10 font-weight-normal py-3 px-1" align="center">EC</td>
    </tr>
);

export const MSRowBowling = (props) => (
    <tr>
        <td valign="middle" className="w-50 font-weight-normal px-1 py-1 pl-3">{ props.data.player.name }</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.overs_bowled}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.overs_maiden}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.runs_given}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.wickets_taken}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.bowler.econ}</td>
    </tr>
);
export const MSRowFOW = (props) => (
    <tr>
        <td valign="middle" className="w-50 font-weight-normal px-1 py-1 pl-3">{ props.data.out_batsman.player.short_name+ " (" + props.data.out_batsman.batsman.runs_scored+")"}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.ball}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.wicket_order+"/"+props.data.team_score}</td>
    </tr>
);


export const MSRow = (props) => (
    <tr>
        <td valign="middle" className="w-50 font-weight-normal px-1 py-1 pl-3">
        { props.data.player.name }
        <br />
        {
            (props.showPlayerDetailText) ? <span className="text-secondary font-weight-normal">{props.data.batsman.out_details}</span> : ''
        }
        </td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.runs_scored}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.balls_played}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.boundry_4s_scored}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.boundry_6s_scored}</td>
        <td valign="middle" className="font-weight-normal w-10 px-1 py-1" align="center">{props.data.batsman.strike_rate}</td>
    </tr>
);

//Match Squads

export const SquadDescription = (props) => (
    <div className="pl-3">
        <h5 className="mb-0">{props.data.player.name}</h5>
        <p className="mb-1">{(props.data.is_captain) ? 'Captain' : ''}</p>
        <p className="mb-1">{(props.data.is_wicket_keeper) ? 'Wicket Keeper' : ''}</p>
        <p className="mb-1">{(!props.data.is_wicket_keeper && !props.data.is_captain) ? props.data.player.string_role : ''}</p>
        {
            (props.data.player.birth_date) ?
            <div>
                <p className="text-secondary mb-0 font-sm">Age</p>
                <p className="mb-0 font-sm">{new Date().getFullYear() - new Date(props.data.player.birth_date).getFullYear()}</p>
            </div> : <div></div>
        }

        <p className="text-secondary mb-0 font-sm">Batting</p>
        <p className="mb-1 font-sm">{props.data.player.batting_style}</p>
    </div>
);
export const SquadBox = (props) => (
    <div className="d-flex col-sm-12 col-md-6 py-4">
        <div className="w-45">
            <div className="one-by-one-square bg-center-cover" style={{backgroundImage: "url(" + props.data.player.display_picture_url + ")"}}></div>
        </div>
        <SquadDescription data={props.data}></SquadDescription>
    </div>
);
export const ScrollRightBallByBall = () => {
    let elem = document.getElementsByClassName('bbb-container');
    if(elem && elem[0]){
        elem = elem[0]
        elem.scrollTo(100000, 0);
    }
}

export const appstart = (number, telcoConstants) => {
    let return_response = {status:0, message:'Something went wrong!', internal_message:'', user:{}}
    let respApi,
    url = '',
    user = [];
    let promise =  new Promise(function(resolve, reject){

        if(telcoConstants.telco === 'mobilink'){
            url = telcoConstants.Api2+"find_sub_by_phone?web_user=1&phone="+number;
            axios.get(url).then(res => {
                respApi = Object.assign({}, res);
                user = respApi.data.user;
                if(user){
                    return_response = {status:1, message:'Success', internal_message:'', user:user}
                    resolve(return_response);
                }else{
                    return_response.internal_message = 'user not found';
                    reject(return_response);
                }

            }).catch(err => {
                return_response.internal_message = 'find phone call fail';
                reject(return_response);
            });
            // response.status = 1;
            // resolve(response);

        }else{
            //telco not found
            return_response.internal_message = 'telco not found: '+telcoConstants.telco;
            reject(return_response);
        }

    });
    return promise;

}
export const sendPin = (number, telcoConstants) => {
    let return_response = {status:0, message:'Something went wrong!', internal_message:'', pin_message:''}
    let respApi,
    url = ''
    let promise = new Promise(function(resolve, reject){

        if(telcoConstants.telco === 'mobilink'){
            url = telcoConstants.Api2+"send_pin?web_user=1&phone="+number+"&udid="+number;
            axios({
                url: url,
                method: 'get',
                timeout: 120000
            }).then(res => {
                respApi = Object.assign({}, res.data);
                if(respApi.resp && respApi.resp.enter_pin_msg){
                    return_response.pin_message = respApi.resp.enter_pin_msg;
                }
                return_response.status = 1; return_response.message = 'Success';
                //pin must be sent
                resolve(return_response);
            }).catch(err => {
                return_response.internal_message = "send pin call fail";
                reject(return_response);
            });
        }else{
            reject(return_response);
        }
    });
    return promise;
}

export const confirmPin = (pin, number, telcoConstants) => {
    let return_response = {status:0, message:'Something went wrong!', internal_message:''}
    let respApi,
    url = ''

    let promise = new Promise(function(resolve, reject){

        if(telcoConstants.telco === 'mobilink'){
            url = telcoConstants.Api2+"confirm_pin_n_sub?phone="+number+"&udid="+number+"&web_user=1&pin="+pin;
            axios({
                method: "get",
                url: url,
                timeout: 120000
            }).then( res => {
                respApi = Object.assign({}, res.data);
                if(respApi.status == "1"){
                    return_response = {status:1, message:'Success', internal_message:''}
                    resolve(return_response);
                }else{
                    return_response.message = 'Invalid Pincode!';
                    reject(return_response);
                }
            }).catch(err => {
                return_response.internal_message = 'confirm pin call failed';
                return_response.internal_message = 'Something went wrong. Please try again later!';
                reject(return_response);
            });

        }else{
            // no telco found
            reject(return_response);
        }

    });
    return promise;
}

export const isNumberValid = (number, telcoConstants) => {
    var isnum = /^\d+$/.test(number);
    if(telcoConstants.telco =='mobilink'){
        if(isnum && number.length == 11){
            return true
        }
    }
    return false;

}
export const loginUser = (user) => {
    var n = Date.now();
    user.setTime = n;
    localStorage.setItem('user', JSON.stringify(user));
}

export const isVideoAllowed = (telcoConstants) => {
    return new Promise(function(resolve, reject){
        if(telcoConstants && (telcoConstants.telco == 'mobilink' || telcoConstants.telco == 'ufone' || telcoConstants.telco == '') ){
            // check ip country for mobilink to disallow content from outside pakistan on cricket.jazz.com.pk
            //check ip in case of multi telco build (cricwick.net) and return PK if from pakistan to place app download link accordingly
            getIp().then((res)=>{
                if(res.data && res.data.country_code === 'PK'){
                    resolve('PK')
                }else{
                    (telcoConstants.telco==='mobilink' || telcoConstants.telco==='ufone')? reject(false) : resolve(true);
                }
            }).catch((res)=>{
                //return 2, which means ipfind api failed to respond, must not stop user from viewing due to api failure
                reject(2)
            });
        }else if(telcoConstants.telco === 'cricwick' || telcoConstants.telco === 'zain' || telcoConstants.telco === ''){
            resolve(true);
        }else{
            resolve(true)
        }
    });
}

export const getIp = () => {
    let url = "https://api.ipfind.com/me?auth=4029d1e5-8c9f-482d-9dce-d1cd064fd737";
    return new Promise(function(resolve, reject){
        axios({
            url: url,
            method: 'get',
            timeout: 120000
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(false)
        });
    })

}

export const getTelcoFromOid = (oid) => {
    switch (oid) {
        case '101':
            return "telenor"
            break;

        case '102':
            return "zong"
            break;

        case '103':
            return "mobily"
            break;

        case '104':
            return "stc"
            break;

        case '105':
            return "zain_bh"
            break;

        case '106':
            return "zain_kw"
            break;

        case '107':
            return "ooredoo"
            break;

        case '108':
            return "ooredoo_kw"
            break;

        case '109':
            return "bbh"
            break;

        case '110':
            return "viva_kw"
            break;

        default:
            return false
            break;
    }
}

export const autoLogin = (telcoConstants, params) => {
    //LOGIN
    //if third party campaign has redirected to portal with phone number * and predefined operator id
    //or the number is found in header

    return new Promise( async (resolve, reject) => {

        let phone = '', oid='', telco='', msisdn='';
        const parsed = queryString.parse(params);

        if(parsed.q){ phone=parsed.q }
        if(parsed.oid){ oid=parsed.oid }

        if(telcoConstants.telco){
            //if single telco deployment i.e jazz or zain (cric.sa.zain.com)
            telco = telcoConstants.telco;
        }else if(!telcoConstants.telco && oid){
            //multi telco build having multiple telcos, redirection by campaign must send operator id to determin
            telco = getTelcoFromOid();
        }

        if(!telco){reject('no telco'); return false}

        if(!phone){
            //phone not found in params; check for header enrichment
            if(telco === 'zain'){
                let HEZainUrl = "http://cric.sa.zain.com/userapi/get_header_phone";
                // let HEZainUrl = "http://cric.sa.zain.com/userapi/get_header_phone?iamtesting=1";
                let HEZainResp = await axios.get(HEZainUrl)
                if(HEZainResp.status === 200 && HEZainResp.data.phone){
                    phone = HEZainResp.data.phone
                    msisdn = phone;
                    console.log('HE phone: ', phone)
                }else{
                    reject('no phone'); return false;
                }
            }

        }

        let serverResponse = {};
        try {
            const url = telcoConstants.NodeApi+"api/appstart?telco="+telco+"&phone="+phone;
            serverResponse = await axios.get(url, {
                // withCredentials: true,mode: 'cors',headers: {'Access-Control-Allow-Credentials': true}
            }).then((res) => {
                    const response = res.data
                    if(response.status){
                        if (response.generic_response.subscribed) {
                            response.generic_response.phone = phone;
                            response.generic_response.telco = telco;
                            localStorage.setItem('user_logged_in', true);
                            localStorage.setItem('user', JSON.stringify(response.generic_response));
                            loginUser(response.generic_response);
                            // window.location.href = '/';
                            resolve(JSON.stringify(response));
                        }else{

                            reject({msg: 'not subscribed', msisdn: msisdn})
                            return false;
                        }
                    }else{
                        reject('no response')
                        return false;
                    }
                }).catch(() => { reject('call fail'); return false;});
            } catch (err) {
                 reject('try failure');
                 return false;
            };



    }); //promise

}

export const autoLoginX = (telcoConstants, params) => {
    //LOGIN
    //if third party campaign has redirected to portal with phone number * and predefined operator id
    //or the number is found in header
    let promise = new Promise((resolve, reject) => {
        let phone = '', oid='', telco='';
        const parsed = queryString.parse(params);

        if(parsed.q){ phone=parsed.q }
        if(parsed.oid){ oid=parsed.oid }

        if(telcoConstants.telco){
            //if single telco deployment i.e jazz or zain (cric.sa.zain.com)
            telco = telcoConstants.telco;
        }else if(!telcoConstants.telco && oid){
            //multi telco build having multiple telcos, redirection by campaign must send operator id to determin
            switch (oid) {
                case '101':
                    telco = "telenor"
                    break;

                case '102':
                    telco = "zong"
                    break;

                case '103':
                    telco = "mobily"
                    break;

                case '104':
                    telco = "stc"
                    break;

                case '105':
                    telco = "zain_bh"
                    break;

                case '106':
                    telco = "zain_kw"
                    break;

                case '107':
                    telco = "ooredoo"
                    break;

                case '108':
                    telco = "ooredoo_kw"
                    break;

                case '109':
                    telco = "bbh"
                    break;

                case '110':
                    telco = "viva_kw"
                    break;

                default:
                    reject('incorrect oid')
                    break;
            }
        }else{
            //reject
            reject('no telco or oid');
        }

        if(!phone){ reject('no phone') }

        let serverResponse = {};
        try {
            const url = telcoConstants.NodeApi+"api/login_or_sendpin?telco="+telco+"&phone="+phone;
            serverResponse = axios.get(url, {
                // withCredentials: true,mode: 'cors',headers: {'Access-Control-Allow-Credentials': true}
            }).then((res) => {
                    const response = res.data
                    if(response.status){
                        if (response.generic_response.subscribed) {
                            response.generic_response.phone = phone;
                            response.generic_response.telco = telco;
                            localStorage.setItem('user_logged_in', true);
                            localStorage.setItem('user', JSON.stringify(response.generic_response));
                            loginUser(response.generic_response);
                            // window.location.href = '/';
                            resolve(JSON.stringify(response));
                        }else{
                            reject('not subscribed')
                        }
                    }
                }).catch(() => { reject('call fail')});
            } catch (err) {
                 reject('try failure');
            };

        // reject('generic');
    });

    return promise;

}

export const tempUser = (phone) => {
    let user = {
        call_type: "temporary_auto_login",
        message: "no response",
        phone: phone,
        subscribed: 5
    }
    return user;
}

export const getEnterPinText = (string, serverResponse) =>{
    let text = string;
    if(serverResponse && serverResponse.remote_response && serverResponse.remote_response.resp && serverResponse.remote_response.resp.enter_pin_msg){
        text = serverResponse.remote_response.resp.enter_pin_msg;
    }
    return text;
}

export const getTelcoOptions = (telcos) =>{

    return telcos.map( (telco, i) => {
        return(
            <option key={'Cto-'+i} value={JSON.stringify(telco)} >{telco.name}</option>
        )
    })
}

export const getVideoPath = (video_obj) => {
    var video = video_obj;
    if(video.qualities){
        if(video.qualities[1]){
            return video.qualities[1].video_file;
        }else if (video.qualities[0]){
            return video.qualities[0].video_file;
        }else{
            return video.video_file
        }
    }
    return video.video_file;
}

export const setCGObject = (telco, referer) =>{
    localStorage.removeItem('cgObject');
    let cgObject = {
        telco: telco,
        referer: referer
    }
    localStorage.setItem('cgObject', JSON.stringify(cgObject));

}

export const getUnsubPath = (user) => {
    let obj = {status: 0, path: ""}
    if(user.telco){

        if( in_array(user.telco, ['zain_kw', 'zain_bh']) && user.details && typeof user.details.service_id != 'undefined' && typeof user.details.udid != 'undefined' && user.phone ){
            obj.status=1;
            obj.path = `https://me.cricwick.net/userapi/api_unsubscribe?phone=${user.phone}&web_user=1&telco=${user.telco}&service_id=${user.details.service_id}&udid=${user.details.udid}`;
        }

        // if(user.telco === 'zain_bh' && user.details && typeof user.details.service_id != 'undefined' && typeof user.details.udid != 'undefined' && user.phone ){
        //     obj.status=1;
        //     obj.path = `http://api.cricboom.com/userapi/api_unsubscribe?phone=${user.phone}&web_user=1&telco=${user.telco}&service_id=${user.details.service_id}&udid=${user.details.udid}`;
        // }

        if( in_array(user.telco, ['ooredoo_kw', 'bbh']) && user.details && typeof user.details.service_id != 'undefined' && user.phone ){
            obj.status=1;
            obj.path = `https://me.cricwick.net/userapi/api_unsubscribe?phone=${user.phone}&web_user=1&service_id=${user.details.service_id}`;
        }

        // if(user.telco === 'bbh' && user.details && typeof user.details.service_id != 'undefined' && user.phone ){
        //     obj.status=1;
        //     obj.path = `http://api.cricboom.com/userapi/api_unsubscribe?phone=${user.phone}&web_user=1&service_id=${user.details.service_id}`;
        // }

        if(in_array(user.telco, ['telenor', 'zong', 'mobilink']) && user.phone){
            obj.status=1;
            obj.path = `https://pk.cricwick.net/main/unsubscribe?phone=${user.phone}&unsub_reason=1`
        }

    }
    return obj
}

function in_array(needle, haystack) {
    for(var i in haystack) {
        if(haystack[i] == needle) return true;
    }
    return false;
}
