import React, { Component } from 'react';
import { VideoCardDescription } from './HomeSeriesCard';
import axios from 'axios';
import { Link } from "react-router-dom";
import {Globals} from './constants';

class Rankings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customCssTransition: 'h-transition',
            isLoading: true,
            apiPath: Globals.ApiUrl+'api/',
            apiResp: [],
            filter: '',
            testClass:'',
            odiClass: '',
            t20Class: ''
        };
    };

    async componentDidMount(){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

        let apiPath = this.state.apiPath,
        resp = [],
        testClass= '',
        odiClass= '',
        t20Class= '',
        url = apiPath+"ranking?type=";
        let filter;
        if(!(typeof this.props.filter == 'undefined') && (this.props.filter == 'odi')){
            filter = this.props.filter;
            odiClass="border-bottom border-danger ";
            t20Class='';
            testClass='';
        }else if(!(typeof this.props.filter == 'undefined') && ( this.props.filter == 't20' )){
            filter = this.props.filter;
            t20Class="border-bottom border-danger ";
            odiClass='';
            testClass='';
        }else{
            filter = 'test';
            testClass="border-bottom border-danger ";
            odiClass='';
            t20Class='';
        }
        this.setState({filter: filter, testClass: testClass, odiClass: odiClass, t20Class: t20Class});


        try{
            resp = await axios.get(url+filter);
        }catch(error){
            console.log('error fetch '+filter+' rankings');
        }

        if(resp.status == 200){
            const R = resp.data;
            this.setState({apiResp: R, customCssTransition: 'v-transition', isLoading: false});
            console.log(filter+' rankings set:', this.state.apiResp);
        }else{
            console.log('error fetch '+filter+' rankings!');
        }
        // rankings

    }

  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }
          
          <div className={this.state.customCssTransition}>

          <div className="card p-3">
            <div className="d-flex overflow-x-auto">
                <h4 className={this.state.testClass+" font-weight-semi-bold m-f-14 p-2"}>
                <Link className="alert-heading" to="/rankings/test">Test</Link>
                </h4>
                <h4 className={this.state.odiClass+" font-weight-semi-bold m-f-14 p-2 ml-4"}>
                <Link className="alert-heading" to="/rankings/odi">ODI</Link>
                </h4>
                <h4 className={this.state.t20Class+" font-weight-semi-bold m-f-14 p-2 ml-4"}>
                <Link className="alert-heading" to="/rankings/t20">T20</Link>
                
                </h4>
            </div>
            
          </div>
          <div className="card mt-2 p-3">
          	{/* <div className="d-flex overflow-x-auto mb-1">
                <h5 className="font-weight-semi-bold p-2 border-bottom border-danger">Men</h5>
                <h5 className="font-weight-semi-bold p-2 ml-4 text-secondary">Women</h5>
            </div> */}
            <div>
		        <button className="d-flex justify-content-between align-items-center py-3 btn w-100 grey-white"
		         type="button" data-toggle="collapse" data-target="#collapseExample-1"
		          aria-expanded="true" aria-controls="collapseExample-1">
		            <h5 className="font-weight-normal text-uppercase mb-0 m-f-14"> {this.state.filter+' Teams Rankings'}</h5>
		            <h4 className="font-weight-semi-bold mb-0"><i className="fa fa-angle-down"></i></h4>
		        </button>
		        <div className="collapse show grey-white" id="collapseExample-1">
		            <RankingsTable titlePlayer={false} showPlayerDetailText={false} data={this.state.apiResp.teams}></RankingsTable>
		        </div>
		    </div>
		    
		    
           </div>
           	<div className="card mt-2 p-3">

		        <button className="d-flex justify-content-between align-items-center py-3 btn w-100 collapsed grey-white"
		         type="button" data-toggle="collapse" data-target="#collapseExample-2"
		          aria-expanded="true" aria-controls="collapseExample-2">
		            <h5 className="font-weight-normal text-uppercase mb-0"> {this.state.filter+' Batsmen'} </h5>
		            <h4 className="font-weight-semi-bold mb-0"><i className="fa fa-angle-down"></i></h4>
		        </button>
		        <div className="collapse " id="collapseExample-2">
		            <RankingsTable titlePlayer={true} showPlayerDetailText={false}  data={this.state.apiResp.batsmen}></RankingsTable>
		        </div>
		    </div>
           <div className="card mt-2 p-3">
		        <button className="d-flex justify-content-between align-items-center py-3 btn w-100 collapsed grey-white"
		         type="button" data-toggle="collapse" data-target="#collapseExample-3"
		          aria-expanded="true" aria-controls="collapseExample-3">
		            <h5 className="font-weight-normal text-uppercase mb-0"> {this.state.filter+' Bowlers'} </h5>
		            <h4 className="font-weight-semi-bold mb-0"><i className="fa fa-angle-down"></i></h4>
		        </button>
		        <div className="collapse " id="collapseExample-3">
		            <RankingsTable titlePlayer={true} showPlayerDetailText={false}  data={this.state.apiResp.bowler}></RankingsTable>
		        </div>
		    </div>

            <div className="card mt-2 p-3">
		        <button className="d-flex justify-content-between align-items-center py-3 btn  w-100 collapsed grey-white"
		         type="button" data-toggle="collapse" data-target="#collapseExample-4"
		          aria-expanded="true" aria-controls="collapseExample-4">
		            <h5 className="font-weight-normal text-uppercase mb-0"> {this.state.filter+' All-Rounders'} </h5>
		            <h4 className="font-weight-semi-bold mb-0"><i className="fa fa-angle-down"></i></h4>
		        </button>
		        <div className="collapse " id="collapseExample-4">
		            <RankingsTable titlePlayer={true} showPlayerDetailText={false}  data={this.state.apiResp.all_rounder}></RankingsTable>
		        </div>
		    </div>
            </div>
        </section>


    );
  }
}
const getPlayersRow = (props)=> {
    if(!(typeof props.data == 'undefined') && props.data.length){
        return props.data.map( (player, i) => {
            return <RankingsTableRowPlayer key={'rtrp-'+i} image={props.image} showPlayerDetailText={false} data={player} rank={i+1}></RankingsTableRowPlayer>
        })
    }else{
        return
    }
    
}

const getTeamsRow = (props) => {
    if(!(typeof props.data == 'undefined') && props.data.length){
        return props.data.map( (team, i) => {
            return <RankingsTableRow key={'rtr-'+i} image={props.image} showPlayerDetailText={false} data={team} rank={i+1}></RankingsTableRow>
        })
    }else{
        return
    }
}
export const RankingsTable = (props) => (
    <table className="mb-3 points-table fs-1p2">
        <tbody>
            {
                ( !(typeof props.titlePlayer == 'undefined') && props.titlePlayer )?
                (<RankingsTableTitlePlayers></RankingsTableTitlePlayers>):
                (<RankingsTableTitle></RankingsTableTitle>)
            }

            {
                ( !(typeof props.titlePlayer == 'undefined') && props.titlePlayer )?
                (getPlayersRow(props)):
                (getTeamsRow(props))
            }
            
            
        </tbody>
    </table>
);

const RankingsTableTitle = (props) => (
    <tr>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" width={50} align="center">Rank</td>
        <td valign="middle" className="text-black w-50 font-weight-semi-bold py-3 px-1 m-f-12" align="left">Team</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center">Matches</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center">Points</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center" width="1">Rating</td>
    </tr>
);

const RankingsTableTitlePlayers = (props) => (
    <tr>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" width={50} align="center">Rank</td>
        <td valign="middle" className="text-black w-50 font-weight-semi-bold py-3 px-1 m-f-12" align="left">Player</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="left">Team</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center" width="1">Rating</td>
    </tr>
);

const RankingsTableRow = (props) => (
    <tr>
        <td valign="middle" className="font-weight-normal px-1 py-1" width={50} align="center">{props.rank}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1 d-flex align-items-center" align="left">
        	<div className="mw-2rem mr-2"><div className="four-by-three bg-center-contain" style={{backgroundImage: 'url('+props.data.team_flag+')', backgroundColor: 'transparent'}}></div></div>
        	{props.data.team_name}
        </td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.match_count}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.points}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.rating}</td>
    </tr>
);

const RankingsTableRowPlayer = (props) => (
    <tr>
        <td valign="middle" className="font-weight-normal px-1 py-1" width={50} align="center">{props.rank}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="left">{props.data.player_name}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1 d-flex align-items-center" align="left">
        	<div className="mw-2rem mr-2"><div className="four-by-three bg-center-contain" style={{backgroundImage: 'url('+props.data.team_flag+')', backgroundColor: 'transparent'}} ></div></div>
        	{props.data.team_name}
        </td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.rating}</td>
    </tr>
);

export default Rankings;
