import React, { Component } from 'react';
import { MainCarouselSwipe } from './InitializePlugins';
import { MatchScorecardInningsDetails, getMatchStateText, getTimeInAmPm, getDateWithoutYear } from './HelperFunctions';
import { ApiUrl, pusherKey } from './constants';
// import { MatchScorecardInningsDetails, getMatchStateText } from './HelperFunctions';
import { Globals } from './constants';
import axios from 'axios';
import Pusher from 'pusher-js';
import { Link } from "react-router-dom";


class Maincarousel extends Component {


    constructor(props) {
        super(props);
        this.state = {
            liveMatchResp: {},
            customCssTransition: 'h-transition',
            leftHandleClass: '',
            rightHandleClass: '',
        }
        this.handleSwipe = MainCarouselSwipe.bind(this); 
        this.windowLoadListener = false;
        this.liveMatchHandler = this.liveMatchHandler.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getFromApi = this.getFromApi.bind(this);
        this.showHandles = this.showHandles.bind(this);
        this.hideHandles = this.hideHandles.bind(this);
        this.scrollright = this.scrollright.bind(this);
        this.scrollleft = this.scrollleft.bind(this);


    };

    liveMatchHandler(data) {
        // console.log('Match update======================>\n',data);
        const liveMatchesList = this.state.liveMatchResp.live_matches || [];
        const liveMatches = liveMatchesList.map( (obj, i) => (data.id===obj.id) ? data : obj );
        let liveMatchResp = this.state.liveMatchResp;
        liveMatchResp.live_matches = liveMatches;
        // this.setState({ liveMatchResp: liveMatchResp }, () => console.log("live match set state"));
        this.setState({ liveMatchResp: liveMatchResp });
    }

    refresh(data) {
    //   console.log("Live match refresh", data);
      this.getFromApi();
    }

    async getFromApi() {
        const url = Globals.ApiUrl + "api/v2/main/live_matches?telco="+this.props.telco;
        let response = {};
        try {
          response = await axios.get(url);
        //   this.setState({liveMatchResp: response.data, customCssTransition: 'v-transition'}, () => console.log("live matches state set", this.state));
          this.setState({liveMatchResp: response.data, customCssTransition: 'v-transition'});
        //   console.log("Live match response", response);
        }
        catch(err) {
          console.log('MC err',err);
        };
    }

    componentDidMount() {
        if (document.readyState === 'complete') { // if document has finished loading all the scripts
            this.handleSwipe.call(); // call videos swipe initialization
        } else { // if document is still loading scripts i.e readyState === 'loading'
            this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
            window.addEventListener('load', this.handleSwipe); // add listener
        };
        const pusher = new Pusher(Globals.pusherKey, {
            cluster: 'ap1',
            encrypted: true
        });
        const channel = pusher.subscribe('live_matches');
        channel.bind('match_update', this.liveMatchHandler);
        channel.bind('refresh', this.refresh);
        this.getFromApi();
        // console.log("Carousel did mount", this.props.data);

    }

    componentWillUnMount=()=>{
        if (this.windowLoadListener) window.removeEventListener('load', this.handleSwipe); //remove listener if listening
    }

    showHandles(){
        var scrollwidth = document.getElementsByClassName('cardss')[0].scrollWidth;
        var clientwidth = document.getElementsByClassName('cardss')[0].clientWidth;
        if(scrollwidth > clientwidth){
            this.setState({leftHandleClass: 'show'});
            this.setState({rightHandleClass: 'show'});

        }
    }

    hideHandles(){
        this.setState({leftHandleClass: ''});
        this.setState({rightHandleClass: ''});

    }

    scrollleft(){
        var left = document.getElementsByClassName("cardss")[0];
        left.scrollLeft = left.scrollLeft - 219;
    }

    scrollright(){
        var right = document.getElementsByClassName("cardss")[0];
        right.scrollLeft = right.scrollLeft + 219;

    }



  render() {
      const { selectedMatchId } = this.props;
    return (
        
        <div id="main-carousel-container" className="container  zi-1 position-relative" onMouseEnter={this.showHandles} onMouseLeave={this.hideHandles}>
                <div className="carousel-left position-absolute" onClick={this.scrollleft}><i className={"fa fa-angle-left on-hover " + this.state.leftHandleClass}></i></div>
                <div className="carousel-right position-absolute" onClick={this.scrollright}><i className={"fa fa-angle-right on-hover "+this.state.rightHandleClass}></i></div>
            <div className={"cardss "+this.state.customCssTransition} >

                {   
                    (this.state.liveMatchResp.hasOwnProperty('live_matches')) ?
                    this.state.liveMatchResp.live_matches.map( (obj, i) => 
                        
                        getMatchCard(obj, i, selectedMatchId)


                    ) : <div></div>
                }

            

            </div>
        </div> 
    );
  }
}

const getMatchCard = (obj, i, selectedMatchId) => (
    
        (obj.match_state === 'Scheduled')?
        (<div key={"lv-match-c-"+i} className="card main-carousel">
            
            <div className="card-body m-f-11 py-2 px-2 topline">
                    
                {/* <span className="font-weight-semi-bold">{getMatchStateText(obj)}</span> */}
                <span>{" "+obj.title+" at "}</span>
                <span className="text-dark-grey">{obj.venue.title}
                </span>
                <span>{" on "}</span>
                <span className="text-dark-grey">{getDateWithoutYear(obj.match_start)
                +", "+getTimeInAmPm(obj.match_start)}
                </span>
            
                    
            </div>
            {
                MatchScorecardInningsDetails(obj, 1, true)
            }
            {
                MatchScorecardInningsDetails(obj, 2, true)
            }
        </div>):
        (
            <Link to={{
                pathname: "/match/"+obj.id+"/summary/1",
                state: { matchId: obj.id }
              }} key={"lv-match-c-"+i} className={(selectedMatchId === obj.id) ? "card main-carousel selected-container" : "card main-carousel"}>
                <div className="card-body m-f-11 py-2 px-2 topline">
                    <span className="font-weight-semi-bold red">{getMatchStateText(obj)}</span>
                    <div className="clearfix"></div>
                    <span>{" "+obj.title+" at "}</span>
                    <span className="text-dark-grey">{obj.venue.title}
                    </span>
                </div>
                {
                    MatchScorecardInningsDetails(obj, 1, true)
                }
                {
                    MatchScorecardInningsDetails(obj, 2, true)
                }
            </Link>
        )
);

export default Maincarousel;
