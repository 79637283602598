import {Telco} from './telco';

export const Globals = {og:{}, login:{}, pk:{}}


// !!!!!!!!!!!~~~~~CREATE A FILE AND ADD TELCO~~~~~!!!!!!!!!!!
// !!!!!!!!!!!  src/telco.js                       !!!!!!!!!!!
// !!!!!!!!!!!  export const Telco = "mobilink";   !!!!!!!!!!!


let version ='2.0';




if(Telco === 'mobilink'){
    // Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
    Globals.ApiUrl = "https://back.cricwick.net/";//live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
    Globals.pusherKey = "44382e5777aebbc67aad";//live
    Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
    Globals.NodeApi = "https://staging.cricwick.net:13002/";
    //Globals.NodeApi = "http://localhost:3002/";

    Globals.ssl = true;
    Globals.firebase = true;

    Globals.allowLowBalance = true;

    Globals.logo = "/images/mobilink/jazz_logo.png?v=1";
    Globals.imgRightAppDownload = "/images/mobilink/app_download.png";
    Globals.title = "Jazz Cricket - Enjoy live cricket and watch key cricket moments";
    Globals.telco = Telco;
    Globals.cssFor = Telco;
    Globals.product = "Jazz Cricket";
    Globals.ios = "https://itunes.apple.com/pk/app/jazz-cricket/id1371362811?mt=8";
    Globals.androidMarket = 'market://details?id=com.Khaleef.CricWickMobilink';
    Globals.android = 'https://play.google.com/store/apps/details?id=com.Khaleef.CricWickMobilink';

    Globals.og.title = "Jazz Cricket";
    Globals.og.description = "Enjoy live cricket and watch key cricket moments";
    Globals.og.image = "/images/mobilink/og-image.jpg";

    Globals.regex  = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;
    Globals.login.enterNumber = 'Please Enter Your Number';
    Globals.login.phonePlaceholder = '03xx xxxx xxx';
    Globals.login.pinPlaceholder = 'XXXXX';
    Globals.login.enterPin = 'Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.';
    Globals.login.image = "/images/mobilink/img-download.jpg";
    Globals.login.invalidPhone = "Please enter phone number in following format "+Globals.login.phonePlaceholder;

    Globals.placeholder4x3 = "/images/mobilink/placeholder4x3.jpg";
    Globals.placeholder16x9 = "/images/mobilink/placeholder16x9.jpg";
    Globals.placeholderVideo4x3 = "/images/mobilink/placeholderVideo4x3.jpg";
    Globals.placeholderPlain4x3 = "/images/mobilink/placeholderPlain4x3.jpg";

}else if(Telco === 'ufone'){
    Globals.regex  = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;

     // Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
     Globals.ApiUrl = "https://back.cricwick.net/";//live
     // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
     Globals.pusherKey = "44382e5777aebbc67aad";//live
     Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
     Globals.NodeApi = "https://staging.cricwick.net:13002/";
     //Globals.NodeApi = "http://localhost:3002/";

     Globals.ssl = false;
     Globals.firebase = false;

     Globals.allowLowBalance = true;

     Globals.logo = "/images/ufone/ufone_logo.jpg?v=1";
     Globals.imgRightAppDownload = "/images/ufone/app_download.png";
     Globals.title = "Ufone Cricket - Enjoy live cricket and watch key cricket moments";
     Globals.telco = Telco;
     Globals.cssFor = Telco;
     Globals.product = "Ufone Cricket";
     Globals.ios = "https://itunes.apple.com/pk/app/ufone-cricket/id1380145518";
     Globals.androidMarket = 'market://details?id=com.ufone.cricket';
     Globals.android = 'https://play.google.com/store/apps/details?id=com.ufone.cricket';

     Globals.og.title = "Ufone Cricket";
     Globals.og.description = "Enjoy live cricket and watch key cricket moments";
     Globals.og.image = "/images/ufone/og-image.jpg";

     Globals.disableSideLogin = true;
     Globals.login.enterNumber = 'Please Enter Your Number';
     Globals.login.phonePlaceholder = '03xx xxxx xxx';
     Globals.login.SubscriptionPackages = true;
     Globals.login.pinPlaceholder = 'XXXXX';
     Globals.login.enterPin = 'Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.';
     Globals.login.image = "/images/ufone/img-download.jpg";
     Globals.login.invalidPhone = "Please enter phone number in following format "+Globals.login.phonePlaceholder;

     Globals.placeholder4x3 = "/images/ufone/placeholder4x3.jpg";
     Globals.placeholder16x9 = "/images/ufone/placeholder16x9.jpg";
     Globals.placeholderVideo4x3 = "/images/ufone/placeholderVideo4x3.jpg";
     Globals.placeholderPlain4x3 = "/images/ufone/placeholderPlain4x3.jpg";

}else if(Telco === 'zain'){
    //zain ksa; cric.sa.zain.com
    // Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
    Globals.ApiUrl = "https://back.cricwick.net/";//live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
    Globals.pusherKey = "44382e5777aebbc67aad";//live
    Globals.Api2 = "http://mobisub.cricwick.mobi/main/";
    Globals.NodeApi = "https://staging.cricwick.net:13002/";
    //Globals.NodeApi = "http://localhost:3002/";

    Globals.ssl = false;
    Globals.firebase = false;

    Globals.allowLowBalance = true;

    Globals.logo = "/images/zain/cricwick-logo.jpg?v="+version;
    Globals.telcoLogo = "/images/zain/telco_logo.png";
    Globals.imgRightAppDownload = "/images/zain/app_download.png?v=11";
    Globals.title = "Cricwick - Enjoy live cricket and watch key cricket moments";

    Globals.telco = Telco;
    Globals.cssFor = Telco;

    Globals.product = "Cricwick";
    Globals.ios = "https://itunes.apple.com/us/app/cricwick/id1436495759?ls=1&mt=8";
    Globals.androidMarket = 'market://details?id=com.Khaleef.cricwick';
    Globals.android = 'https://play.google.com/store/apps/details?id=com.Khaleef.cricwick';


    Globals.og.title = "Cricwick";
    Globals.og.description = "Enjoy live cricket and watch key cricket moments";
    Globals.og.image = "";

    Globals.regex  = /^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/;
    Globals.login.enterNumber = 'Please Enter Your Number';
    Globals.login.phonePlaceholder = '966 xxx xxx xxx';
    Globals.login.pinPlaceholder = 'XXXXX';
    Globals.login.enterPin = 'Please enter the Pin code you received in SMS.';
    Globals.login.image = "/images/zain/img-download.jpg?v="+version;
    Globals.login.invalidPhone = "Please enter phone number in following format "+Globals.login.phonePlaceholder;

    Globals.placeholder4x3 = "/images/zain/placeholder4x3.jpg";
    Globals.placeholder16x9 = "/images/zain/placeholder16x9.jpg";
    Globals.placeholderVideo4x3 = "/images/zain/placeholderVideo4x3.jpg";
    Globals.placeholderPlain4x3 = "/images/zain/placeholderPlain4x3.jpg";

}else{
    // Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
    Globals.ApiUrl = "https://back.cricwick.net/";//live
    // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
    Globals.pusherKey = "44382e5777aebbc67aad";//live
    Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
    //Globals.NodeApi = "http://localhost:3002/"; //local
    //Globals.NodeApi = "https://staging.cricwick.net:13002/";//ssl live
    Globals.NodeApi = "http://adsksa.mobi:3002/";//ssl live

    Globals.ssl = false;
    Globals.firebase = false;

    Globals.allowLowBalance = true;

    Globals.logo = "/images/cricwick/cricwick-logo.jpg?v=1";
    Globals.imgRightAppDownload = "/images/cricwick/app_download.png?v="+version;
    Globals.title = "Cricwick - Enjoy live cricket and watch key cricket moments";

    Globals.telco = '';
    Globals.cssFor = Telco;

    Globals.product = "Cricwick";

    // Globals.ios = 'market://';
    Globals.ios = 'https://play.google.com/store/apps/details?id=com.Khaleef.cricwick';
    Globals.android = 'https://play.google.com/store/apps/details?id=com.Khaleef.cricwick';
    Globals.androidMarket = 'market://details?id=com.Khaleef.cricwick';
    Globals.pk.ios = 'https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong';
    Globals.pk.android = 'https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong';
    Globals.pk.androidMarket = 'market://details?id=com.Khaleef.CricWick.TelenorZong';

    Globals.og.title = "Cricwick";
    Globals.og.description = "Enjoy live cricket and watch key cricket moments";
    Globals.og.image = "";

    Globals.login.enterNumber = 'Please Enter Your Number';
    Globals.login.phonePlaceholder = '03xx xxxx xxx';
    Globals.login.pinPlaceholder = 'XXXXX';
    Globals.login.enterPin = 'Please enter the Pin code you received in SMS.';
    Globals.login.image = "/images/cricwick/img-download.jpg?v="+version;
    Globals.login.invalidPhone = "Please enter phone number in following format "+Globals.login.phonePlaceholder;
    Globals.login.chooseTelco = "Please Select Your Network Operator";
    // Globals.login.chooseTelco = "Please Choose Your Operator";

    Globals.telcos = [
        {
            telco: "telenor",
            name: "telenor (PK)",
            b: "mobilink",
            flag : '/images/flags/pak.jpg',
            regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: Globals.login.phonePlaceholder,
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                invalidPhone: Globals.login.invalidPhone
            }
        },
        {
            telco: "zong",
            name: "zong (PK)",
            b: "mobilink",
            flag : '/images/flags/pak.jpg',
            regex: /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/,
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: Globals.login.phonePlaceholder,
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                invalidPhone: Globals.login.invalidPhone
            }
        },
        {
            telco: "mobily",
            name: "mobily (KSA)",
            b: "zain",
            flag : '/images/flags/ksa.jpg',
            regex:/^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "966xxxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                invalidPhone: `Please enter phone number in following format ${" 966xxxxxxxxx"}`
            }
        },
        {
            telco: "stc",
            name: "STC (KSA)",
            b: "zain",
            flag : '/images/flags/ksa.jpg',
            regex:/^9665\d{8}$|^05\d{8}$|^5\d{8}$|^009665\d{8}$|^\+9665\d{8}$|^\+009665\d{8}$|^966111111111$/,
            telco_message: "*Billing enabled by STC - 0.65SAR / Day",
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "966xxxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                // invalidPhone: "Please enter phone number in following format" + " 966xxxxxxxxx" commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 966xxxxxxxxx"}`
            }
        },
        {
            telco: "zain_bh",
            name: "Zain (BH)",
            b: "zain",
            flag : '/images/flags/bhr.jpg',
            regex:/^9733\d{7}$|^03\d{7}$|^9661111111\d{2}$|^3\d{7}$/,
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "973xxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                // invalidPhone: "Please enter phone number in following format" + " 973xxxxxxxx" commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 973xxxxxxxx"}`
            }
        },
        {
            telco: "zain_kw",
            name: "Zain (KW)",
            b: "zain",
            flag : '/images/flags/kwt.jpg',
            regex: /^965\d{8}$|^05\d{8}$|^5\d{8}$|^9661111111\d{2}$/,
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "965xxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                // invalidPhone: "Please enter phone number in following format" + " 965xxxxxxxx" commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 965xxxxxxxx"}`
            }
        },
        {
            telco: "ooredoo",
            name: "Ooredoo (QA)",
            b: "zain",
            flag : '/images/flags/qtr.jpg',
            regex:/^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "974xxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx" commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`
            }
        },
        {
            telco: "ooredoo_kw",
            name: "Ooredoo (KW)",
            b: "zain",
            regex:/^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
            consentGatewayRedirection: true,
            flag : '/images/flags/kwt.jpg',
            cgRedirectionUrl: window.location.origin+"/authentication/",
            cgUrl : "http://galaxylp.mobi-mind.net/?Id=703,CRICKWICK,965,2695,45,"+    window.location.origin+"/authentication/"     +",0,41903,4545",
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "974xxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                //invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx" commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`
            }
        },
        {
            telco: "bbh",
            name: "Batelco (BH)",
            b: "zain",
            regex:/^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
            consentGatewayRedirection: true,
            flag : '/images/flags/bhr.jpg',
            cgRedirectionUrl: window.location.origin+"/authentication/",
            // cgUrl : "http://galaxylp.mobi-mind.net/?Id=701,CRICKWICK,973,2695,45,"+ window.location.origin+"/authentication/" +",0,42601,4542&IURL=http://galaxylp.mobi-mind.net/Cricwick/banner.jpg&MM_languageId=3",
            cgUrl : "http://galaxylp.mobi-mind.net/?Id=701,CRICKWICK,973,2695,361,"+ window.location.origin+"/authentication/" +",0,42601,4542&IURL=http://galaxylp.mobi-mind.net/Cricwick/banner.jpg&MM_languageId=3",
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "974xxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx" commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`
            }
        },
        {
            // cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,18,"+ window.location.origin+"/authentication/" +",0,41904,2395",
            telco: "viva_kw",
            name: "Viva (KW)",
            b: "zain",
            flag : '/images/flags/kwt.jpg',
            regex:/^9661111111\d{2}$|974\d{8}$|055\d{6}$|033\d{6}$|066\d{6}$/,
            consentGatewayRedirection: true,
            cgRedirectionUrl: window.location.origin+"/authentication/",
            cgUrl : "http://galaxylp.idextelecom.com/?Id=257,Cricket,965,8769,361,"+ window.location.origin+"/authentication/" +",0,41904,2395",
            login:{
                enterNumber: Globals.login.enterNumber,
                phonePlaceholder: "974xxxxxxxx",
                pinPlaceholder: Globals.login.pinPlaceholder,
                enterPin: Globals.login.enterPin,
                image: Globals.login.image,
                // invalidPhone: "Please enter phone number in following format" + " 974xxxxxxxx"  commented by Tariq
                invalidPhone: `Please enter phone number in following format ${" 974xxxxxxxx"}`
            }
        }
    ];

    Globals.placeholder4x3 = "/images/cricwick/placeholder4x3.jpg";
    Globals.placeholder16x9 = "/images/cricwick/placeholder16x9.jpg";
    Globals.placeholderVideo4x3 = "/images/cricwick/placeholderVideo4x3.jpg";
    Globals.placeholderPlain4x3 = "/images/cricwick/placeholderPlain4x3.jpg";
}
