
import React, { Component } from 'react';
import axios                from 'axios';
//import {Redirect} from 'react-router-dom';
// import { Link } from "react-router-dom";

import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import { Globals }                                             from './constants';
import { isNumberValid, loginUser, tempUser, getEnterPinText, getTelcoOptions, setCGObject } from './HelperFunctions';

import '../src/css/flags.css';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            phone               : '',
            pin                 : '',
            inputName           : 'phone',
            inputValue          : (this.props.msisdn)? this.props.msisdn : '',
          
            inputName1          : '',  //tariq
            inputValue1         : '',  //tariq

            telco               : Globals.telco,
            regex               : Globals.regex,
            showPhone           : (Globals.telco)? "" : "d-none",
            phonePlaceholder    : Globals.login.phonePlaceholder,
            SubscriptionPackage : (Globals.login.SubscriptionPackages) ? "daily" : "",
            pinPlaceholder      : Globals.login.pinPlaceholder,

            telcoMessageClass   : "d-none",
            telcoMessage        : "",
            pinSent             : false,
            disableTelcoSelect  : "",
            task                : 'phone',
            user                : [],
            redirect            : false,
            btnTxt              : 'Continue',
            btnDisbaled         : false,
            message             : '',

            heading             : Globals.login.enterNumber,
            enterPin            : Globals.login.enterPin,

            strings: {
                somethingWentWrong: 'Something went wrong. Please try again later!',
                invalidPin        : 'Invalid Pincode!',
                invalidPhone      : Globals.login.invalidPhone
            },

            selectedTelco : [{name:'Choose operator', flag:''}], //Tariq
            dropdownOpen  : false //Tariq
        };

        this.handleKeypress    = this.handleKeypress.bind(this);
        this.login             = this.login.bind(this);
        this.loginValuesChange = this.loginValuesChange.bind(this);
        this.apiPath           = Globals.NodeApi+"api/";
        this.setTelco          = this.setTelco.bind(this);
        this.textInput         = React.createRef();

        //////////// Tariq
        this.toggle            =  this.toggle.bind(this);

    };


    componentDidMount(){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }

    componentWillReceiveProps(nextProps){
        console.log('ateeb i am recieving!!', nextProps);
        if(nextProps.msisdn){
            this.setState({inputValue: nextProps.msisdn});
        }
    }

    handleKeypress = function(e) {
        if(e.key === 'Enter'){
            this.login();
        }
    }

    validatePhone(phoneNumber, regexString) {
        if (!phoneNumber) {
            return false;
        } else if (!regexString.test(phoneNumber)) {
            return false;
        } else {
            return true;
        }
    }

    async login() {
        const phone = this.state.phone;
        let serverResponse = {};
        this.setState({ btnTxt: 'Please Wait ...', btnDisbaled: true, message: '' });
        if (!this.state.pinSent) { //appstart or send pin

            let telco = this.state.telco;
            let regex = this.state.regex;

            const result = this.validatePhone(phone, regex);
            if (!result || !phone){
                this.setState({ btnTxt: 'CONTINUE', btnDisbaled: false, message: "Invalid Phone!" });
                return false;
            }

            try {
                const url = this.apiPath + "login_or_sendpin?telco=" + this.state.telco + "&phone=" + phone + "&sub_type=" + this.state.SubscriptionPackage;

                console.log("Login Server Response : ",url);
                serverResponse = await axios.get(url, {
                    // withCredentials: true,
                    // mode: 'cors',
                    // headers: {
                    //     'Access-Control-Allow-Credentials': true,
                    //     'Access-Control-Allow-Origin': true

                    // }

                });

                serverResponse = serverResponse.data;
                console.log("Tariq Generic Response : ",serverResponse.generic_response);

            } catch (err) {
                console.log("Error logging in", err);
            };
            console.log("login response", serverResponse)

            if (serverResponse) {
                let generic_response = {}
                if( (Object.keys(serverResponse).length === 0 && serverResponse.constructor === Object) || (!serverResponse.remote_response && !serverResponse.generic_response) ){
                    //empty response, means no response from  node server
                    generic_response = tempUser(phone);
                }else if(!serverResponse.status && serverResponse.generic_response && serverResponse.generic_response.call_type && serverResponse.generic_response.call_type === 'appstart' && !serverResponse.error) {
                    //appstart failed, remote server must not be responding
                    generic_response = tempUser(phone);
                }else if (!serverResponse.status) {
                    //server responded but ERROR !
                    this.setState({ message: serverResponse.generic_response.message, btnTxt: 'Continue', btnDisbaled: false });
                } else {
                    generic_response =    serverResponse.generic_response;
                };
                if (generic_response.subscribed) {
                    this.setState({ message: '' });
                    // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
                    generic_response.phone = phone;
                    generic_response.telco = this.state.telco
                    localStorage.setItem('user_logged_in', true);
                    localStorage.setItem('user', JSON.stringify(generic_response));
                    loginUser(generic_response);
                    window.location.reload();
                    return false;
                }
            }

            if (serverResponse.status && !serverResponse.generic_response.subscribed) { //pin sent
                var element = document.getElementById('telcoselectL');
                if(element){
                    element.parentNode.removeChild(element);
                }

                let inputName1  = this.state.inputName;  // Tariq
                let inputValue1 = this.state.inputValue;  // Tariq
                
                //Changes states for inputName1 and inputValue1

                this.setState({ inputName1: inputName1, inputValue1:inputValue1  ,heading: getEnterPinText(this.state.enterPin, serverResponse), phonePlaceholder: 'xxxx', inputValue: '', inputName: 'pin', pinSent: true, message: '', btnTxt: 'Continue', btnDisbaled: false  });
                
                if(serverResponse.remote_response && serverResponse.remote_response.telco_message){
                    // if node server for zain_bh, zain_kw, etc gives telco message from api
                    this.setState({telcoMessageClass: "", telcoMessage: serverResponse.remote_response.telco_message});
                }
            }
        } else { //pin is sent so call confirm pin start
            const pin = this.state.pin;
            console.log('pin', pin);
            console.log("cookie token", getCookie('cricketapptoken'))
            try {
                const url = this.apiPath + "confirmpin?telco="+this.state.telco+"&phone="+phone+"&pin="+pin+"&sub_type="+this.state.SubscriptionPackage;
                serverResponse = await axios.get(url, {
                    // withCredentials: true,
                    // mode: 'cors',
                    // headers: {
                    //     'Access-Control-Allow-Credentials': true,
                    //     'Access-Control-Allow-Origin': true
                    // }

                });
                serverResponse = serverResponse.data;
            } catch (err) { console.log("Error confirming pin", err); };

            console.log("confirm pin response", serverResponse)

            if (serverResponse) {
                let generic_response = {}
                if(Object.keys(serverResponse).length === 0 && serverResponse.constructor === Object){
                    // no server response, ERROR ! login with a temporary user
                    generic_response = tempUser(phone);
                }
                else if (!serverResponse.status) {
                    this.setState({ message: serverResponse.generic_response.message, btnTxt: 'Continue', btnDisbaled: false });
                } else {
                    generic_response = serverResponse.generic_response;
                };


                if (generic_response.subscribed) {
                    this.setState({ message: '' });
                    // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
                    generic_response.phone = phone;
                    generic_response.telco = this.state.telco
                    localStorage.setItem('user_logged_in', true);
                    localStorage.setItem('user', JSON.stringify(generic_response));
                    loginUser(generic_response);

                    window.location.reload();
                }
            };
        };

    }


    loginValuesChange = (event) => {
        console.log("Login value change", event.target.name, event.target.value);
        this.setState({[event.target.name]: event.target.value, inputValue: event.target.value});
    }


    setTelco = (e) => {
        e = e.target.value;
        let val = (e) ? JSON.parse(e):'';
        let setState = {telco: "", showPhone: "d-none", telcoMessageClass: "d-none", telcoMessage:""};
        this.setState(setState);
        if(val){
            setState.telco = val.telco;
            const _telco =  Globals.telcos.filter(x => x.telco === val.telco);
            if(!val.consentGatewayRedirection){
                setState.showPhone = "";
                setState.heading = val.login.enterNumber;
                setState.enterPin = val.login.enterPin;
                setState.phonePlaceholder = val.login.phonePlaceholder;
                setState.pinPlaceholder = val.login.pinPlaceholder;
                setState.invalidPhone = val.login.invalidPhone;
                setState.regex = _telco[0].regex;

                if(val.telco_message){
                    setState.telcoMessageClass = "";
                    setState.telcoMessage = val.telco_message;
                }
                this.setState(setState);
                this.textInput.current.focus();
            }else{
                //redirect to CG
                let telco = val.telco,
                    referer = window.location.href;
                    setCGObject(telco, referer);
                window.location.href = val.cgUrl;
                return;
            }

        }
    }

   //////// By Tariq ///////////////
    setTelco_t = (val) => {
        // e = e.target.value;
        // let val = (e) ? JSON.parse(e):'';
        let setState = {telco: "", showPhone: "d-none", telcoMessageClass: "d-none", telcoMessage:"", selectedTelco:{name:"", flag:""}};

        this.setState(setState);

        if(val){
            setState.telco = val.telco;
            const _telco =  Globals.telcos.filter(x => x.telco === val.telco);
            if(!val.consentGatewayRedirection){
                setState.showPhone        = "";
                setState.heading          = val.login.enterNumber;
                setState.enterPin         = val.login.enterPin;
                setState.phonePlaceholder = val.login.phonePlaceholder;
                setState.pinPlaceholder   = val.login.pinPlaceholder;
                setState.invalidPhone     = val.login.invalidPhone;
                setState.regex            = _telco[0].regex;
                setState.selectedTelco.name    = val.name;
                setState.selectedTelco.flag    = val.flag;

                if(val.telco_message){
                    setState.telcoMessageClass = "";
                    setState.telcoMessage = val.telco_message;
                }

                this.setState(setState);
                this.textInput.current.focus();

            }else{
                //redirect to CG
                let telco = val.telco,
                    referer = window.location.href;
                    setCGObject(telco, referer);
                window.location.href = val.cgUrl;
                return;
            }

        }
    }

    ///////////// End Tariq ////////////////////////

    setSubscriptionPackage = (e) => {
        e = e.target.value;
        let val = (e) ? e:'';
        this.setState({SubscriptionPackage : val});
        console.log('package', val);
    }


    ///// Tariq ///////////////////////////////

    handleSelect = (telco) => {
        console.log(telco);
        this.setState(()=>{
                return{
                    selectedTelco : telco
                }
        })
    }

    toggle() {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
    }

    renderTelcosList=(telcos)=>{
        return(
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
                <DropdownToggle color='default'
                    style={{
                        width: 100 + '%', textAlign: 'left',
                        backgroundColor: 'white',
                        borderBottomColor: 'lightgray',
                        paddingLeft: 0,
                        paddingBottom: 8 + 'px',
                        borderRadius: 0
                    }}
                >
                    <span>
                        <img src={this.state.selectedTelco.flag} alt='' style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }} />
                    </span>

                    <span style={{ fontSize: 1.2 + 'em', paddingLeft: 10 + 'px', textTransform: 'capitalize' }}>
                           {this.state.selectedTelco.name ? this.state.selectedTelco.name : 'Choose operator'}
                    </span>

                    <i className="fa fa-angle-down" style={{float: 'right', fontSize: '18px'}}></i>

                </DropdownToggle>

                <DropdownMenu style={{ width: 100 + '%', borderRadius: 0 }}>
                    {Globals.telcos.map((telco, i) => (
                        <DropdownItem key={i} value={telco.name}
                            onClick={() => this.setTelco_t(telco)}
                        //    onChange={()  => this.setTelco_t(telco)}
                        >
                            <span >
                                <img src={telco.flag} alt='' style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }} />
                            </span>

                            <span style={{ textTransform: 'capitalize', fontSize: 1.2 + 'em', paddingLeft: 10 + 'px' }} >
                                {telco.name}
                            </span>

                        </DropdownItem>
                    ))
                    }
                </DropdownMenu>
            </Dropdown>
        )
    }

    //==================================
    renderTelcosForPin=()=>{
        return(
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
                <DropdownToggle color='default'
                    style={{
                        width: 100 + '%', textAlign: 'left',
                        backgroundColor: 'white',
                        borderBottomColor: 'lightgray',
                        paddingLeft: 0,
                        paddingBottom: 8 + 'px',
                        borderRadius: 0
                    }}
                >
                    <span>
                        <img src={this.state.selectedTelco.flag} alt='' style={{ boxShadow: '0px  1px  1px  0px gray', borderRadius: 3 }} />
                    </span>

                    <span style={{ fontSize: 1.2 + 'em', paddingLeft: 10 + 'px', textTransform: 'capitalize' }}>
                           {this.state.selectedTelco.name}
                    </span>
                    
                    {/* <span style={{ float: 'right' }}>
                        <i className="fas fa-chevron-down"></i>
                    </span> */}
                </DropdownToggle>
            </Dropdown>
        )
    }  

    //==================================

 renderLogin=()=>{
   
    return (
      <div>
        {!Globals.telco ? (
          ////////////////////Tariq Sulehri Working //////////////////
          <div>

            {this.renderTelcosList(Globals.telcos)}
          </div>
        ) : (
          ""
        )}

        <div className={this.state.showPhone}>
          <input
            name={this.state.inputName}
            className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0"
            type="text"
            ref={this.textInput}
            placeholder={this.state.phonePlaceholder}
            value={this.state.inputValue}
            onChange={this.loginValuesChange}
            onKeyPress={this.handleKeypress}
          />

          <p className="m-0 message mt-2 text-danger">
            {this.state.message}
          </p>

          <input type="hidden" name="flow" value={this.state.task} />
          {Globals.login.SubscriptionPackages && !this.state.pinSent ? (
         
          <div className="radio">
            
              <label className="radio-inline">
                <input
                  type="radio"
                  value="daily"
                  name="radioB"
                  defaultChecked
                  onChange={this.setSubscriptionPackage}
                />{" "}
                Daily{" "}
              </label>

              <label className="radio-inline">
                <input
                  type="radio"
                  value="monthly"
                  name="radioB"
                  onChange={this.setSubscriptionPackage}
                />{" "}
                Monthly{" "}
              </label>


            </div>
          ) : (
            ""
          )}
          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.login}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>
          {!Globals.telco && Globals.telcos ? (
            <p className={"mt-2 " + this.state.telcoMessageClass}>
              {this.state.telcoMessage}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    );

 }
//=====================================================
renderPin=()=>{
    return (
      <div>
          
        <div>{this.renderTelcosForPin()}</div>

        <div className={this.state.showPhone}>
          <input
            name={this.state.inputName1}
            className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0"
            type="text"
            value={this.state.inputValue1}
            disabled={true}
          />

          <input
            name={this.state.inputName}
            className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0"
            type="text"
            ref={this.textInput}
            autoFocus={true}
            placeholder={this.state.phonePlaceholder}
            value={this.state.inputValue}
            onChange={this.loginValuesChange}
            onKeyPress={this.handleKeypress}
          />

          <p className="m-0 message mt-2 text-danger">
            {this.state.message}
          </p>

          <input type="hidden" name="flow" value={this.state.task} />
          {Globals.login.SubscriptionPackages && !this.state.pinSent ? (
         
          <div className="radio">
            
              <label className="radio-inline">
                <input
                  type="radio"
                  value="daily"
                  name="radioB"
                  defaultChecked
                  onChange={this.setSubscriptionPackage}
                />{" "}
                Daily{" "}
              </label>

              <label className="radio-inline">
                <input
                  type="radio"
                  value="monthly"
                  name="radioB"
                  onChange={this.setSubscriptionPackage}
                />{" "}
                Monthly{" "}
              </label>


            </div>
          ) : (
            ""
          )}

          <button
            className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
            onClick={this.login}
            disabled={this.state.btnDisbaled}
          >
            {this.state.btnTxt}
          </button>
          {!Globals.telco && Globals.telcos ? (
            <p className={"mt-2 " + this.state.telcoMessageClass}>
              {this.state.telcoMessage}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    );

 }




  render() {
      return (
        <div id="main-section">
            <div className="register-login card p-4 pb-5 px-lg-5 py-lg-4">
                <h4 className="pb-3 text-black border-bottom mb-5 font-weight-semi-bold m-f-14">Register | Login</h4>

                <p className="heading pt-2 pb-0 mb2 mt-lg-4 text-black fs-1p2 m-f-13">
                    {(this.state.telco)?this.state.heading : Globals.login.chooseTelco}
                </p>

                { 
                  !this.state.pinSent ?   this.renderLogin()   :    this.renderPin()
                } 

                {/* ////////////////////Tariq Sulehri Working ////////////////// */}
                {/* {(!Globals.telco ?

                     <div> */}
                                   {/* <select id="telcoselectL" onChange={this.setTelco} className="nostyle border-bottom bg-transparent w-100 py-3 px-1 h5 m-0 text-capitalize" disabled={this.state.disableTelcoSelect}>
                                      <option value="">Choose operator</option>
                                         {getTelcoOptions(Globals.telcos)}
                                   </select> */}
                              {/* {this.renderTelcosList(Globals.telcos)}

                      </div>
                 : "")} */}

                {/* <div className={this.state.showPhone}>
                    <input name={this.state.inputName} className="phone nostyle border-bottom bg-transparent w-100 py-3 px-2 h5 m-0" type="text"
                        ref={this.textInput}
                        placeholder={this.state.phonePlaceholder} value={this.state.inputValue} onChange={this.loginValuesChange}
                        onKeyPress={this.handleKeypress}
                    />
                    <p className="m-0 message mt-2 text-danger">{this.state.message}</p>
                    <input type="hidden" name="flow" value={this.state.task} />
                    {(Globals.login.SubscriptionPackages && !this.state.pinSent ) ?
                    <div className="radio">
                        <label className="radio-inline"><input type="radio" value="daily" name="radioB" defaultChecked onChange={this.setSubscriptionPackage} /> Daily </label>
                        <label className="radio-inline"><input type="radio" value="monthly" name="radioB"  onChange={this.setSubscriptionPackage} /> Monthly </label>
                    </div>
                     : "" }
                    <button className="submit border-0 w-50 btn bg-red text-white rounded-0 mt-4 p-3 mb-lg-5 text-uppercase font-weight-bold fs-1p3"
                    onClick={this.login} disabled={this.state.btnDisbaled} >{this.state.btnTxt}</button>
                    {
                        (!Globals.telco && Globals.telcos)?
                        (<p className={"mt-2 "+this.state.telcoMessageClass}>{this.state.telcoMessage}</p>):""
                    }
                </div> */}


                <a href="javascript:;" className="app-download my-lg-5 py-lg-5 mt-5" onClick={this.props.downloadApp}>
                    <img className="w-100" src={ Globals.login.image } alt="" />
                </a>
            </div>
        </div>

    );
  }
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default Login;
