import React, { Component } from "react";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import Maincarousel from "./Maincarousel";
import Leftsection from "./Leftsection";
import Rightsection from "./Rightsection";
import Login from "./Login";
import AllSeries from "./AllSeries";
import Series from "./Series";
import Stories from "./Stories";
import Videos from "./Videos";
import Rankings from "./Rankings";
import Match from "./Match";
import SeriesSchedules from "./SeriesSchedules";
import SeriesPointsTable from "./SeriesPointsTable";
import SeriesVideos from "./SeriesVideos";
import SingleVideo from "./SingleVideo";
import StoriesDetail from "./StoreisDetail";
import Tests from "./Tests";
import Authentication from "./Authentication";
import Home from "./Home";
import NotFound404 from "./404";
import { autoLogin } from "./HelperFunctions";
import { Globals } from "./constants";
import Digital from "./Digital";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "",
      selectedMatchId: 0,
      loginCheck: false,
      telco: this.props.userTelco ? this.props.userTelco : "zain"
    };
    this.setCurrentPath = this.setCurrentPath.bind(this);
    this.downloadApp = this.downloadApp.bind(this);
  }

  setCurrentPath(path, state) {
    let paths = path.split("/");
    paths = paths.slice(1, paths.length);
    let matchId = 0;
    if (state && state.matchId) matchId = state.matchId;
    this.setState({ currentPage: "/" + paths[0], selectedMatchId: matchId });
  }

  componentDidMount() {
    this.setCurrentPath(this.props.location.pathname);

    this.props.history.listen(location => {
      this.setCurrentPath(location.pathname, location.state);
    });
  }

  downloadApp(e) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let sUsrAg = navigator.userAgent,
      win,
      url;
    if (iOS) {
      url = this.props.appPathIos;
      win = window.open(url, "_blank");
      win.focus();
    } else {
      if (sUsrAg.indexOf("Android") > -1) {
        url = this.props.marketPath;
        win = window.open(url, "_blank");
        win.focus();
      } else {
        url = this.props.appPath;
        window.open(url);
      }
    }
  }

  render() {
    return (
      <Switch>
        <Route path="/:telco/digital" exact component={Digital} />

        <div>
          <Nav currentPage={this.state.currentPage} {...this.props} />

          <Maincarousel
            selectedMatchId={this.state.selectedMatchId}
            telco={this.state.telco}
          />

          <div id="page" className="container py-4">
            <div className="row">
              <Leftsection
                userLoggedIn={this.props.userLoggedIn}
                telco={this.state.telco}
              />

              {/* <Switch> */}
              <Route
                path="/"
                exact
                render={() => <Home telco={this.state.telco} />}
              />
              <Route
                path="/login"
                exact
                render={props =>
                  this.props.userLoggedIn ? (
                    <Redirect to="/" />
                  ) : (
                    <Login
                      {...props}
                      downloadApp={this.downloadApp}
                      msisdn={this.props.msisdn}
                    />
                  )
                }
              />



              <Route
                path="/series/:id/:title"
                exact
                render={props => <Series {...props} telco={this.state.telco} />}
              />
              <Route path="/allseries/:state" exact component={AllSeries} />
              <Route
                path="/series/:id/:title/schedules"
                exact
                render={props => (
                  <SeriesSchedules {...props} telco={this.state.telco} />
                )}
              />

              <Route
                path="/series/:id/:title/pointstable"
                exact
                component={SeriesPointsTable}
              />

              <Route
                path="/series/:id/:title/videos"
                exact
                render={props =>
                  this.props.userLoggedIn ? (
                    <SeriesVideos
                      {...props}
                      telco={this.state.telco}
                      isVideoAllowed={this.props.isVideoAllowed}
                      isRegionChecked={this.props.isRegionChecked}
                      noVideoReason={this.props.noVideoReason}
                    />
                  ) : (
                    <Login
                      downloadApp={this.downloadApp}
                      {...props}
                      msisdn={this.props.msisdn}
                    />
                  )
                }
              />

              <Route
                path="/series/:id/:title/videos/:videoId"
                exact
                render={props =>
                  this.props.userLoggedIn ? (
                    <SeriesVideos
                      {...props}
                      telco={this.state.telco}
                      isVideoAllowed={this.props.isVideoAllowed}
                      isRegionChecked={this.props.isRegionChecked}
                      noVideoReason={this.props.noVideoReason}
                    />
                  ) : (
                    <Login
                      downloadApp={this.downloadApp}
                      {...props}
                      msisdn={this.props.msisdn}
                    />
                  )
                }
              />

              <Route
                path="/video/:videoId"
                exact
                render={props =>
                  this.props.userLoggedIn ? (
                    <SingleVideo {...props} telco={this.state.telco} />
                  ) : (
                    <Login
                      downloadApp={this.downloadApp}
                      {...props}
                      msisdn={this.props.msisdn}
                    />
                  )
                }
              />

              <Route
                path="/stories"
                exact
                render={() => <Stories type="news" />}
              />
              <Route
                path="/stories/news"
                exact
                render={() => <Stories type="news" />}
              />
              <Route
                path="/stories/articles"
                exact
                render={() => <Stories type="articles" />}
              />
              <Route
                path="/stories/articles/:id"
                exact
                render={match => (
                  <StoriesDetail type="articles" match={match} />
                )}
              />
              <Route
                path="/stories/news/:id"
                exact
                render={match => <StoriesDetail type="news" match={match} />}
              />
              <Route
                path="/videos"
                exact
                render={props => (
                  <Videos
                    {...props}
                    telco={this.state.telco}
                    type="allVideos"
                  />
                )}
              />
              <Route
                path="/videos/featured"
                exact
                render={props => (
                  <Videos {...props} telco={this.state.telco} type="featured" />
                )}
              />
              <Route
                path="/videos/archives"
                exact
                render={props => (
                  <Videos {...props} telco={this.state.telco} type="archives" />
                )}
              />
              <Route path="/rankings" exact component={Rankings} />
              <Route path="/rankings/test" exact component={Rankings} />
              <Route
                path="/rankings/t20"
                exact
                render={() => <Rankings filter={"t20"} />}
              />
              <Route
                path="/rankings/odi"
                exact
                render={() => <Rankings filter={"odi"} />}
              />

              <Route path="/test" exact component={Tests} />
              <Route path="/authentication" exact component={Authentication} />

              <Route
                path="/match/:id/:category/:subcategory"
                exact
                render={props =>
                  this.props.userLoggedIn ||
                  (props.match.params.category !== "videos" &&
                    props.match.params.subcategory !== "allowStream") ? (
                    <Match
                      {...props}
                      isVideoAllowed={this.props.isVideoAllowed}
                      telco={this.state.telco}
                      isRegionChecked={this.props.isRegionChecked}
                      noVideoReason={this.props.noVideoReason}
                    />
                  ) : (
                    <Login
                      downloadApp={this.downloadApp}
                      {...props}
                      msisdn={this.props.msisdn}
                    />
                  )
                }
              />
              <Route component={NotFound404} />
              {/* <Route path="/404notfound" exact component={NotFound404} /> */}
              {/* <Redirect from='*' to='/404notfound' /> */}
              {/* </Switch> */}

              <Rightsection
                downloadApp={this.downloadApp}
                telco={this.state.telco}
              />
            </div>
          </div>

          <Footer downloadApp={this.downloadApp} />
        </div>
      </Switch>
    );
  }
}

export default withRouter(Routes);
