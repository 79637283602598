import React, { Component } from 'react';
import { SquadBox } from './HelperFunctions';
import { Link } from 'react-router-dom';

class MatchSquads extends Component {
    
  render() {
    return (

          <div className={"card mt-2 p-3 "+this.props.customCssTransition}>
            {
                (this.props.hasOwnProperty('match') && this.props.match) ?
                 <div className="d-flex overflow-x-auto py-3 border-bottom">
                    <Link to={"/match/"+this.props.match.id+"/squads/1"}>
                        <h4 className={getActiveClasses(this.props.activeSquadsId, this.props.match, 1)+' text-capitalize'}>{this.props.match.teamA.name}</h4>
                    </Link>
                    <Link to={"/match/"+this.props.match.id+"/squads/2"}>
                        <h4 className={getActiveClasses(this.props.activeSquadsId, this.props.match, 2)+' text-capitalize'}>{this.props.match.teamB.name}</h4>
                    </Link>
                </div> : <div></div>
            }
           
            <div className="pt-4 row">

                {
                    this.props.data.map( (obj, i) => <SquadBox key={"squads-"+i} data={obj} /> )
                }


            </div>
          </div>


    );
  }
};

const getActiveClasses = (id, match, order) => {
    if (order === 1 && id !== match.team_1_id) {
        return "font-weight-semi-bold p-2 ml-4 text-secondary"
    };
    if (order === 2 && id !== match.team_2_id) {
        return "font-weight-semi-bold p-2 ml-4 text-secondary"
    };
    return "font-weight-semi-bold p-2 ml-4 border-bottom border-danger";
};



export default MatchSquads;
