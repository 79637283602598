import HttpsRedirect from 'react-https-redirect';
import React from 'react';
import App from './App';
import { Globals } from './constants';

class HttpsApp extends React.Component {

    render() {
        if(Globals.ssl){
            return (
                <HttpsRedirect>
                    <App />
                </HttpsRedirect>
            );
        }else{
            return (
                <App />
            );
        }
      
    }
  }

export default HttpsApp;