import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Globals } from './constants';
import axios from 'axios';
import {space2hyphen} from './HelperFunctions'

class AllSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
        customCssTransition: 'h-transition',
        isLoading: true,
        allSeriesResp: null,
        selectedLive: (this.props.match.params.state==='live'),
        selectedUpcoming: (this.props.match.params.state==='upcoming'),
        selectedRecent: (this.props.match.params.state==='recent'),
        page: 1,
        pagesEndLive: false,
        pagesEndRecent: false,
        pagesEndUpcoming: false,
    };
    this.getMarkup = this.getMarkup.bind(this);
    this.seriesNav = this.seriesNav.bind(this);
    this.nextPage = this.nextPage.bind(this);
  };

  async nextPage(e) {

    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName('loading-more-results');
      if(elem.length)
        elem[0].classList.remove('d-none');
  
      const page = this.state.page + 1;
      const url = Globals.ApiUrl + "api/v2/series/?page="+page.toString()+"&per_page=10";
      let resp;
      try {
        resp = await axios.get(url);
        console.log("Next page", resp);
      }
      catch(err) {
        console.log("error fetching next page");
      };

      if (resp.status === 200) {
        if(elem.length)
          elem[0].classList.add('d-none');
        
        let allSeriesResp = Object.assign({}, this.state.allSeriesResp);
        let pagesEndLive = false, pagesEndRecent = false, pagesEndUpcoming = false;
        if (!resp.data.live_series.length) pagesEndLive = true;
        if (!resp.data.recent_series.length) pagesEndRecent = true;
        if (!resp.data.upcoming_series.length) pagesEndUpcoming = true;
        allSeriesResp.live_series = allSeriesResp.live_series.concat(resp.data.live_series);
        allSeriesResp.recent_series = allSeriesResp.recent_series.concat(resp.data.recent_series);
        allSeriesResp.upcoming_series = allSeriesResp.upcoming_series.concat(resp.data.upcoming_series);

        this.setState({ allSeriesResp: allSeriesResp, page: page, pagesEndLive: pagesEndLive, 
            pagesEndRecent: pagesEndRecent, pagesEndUpcoming: pagesEndUpcoming }, () => console.log("Set state next page all series", this.state));

      } else {
        // if (resp.data.length===0) this.setState({pagesEnd: true});
        if(elem.length)
          elem[0].classList.add('d-none');
        console.log("Api Error or no results");
      };

    }

  }

    async componentDidMount() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });

        // let seriesId = this.props.match.params.id;
        if (!this.state.allSeriesResp) {
          const url = Globals.ApiUrl+"api/v2/series/?page="+this.state.page+"&per_page=10";
          let resp;
          try {
              resp = await axios.get(url);
              console.log("All series resp", resp);
          }
          catch (err) {
              console.log("Error", err);
              window.location.href = '/404notfound';
          };
          if (resp.status!==200) window.location.href = '/404notfound';
          this.setState({allSeriesResp: resp.data, customCssTransition: 'v-transition', isLoading: false}, () => console.log("All Series set state", this.state));
        }
    }
    componentWillReceiveProps(nextProps) {
        console.log("Next props", nextProps);
        if (nextProps.match.params.state==='live' && !this.selectedLive) 
          this.setState({selectedLive: true, selectedUpcoming: false, selectedRecent: false}, () => console.log("State updated", this.state));
        if (nextProps.match.params.state==='upcoming' && !this.selectedUpcoming) 
          this.setState({selectedUpcoming: true, selectedRecent: false, selectedLive: false}, () => console.log("State updated", this.state));
        if (nextProps.match.params.state==='recent' && !this.selectedRecent) 
          this.setState({selectedRecent: true, selectedUpcoming: false, selectedLive: false}, () => console.log("update", this.state));
    }
    getMarkup() {
        let selected = "";
        if (this.state.selectedLive) selected = "live_series";
        if (this.state.selectedRecent) selected = "recent_series";
        if (this.state.selectedUpcoming) selected = "upcoming_series";
        
        if (this.state.allSeriesResp) {
          return this.state.allSeriesResp[selected].map( (series, i) => {

              return <Link key={"all-s-"+i} to={"/series/"+series.id+"/"+space2hyphen(series.short_name)} className="custom-box p-3">

                <h5 className="m-f-13">{series.title}</h5>
                <h5 className="text-secondary mb-0 m-f-13">{getDate(series.start_date) + ' ' + getMonth(series.start_date) 
                + ' - ' + getDate(series.end_date) + ' ' + getMonth(series.end_date)}
                </h5>
              </Link>
            
            });
        }
    }
    seriesNav(e) {

        if (e.target.id !== this.state.selected) this.setState({selected: e.target.id}, () => console.log("nav", this.state));
    }

  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }

        <div className={this.state.customCssTransition}>
        
            <div className="card p-3">
                <div className="d-flex overflow-x-auto">
                    <Link to="/allseries/live">
                        <h4 className={(this.state.selectedLive) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                        : "font-weight-semi-bold p-2  m-f-14"}>Live</h4>
                    </Link>
                    <Link to="/allseries/upcoming">
                        <h4 className={(this.state.selectedUpcoming) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                        : "font-weight-semi-bold p-2  m-f-14"}>Upcoming</h4>
                    </Link>
                    <Link to="/allseries/recent">
                        <h4 className={(this.state.selectedRecent) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                        : "font-weight-semi-bold p-2  m-f-14"}>Result</h4>
                    </Link>
                    
                </div>
            </div>


          <div className="card p-3 my-2">
            {
                this.getMarkup()
            } 
          </div>
          {
            ( (!this.state.pagesEndLive && this.state.allSeriesResp && this.state.allSeriesResp.live_series.length && this.state.selectedLive)
            || (!this.state.pagesEndRecent && this.state.allSeriesResp && this.state.allSeriesResp.recent_series.length && this.state.selectedRecent)
            || (!this.state.pagesEndUpcoming && this.state.allSeriesResp && this.state.allSeriesResp.upcoming_series.length && this.state.selectedUpcoming) )
            ?
            (
              <div>
                <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
              </div>
            )
            : <div></div>
          }
          </div>
        </section>


    );
  }
}

export const getDay = (date) => ( new Date(date).toDateString().split(' ')[0] )
export const getMonth = (date) => ( new Date(date).toDateString().split(' ')[1] )
export const getDate = (date) => ( new Date(date).toDateString().split(' ')[2] )
export const getYear = (date) => ( new Date(date).toDateString().split(' ')[3] )

export default AllSeries;
