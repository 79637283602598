import React, { Component } from 'react';
import { CollapsableScorecard, getScorecardTitle } from './HelperFunctions';
import axios from 'axios';
import { Globals } from './constants';

class MatchScorecard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchScorecardResp: {},
            isLoading: true,
            customCssTransition: 'h-transition',
            hasError: false
        };
        this.refresh = this.refresh.bind(this);
        this.getFromApi = this.getFromApi.bind(this);
    };
    refresh() {
        this.setState({isLoading: true, hasError: false, customCssTransition: 'h-transition'}, this.getFromApi);

    }
    async getFromApi() {
        const url = Globals.ApiUrl + "api/" + this.props.matchId;
        let apiResponse = {}, hasError = false;
        try {
            apiResponse = await axios.get(url);
            console.log("Match scorecard response", apiResponse);
            // if (response.status!==200) window.location.href = '/404notfound';
            
        }
        catch(err) {
            console.log("Error in match scorecard api fetching", err);
            // window.location.href = '/404notfound';
        };
        if (!apiResponse.data.hasOwnProperty('id')) hasError = true;
        this.setState({ matchScorecardResp: apiResponse.data, hasError: hasError, isLoading: false,
            customCssTransition: 'v-transition' }, () => console.log("Match scorecard set state", this.state));
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        this.getFromApi();
    }

  render() {
    return (
        <div className="custom-match-scorecard">
        
            {
                (this.state.isLoading) ?
                <div className="mt-2 main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
                </div> : <div></div>
            }
            
            <div className={this.state.customCssTransition}>

            {
                (this.state.hasError) ?
                <div className="mt-2 has-error card min-height-80vh p-3">
                <h5> Error or no result <button className="btn btn-default" onClick={this.refresh}>Try Again</button></h5>
                </div> : <div></div>
            }

            {
                (this.state.matchScorecardResp.hasOwnProperty('innings')) ? this.state.matchScorecardResp.innings.map( (obj, i) => 
                
                    <div key={"match-sc-"+i} className="card mt-2 p-3">
                        <CollapsableScorecard 
                            data={obj} 
                            inningsCount={this.state.matchScorecardResp.innings.length-1} 
                            thisIndex = {i}
                            showPlayerDetailText={true}
                            title={getScorecardTitle(obj, i, this.state.matchScorecardResp)} 
                            index={"col-sc-"+i}
                        >
                        </CollapsableScorecard>
                        {/* <CollapsableScorecardBowling data={obj} showPlayerDetailText={false} title="Fall of Wickets" index={"col-sc-b-"+i}>
                        </CollapsableScorecardBowling> */}
                    </div>
                
                ) : <div></div>
            }

            </div>

          
          {/* <div className="card mt-2 p-3">
            <CollapsableScorecard showPlayerDetailText={true} title="Australia 1st innings" index={3}></CollapsableScorecard>
            <CollapsableScorecardBowling showPlayerDetailText={false} title="Fall of wickets" index={4}></CollapsableScorecardBowling>
          </div> */}
          
          {/*<MatchDetail image={this.state.bgImage}></MatchDetail>*/}
        
        </div>
    );
  }
}



export default MatchScorecard;
