import React, { Component } from 'react';
import { SeriesNav } from './Series';
import axios from 'axios';
import { hyphen2space } from './HelperFunctions';
import {Globals} from './constants';

class SeriesPointsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesTitle: '',
            isLoading: true,
            pointsTable: []
        };
    };


    async componentDidMount() {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        let seriesTitle = this.props.match.params.title
        this.setState({seriesTitle: seriesTitle}); 

        try {
            const url = Globals.ApiUrl+"api/series_points_table/"+this.props.match.params.id;
            const response = await axios.get(url);
            if (response.status===200){
                this.setState({ pointsTable: response.data.points_table, isLoading: false }, () => console.log("points table set state", this.state));
                let elem = document.getElementsByClassName('none-message')
                if(elem.length>0){
                    elem[0].classList.remove('d-none');
                }
            }
            else
                window.location.href = '/404notfound';
        }
        catch (err) {
            console.log(err);
            window.location.href = '/404notfound';
        };
    }

  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page">
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }
          <div className="card p-3">
            <h4 class="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">{hyphen2space(this.state.seriesTitle)}</h4>
            <SeriesNav path={this.props.match}></SeriesNav>
          </div>
          <div className="card mt-2 p-3">
            {
                (this.state.pointsTable.length > 0) ? 
                (<PointsTable data={this.state.pointsTable}></PointsTable>):
                (<h5 className="mb-0 none-message d-none">Points table not available at the moment</h5>)
            }
          </div>
          
        </section>


    );
  }
}
export const PointsTable = (props) => (
    <table className="mb-3 points-table fs-1p2">
        <tbody>
            <PointsTableTitle></PointsTableTitle>
            {
                props.data.map( (obj, i) => 
                    <PointsTableRow key={"pt-"+i} data={obj} showPlayerDetailText={false}></PointsTableRow>
                 )
            }
            
        </tbody>
    </table>
);

const PointsTableTitle = () => (
    <tr>
        <td valign="middle" className="text-black w-25 font-weight-normal py-3 px-1 pl-3">Teams</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">Mat</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">Won</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">Lost</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">Ties</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">NR</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">Pts</td>
        <td valign="middle" className="text-black font-weight-normal py-3 px-1" align="center">NRR</td>
    </tr>
);

const PointsTableRow = (props) => (
    <tr>
        <td valign="middle" className="w-50 font-weight-normal px-1 py-1 pl-3 text-black">{props.data.team}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.match_played}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.match_won}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.match_lost}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.match_tied}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.match_no_result}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.points}</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">{props.data.net_run_rate}</td>
    </tr>
);
export default SeriesPointsTable;
