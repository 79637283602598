import React, { Component } from "react";
import { Dropdown, 
   DropdownItem, 
   DropdownToggle, 
   DropdownMenu, Spinner }     from 'reactstrap';

import { Globals } from './constants';
import axios from 'axios';
import Moment from 'moment';

import {DGlobals}  from './dConstants';

  const location = window.location.href;
  if(location.indexOf("/digital") > 0) {
    import('../src/css/digital.css');
  }
   
class Digital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlPath: "",
      telcos: [],
      selectedTelco   : 'Choose operator',
      inputName       : 'phone',
      inputValue      : '',
      inputName1      : '',
      inputValue1     : '',
      phone           : '',
      pin             : '',

      btnText   : 'CONTINUE',
      message             : '',
      serverResponse: null,
      pinSent : false,
      wait:false, // not implemented

      loginPhonePlaceholder:'',
      loginPinPlaceholder:'',
      loginEnterPin:'',
      loginInvalidPhone :'',
      regex      :'',
      sucessRedirectFromDigital:'',
      telcoDisclamerText : [],

      globalTelco :null,
    };

    this.apiPath             = Globals.NodeApi + "api/";
    this.handlePhoneChange   = this.handlePhoneChange.bind();
    this.enableDisableField  = this.enableDisableField.bind();
    this.login               = this.login.bind();
    this.verifyPin           = this.verifyPin.bind();

  }

  componentDidMount= async()=>{
        let telcoName = this.getTelcoString(this.props.location.pathname);

        await this.getDefaultTelco();
        await this.fillTelcoList(telcoName);
        await this.fillTelcoDisclaimerText(this.state.telcos);
        //this.renderTelcosList(this.state.telcos);
        
   }

  getDefaultTelco=async()=>{
     if(this.state.telcos.length >= 1){
           this.setState(()=>{return{selectedTelco : this.state.telcos[0].name}});
           this.setTelco_t(this.state.telcos[0])
      }
  }

  handlePhoneChange = (e) => {
    this.setState({ inputValue : e.target.value });
    // if(this.state.ph_number.length !== 12){
    //     this.setState({ message: '' });
    // }
}


  componentWillReceiveProps=async(nextProps)=>{
      let telcoName = this.getTelcoString(nextProps.location.pathname);

      await this.getDefaultTelco();
      await this.fillTelcoList(telcoName);
      await this.fillTelcoDisclaimerText(this.state.telcos);

    }


 toggle = () => {
   this.setState(prevState => ({
       dropdownOpen:  !prevState.dropdownOpen
   }));
}




setTelco_t = async(telco) => {

  var globalTelco = DGlobals.Telcos.filter(x=>x.telco===telco.name);
 
  await this.setState(()=>{ return {            globalTelco               :globalTelco[0], 
                                                selectedTelco             :telco.name,
                                                loginPhonePlaceholder     :globalTelco[0].loginPhonePlaceholder,
                                                loginPinPlaceholder       :globalTelco[0].loginPinPlaceholder,
                                                loginEnterPin             :globalTelco[0].loginEnterPin,
                                                loginInvalidPhone         :globalTelco[0].loginInvalidPhone,
                                                loginPinPlaceholder       :globalTelco[0].loginPinPlaceholder,
                                                regex                     :globalTelco[0].regex, 
                                                sucessRedirectFromDigital :globalTelco[0].sucessRedirectFromDigital, 
                                                isSelectedTelco: true 
                                  }});

  //console.log("Selected Telco is tariq ", this.state.globalTelco.telco);
}

renderTelcosList = (telcos) => {
   return (
       <Dropdown isOpen={this.state.dropdownOpen}
           toggle={this.toggle}>
          
           <DropdownToggle className="drop-button"
               style={{
                   textAlign: 'left',
                   backgroundColor: 'transparent',
                   border: 'none',
                   paddingLeft: 0,
                   paddingBottom: 1 + 'px',
                   borderRadius: 0,
                   color: '#555555'
               }} 
           >
               
               {/* {this.state.selectedTelco.name} */}
              {this.state.selectedTelco}
               
               <span style={{ float: 'right', fontSize: 20 + 'px', color: 'black' }}>
                   <i className="fa fa-sort"></i>
               </span>

           </DropdownToggle>

           <DropdownMenu style={{ width: 100 + '%', borderRadius: 0 }} >
               {telcos.map((telco, i) => (
                   <DropdownItem className="drop-options"
                       key={i} 
                       value={telco.name}
                       onClick={() => this.setTelco_t(telco)}
                   >
                       
                       
                           {telco.name}
                       

                   </DropdownItem>
               ))
               }
           </DropdownMenu>
       </Dropdown>
   )
}

getTelcoString=(urlPath)=>{

   console.log(urlPath);
   let str = '';
   for(let i=1 ; i < urlPath.length ; i++){
      if(urlPath[i] === '/'){
           // this.setState(()=>{return{drivedTelco:str}});
            console.log(str);
            return str;
      }else{
           // console.log(urlPath[i]);
            str =  str + urlPath[i];
      }
   }
 //  this.setState(()=>{return{drivedTelco:str}});
   return str;
    
}

fillTelcoList= async(telcoFromString)=>{

      if(telcoFromString==="mobilink"){
          this.setState(()=>{ return {telcos : [{ name: "mobilink" }] } });
          return; 
      } 

      if(telcoFromString==="ufone"){
          this.setState(()=>{ return {telcos : [{ name: "ufone" }] } });
          return; 
      } 

      if(telcoFromString==="telenor"){
          this.setState(()=>{ return {telcos : [{ name: "telenor" }] } });
          return; 
      } 

      if(telcoFromString==="zong"){
          this.setState(()=>{ return {telcos : [{ name: "zong" }] } });
          return; 
      } 

      if(telcoFromString === "zain" || telcoFromString === "mobily" || telcoFromString === "stc"){ 
          console.log("Condition is True");
          this.setState(()=>{ return {telcos : [{ name: "zain" }, { name: "mobily" }, { name: "stc" }] } });
          return;
      }

      if(telcoFromString==="viva_kw"){
          this.setState(()=>{ return {telcos : [{ name: "viva_kw" }] } });
          return; 
      } 

      if(telcoFromString==="zain_kw"){
        this.setState(()=>{ return {telcos : [{ name: "zain_kw" }] } });
        return; 
      } 

      if(telcoFromString==="ooredoo_kw"){
        this.setState(()=>{ return {telcos : [{ name: "ooredoo_kw" }] } });
        return; 
      } 

      if(telcoFromString==="bbh"){
        this.setState(()=>{ return {telcos : [{ name: "bbh" }] } });
        return; 
      } 

      if(telcoFromString==="zain_bh"){
        this.setState(()=>{ return {telcos : [{ name: "zain_bh" }] } });
        return; 
      } 

      if(telcoFromString==="ooredoo"){
        this.setState(()=>{ return {telcos : [{ name: "ooredoo" }] } });
        return; 
      } 

}


fillTelcoDisclaimerText=(telcos)=>{
    let disclaimerText = [];

    telcos.map((telco ,  i)=>{
         let  _telco=  DGlobals.Telcos.filter(x=>x.telco===telco.name);
         disclaimerText.push({ telco : _telco[0].telco, telcoDisclamerText : _telco[0].telcoDisclaimerText})
        //  console.log("telco for disclamer : ", disclaimerText);
    });
    this.setState(()=>{return{telcoDisclamerText :  disclaimerText}});
}


renderDisclaimer=()=>{
  return (
    <div>
      {this.state.telcoDisclamerText.map((disclaimerText, i) => (
        <p key={i} className="termsConditionsTelco">
          {/* {console.log(disclaimerText)} */}
          {disclaimerText["telcoDisclamerText"]} <br />
        </p>
      ))}
    </div>
  );
}

renderHeader=()=>{
  return( 
    <div>
          <div className="logo visible-xs">
               <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/cric-logo.png" alt="a" />
          </div>

          <div className="logo visible-lg">
               <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/cric-logo.png" alt="a" />
          </div>

          <div className="logo visible-md">
               <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/cric-logo.png" alt="a" />
          </div>
           
          <div className="header-img">
               {/* <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/live_2.png?v=14.7" alt="a"/> */}
               <img src="/images/live.png" alt="a"/>
          </div> 

    </div>

);
}

renderLogin=()=>{

  const { loginPhonePlaceholder, btnText, inputName, inputValue, message } = this.state;
  return(
    <div className='sub-form'> 
       <div className="phone-div">
                <label    id="label-input"  htmlFor="ph-number">
                    <span id="input-eng">Enter mobile number </span>
                    <span id="input-urdu1"> | موبائل نمبر درج کریں </span>
                </label>

                <div className="phone-field">
                        <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/ico-mobilepak.png" alt="" />
                        <input className="input-number" type="text" id={inputName} value={inputValue} placeholder={loginPhonePlaceholder}
                               onChange={this.handlePhoneChange}/>
                </div>
       </div>

       <div className="phone-div">
            <label htmlFor="carrier">
              Choose your carrier
              <span> | آپریٹر کا انتخاب کریں </span>
            </label>

            <div className="phone-field">
               <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/ico-numpak.png" alt=""/>
               {this.renderTelcosList(this.state.telcos)} 
            </div>
       </div>

       <div>
         <p id="msg-success">{message}  </p>
         <button className="continue" onClick={this.login}>
            {/* <span> <Spinner size="lg" color="warning" /> </span> */}
             {btnText}
        </button>
         {/* <p id="msg-success" style={{ display: "none" }}> Successfully Subscribed! </p>
         <p id="msg-error" style={{ display: "none" }}>   Something wente wrong!   </p> */}
       </div>

        <div>
          <p className="termsConditions">
            By subscribing to the service you are accepting all terms and
            conditions of the service.
          </p>
        </div>
  
        {this.renderDisclaimer()}   
     
  </div>

  );
}

renderTelcosForPin=()=>{
  return(
    <Dropdown isOpen={this.state.dropdownOpen}
    toggle={this.toggle}>
   
    <DropdownToggle className="drop-button"
        style={{
            textAlign: 'left',
            backgroundColor: 'transparent',
            border: 'none',
            paddingLeft: 0,
            paddingBottom: 1 + 'px',
            borderRadius: 0,
            color: '#555555'
        }} 
    >
        
        {/* {this.state.selectedTelco.name} */}
       {this.state.selectedTelco}
        
        <span style={{ float: 'right', fontSize: 20 + 'px', color: 'black' }}>
            <i className="fa fa-sort"></i>
        </span>

    </DropdownToggle>

    {/* <DropdownMenu style={{ width: 100 + '%', borderRadius: 0 }} >
        {telcos.map((telco, i) => (
            <DropdownItem className="drop-options"
                key={i} 
                value={telco.name}
                onClick={() => this.setTelco_t(telco)}
            >
                
                
                    {telco.name}
                

            </DropdownItem>
        ))
        }
    </DropdownMenu> */}
</Dropdown>
  )
}

renderPin=()=>{
  const { loginPinPlaceholder, btnText, inputName, inputValue, message } = this.state;
    return(
      <div className='sub-form'> 
    
         <div className="phone-div pin-msgs" style={{ backgroundColor: "transparent", color: "#fff" }} >
                 <label htmlFor="carrier">
                      Please enter the 6 or 8 digit code you receive via SMS.
                 </label>
  
                 <label>
                    آپکے موبائل نمبر پر ایس ایم ایس کے ذریعے پن نمبر بھیجا گیا ہے
                 </label>
         </div>


         <div className="phone-div">
                  <label    id="label-input"  htmlFor="ph-number">
                      <span id="input-eng">Enter PIN number </span>
                      <span id="input-urdu1"> | پن نمبر درج کریں </span>
                  </label>
  
                  <div className="phone-field">
                          <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/ico-mobilepak.png" alt="" />
                          <input className="input-number" type="text" id={inputName} value={inputValue} placeholder={loginPinPlaceholder}
                                 onChange={this.handlePhoneChange}/>
                  </div>
         </div>
  
         <div className="phone-div">
              <label htmlFor="carrier">
                Selected carrier
                <span> | منتخب آپریٹر   </span>
              </label>
  
              <div className="phone-field">
                 <img src="http://cric.sa.zain.com/userapi/assets/jan18/promo/slices/ico-numpak.png" alt=""/>
                 
                 {this.renderTelcosForPin()} 

              </div>
         </div>
  
        
  
         <div>
           <p id="msg-success">{message} </p>
           <button className="continue" onClick={this.verifyPin}>{btnText}</button>
           {/* <p id="msg-success" style={{ display: "none" }}> Successfully Subscribed! </p>
           <p id="msg-error" style={{ display: "none" }}>   Something wente wrong!   </p> */}
         </div>
  
          <div>
            <p className="termsConditions">
              By subscribing to the service you are accepting all terms and
              conditions of the service.
            </p>
          </div>

          {this.renderDisclaimer()}    
    </div>
  
    );

}


getNumberStatus=async()=>{

  const phone = this.state.inputValue;
  const url   = this.apiPath + "login_or_sendpin?telco=" + this.state.globalTelco.telco + "&phone=" + phone + "&sub_type=" + this.state.globalTelco.SubscriptionPackage
                + "&service_id="      + this.state.globalTelco.serviceId ;

  if(this.state.globalTelco.serviceId === '0'){
       console.log("Service Id = ", this.state.globalTelco.serviceId);
  }else{
       console.log("Service Id = ", this.state.globalTelco.serviceId);
  }
  
  
  let serverResponse = null;

  console.log(url);

  try {

      
        serverResponse = await axios.get(url);
        this.setState(() => {return {serverResponse :  serverResponse.data, error: false , phone: phone ,message:''}});
        console.log("Server response: ", this.state.serverResponse);

  }catch(err){
      
        console.log("Error logging in", err);
        this.setState(()=>{return{Error: true, serverResponse:null}});
  }

}

getPinStatus=async()=>{
  const {phone, selectedTelco} =  this.state; 
  
  const pin = this.state.inputValue;
  const url = this.apiPath   
              + "confirmpin?telco=" + selectedTelco
              + "&phone="           + phone 
              + "&pin="             + pin 
              + "&sub_type="        + this.state.SubscriptionPackage
              + "&service_id="      + this.state.globalTelco.serviceId
  
  let serverResponse = null;

  console.log(url);

    try {
      
        serverResponse   = await axios.get(url);
        this.setState(() => {
                              return { 
                                        serverResponse :  serverResponse.data, 
                                        error: false, 
                                        pin: pin,  
                                        message:'',
                                        urlPath : url,
                              }
        });
        // console.log("try Server PIN  response: ", this.state.serverResponse);

    } catch (err) { 
        console.log("Error confirming pin", err);
        this.setState(() => {return {serverResponse : null , error: true}});
    };

}


validatePhone(phoneNumber, regexString) {
  if (!phoneNumber) {
      return false;
  } else if (!regexString.test(phoneNumber)) {
      return false;
  } else {
      return true;
  }
}

login = async () => {
   const {inputValue, regex ,loginInvalidPhone, globalTelco, sucessRedirectFromDigital} =  this.state;
   const btnText = "CONTINUE";
      

   if(!globalTelco){
        this.setState(()=>{return{message : " Please select telco", btnText:btnText}});
        return;
   }  


   if(inputValue.trim().length === 0){
       this.setState(()=>{return{message : loginInvalidPhone, btnText:btnText}});
       return;
   }  

   let result =  this.validatePhone(inputValue, regex);
   if(!result){
       console.log("regex result is : ",result , loginInvalidPhone);
       this.setState(()=>{return{message : loginInvalidPhone, btnText:btnText}});
       return;
   }
  
   this.setState(()=>{return{btnText:"Please wait..."}});

   await this.getNumberStatus(); // Server Call
 

   if(this.state.error){
        this.setState(()=>{return{message : "Please check your network status...", btnText:btnText}});
        return; 
    }

   if(!this.state.serverResponse){
      this.setState(()=>{return{message : "Some thing went wrong...", btnText:btnText}});
      return; 
   }   

   //console.log( "redirect from login Tariq: ",this.state.serverResponse);

   if(this.state.serverResponse && this.state.serverResponse.generic_response.subscribed){
       window.location.href = sucessRedirectFromDigital + inputValue;
       return;                 
   }

     
   if(this.state.serverResponse && !this.state.serverResponse.generic_response.subscribed){
        console.log("Server Response after getNumberStatus : ", this.state.serverResponse.generic_response.subscribed);
        this.setState(()=>{
                return{  inputValue: '',
                         inputName: 'pin', 
                         pinSent: true, 
                         message: '', 
                         btnText:btnText, 
                         btnDisbaled: false
        } 
    });

    return;

   }


}


logConversion= async () =>{
  
  const {phone, serverResponse, urlPath, globalTelco} =  this.state
  
  let search     = new URLSearchParams(this.props.location.search);
  let partner_id = search.get("partner_id");
  
  console.log("Global Telco......" , partner_id);
  let utcDate =  new Date();
  let m =  Moment.utc(utcDate);
      m =  m.format('YYYY-MM-DD HH:mm:ss');
  
  const dataForPost = {
        product     : 'Cricwick-Services-General',
        product_id  : '3',
        msisdn      :  phone,
        service_id  :  globalTelco.serviceId,
        partner_id  :  partner_id,
        request     :  urlPath,                                     // => $actual_link, //url of rverResponse   = await axios.get(url);
        response    :  JSON.stringify(serverResponse),                              //complete json serverResponse
        created_at  :  m,                                             //"  " " => gmdate('Y-m-d h:i:s'),
        visitor_id  :  "",
        //is_test     : '0'                                       // It will be empty
  }    

        console.log(dataForPost);
 
        //PHP Code Thair Bhi.
        //   $data = array(
        //     "product" => "Iqra-Services-General", product = Cricwick-Services-General
        //     "product_id" => "4",                  product_id = 3
        //     "msisdn" => $phone_number,
        //     "service_id" => $service_id,
        //     "partner_id" => $partner_id,
        //     "request" => $actual_link, //url of rverResponse   = await axios.get(url);
        //     "response" => json_encode($response),//complete json serverResponse
        //     "created_at" => gmdate('Y-m-d h:i:s'),
        //     "visitor_id" => ""  // It will be empty
        // );

        //                     $url = "185.102.219.8:8078/api/log_conversion";
        //                     $this->curl_call_post($url,$data);
         
        //http://adsksa.mobi/zain/digital?parnter_id=1

     const url   = "http://185.102.219.8:8078/api/log_conversion";
  
     try {
  
           let rsp = await axios.post(url, dataForPost )   //dataForPost);
           console.log(rsp);
  
     }catch(err){
        
          console.log("Error Posting Data : ", err);
          //this.setState(()=>{return{Error: true}});
     }
  

}


verifyPin = async () => {

    const {inputValue, phone, sucessRedirectFromDigital} =  this.state;

    this.setState(()=>{return{btnText:"Please wait..."}});

    if(inputValue.trim().length === 0){
        this.setState(()=>{return{message : "Invaid pin entered", btnText:"CONTINUE"}});
        return;
    }  

    
    await this.getPinStatus();
   // this.logConversion();

    console.log("Redirect Url : ", sucessRedirectFromDigital + phone )
  
    if(this.state.error){
      this.setState(()=>{return{message : "Please check your network status...", btnText:"CONTINUE"}});
      return; 
    }

    if(!this.state.serverResponse){
      this.setState(()=>{return{message : "Some thing went wrong...", btnText:"CONTINUE"}});
      return; 
    } 

    
    if(this.state.serverResponse && !this.state.serverResponse.generic_response.subscribed){
      this.setState(()=>{
        return{ btnText: "CONTINUE", message:this.state.serverResponse.generic_response.message }      
      });
      
      return;    
    }
    
    if (!this.state.serverResponse.status) {
      this.setState(()=>{
        return{ btnText: "CONTINUE", message: this.state.serverResponse.generic_response.message,  btnDisbaled: false }      
      });
      
      return;
    }     
    
     console.log("Pin Response : ", this.state.serverResponse); 
     const generic_response = this.state.serverResponse.generic_response;
      

     if (generic_response.subscribed) {
          // this.setState({ message: '' });
          // generic_response.phone = phone;
          // generic_response.telco = this.state.telco
          // localStorage.setItem('user_logged_in', true);
          // localStorage.setItem('user', JSON.stringify(generic_response));
          // // loginUser(generic_response);

          this.logConversion();
         // console.log( "redirect after pin tariq : ",sucessRedirectFromDigital + inputValue)
          window.location.href = sucessRedirectFromDigital + phone;
          return;   

         // window.location.reload();
    }  

      return;

}

enableDisableField=()=>{
  const {globalTelco} =  this.state;
 
  // console.log("Tariq Global telco",globalTelco);

  if(!globalTelco){
       return 'true';
  }else{
       return 'false';
  }

}


render() {
    return (
          <div className="container">
            {this.renderHeader()} 
            {this.state.pinSent ? this.renderPin() :  this.renderLogin()  }
          </div>  
    );
  }
}

export default Digital;
