import React, { Component } from 'react';
import axios from 'axios';
import {loginUser} from './HelperFunctions';
import {Globals} from './constants';
const queryString = require('query-string');




class Authentication extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };

        this.apiPath = Globals.NodeApi+"api/";
    };

    async componentWillMount(){
        let cgObject = JSON.parse(localStorage.getItem('cgObject')),
            redirectTo = '/login',
            logRequest = {cgObject: cgObject, params: this.props.location.search};
            // axios.get(this.apiPath+"log?function=auth_callback&req="+JSON.stringify(logRequest));
            console.log('authentication waiting buffer');
        setTimeout( async () => {
            console.log('authentication entring timeout');
            if(cgObject){
                let params = queryString.parse(this.props.location.search)
                let phone = '';
                let serverResponse = {};
    
                if(cgObject.telco === 'ooredoo_kw' || cgObject.telco === 'bbh' || cgObject.telco === 'viva_kw'){
                    console.log('authentication valid telco');
                    // const parsed = queryString.parse(params);
                    if(params.CGMSISDN){
                        console.log('authentication msisdn found', params);
                        phone = params.CGMSISDN;

                        if(params.CGStatus == 0 || params.CGStatus == 5){
                            console.log("authentication status param found", params.CGStatus);
                            let generic_response = {};
                            generic_response.call_type = "CGStatus"
                            generic_response.subscribed = 1
                            generic_response.phone = phone;
                            generic_response.telco = cgObject.telco;
                            localStorage.setItem('user_logged_in', true);
                            localStorage.setItem('user', JSON.stringify(generic_response));
                            loginUser(generic_response);

                            // window.location.href = cgObject.referer;
                            // return false;//remove this
                            redirectTo = cgObject.referer;
                        }else{

                            try {
                                const url = this.apiPath + "appstart?telco="+cgObject.telco +"&phone="+phone;
                                serverResponse = await axios.get(url, {});
                                serverResponse = serverResponse.data;
                            } catch (err) { 
                                console.log("authentication Error logging in", err);
                            };
        
                            if (serverResponse) {
                                console.log("authentication server responded");
                                // axios.get(this.apiPath+"log?function=auth_callback_appstart&req="+JSON.stringify(logRequest)+"&res="+JSON.stringify(serverResponse));
                                let generic_response = {}
                                if(Object.keys(serverResponse).length === 0 && serverResponse.constructor === Object){
                                }
                                else if (!serverResponse.status) {
                                } else {
                                    generic_response =    serverResponse.generic_response;
                                };
                                if (generic_response.subscribed) {
                                    generic_response.phone = phone;
                                    generic_response.telco = cgObject.telco;
                                    localStorage.setItem('user_logged_in', true);
                                    localStorage.setItem('user', JSON.stringify(generic_response));
                                    loginUser(generic_response);
        
                                    // window.location.href = cgObject.referer;
                                    // return false;//remove this
                                    redirectTo = cgObject.referer;
                                }
                            }else{
                                console.log('authentication no server response');
                            }

                        }

                        
                        
                    }else{
                        console.log('authentication', 'no msisdn')
                    }
                }else{
                    console.log('authentication invalid telco');
                }
    
            }else{
                console.log('authentication cgobject not found');
            }
    
    
            localStorage.removeItem('cgObject');
            window.location.href=redirectTo;
            console.log("authentication redirect to ",redirectTo);
        }, 5000);
        
    }

  render() {
    return(
      <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
        </div>
      </section>
    )
  }

}


export default Authentication;
