import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { StoriesCard } from './Stories';
import { VideoCardDescription } from './HomeSeriesCard';
import ImageLoader from 'react-load-image';


import { Globals } from './constants';
import {Helmet} from "react-helmet";


class StoriesDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
            customCssTransition: 'h-transition',
            isLoading: true,
            apiPath: Globals.ApiUrl + "api/",
            apiResp: [],
            articles: [],
            label: '',
            page: 1,
            per_page: 6,
            type: '',
            id: '',
            articlesClass: '',
            newsClass: '',
        };
        this.getDataFromApi = this.getDataFromApi.bind(this);
    };

    async componentDidMount(){
        
        this.getDataFromApi(this.props.match.match.params.id);
    }
    
    async getDataFromApi(contentId) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        let type = this.props.type,
        id = contentId,
        url = this.state.apiPath+type+"/"+id,
        articlesUrl = this.state.apiPath+type+"?page=1&page_size=4",
        articles = [],
        apiResp,
        label,
        resp;

        if(type == 'articles'){
            label="Article";
            this.setState({articlesClass: 'border-bottom border-danger', newsClass: '', label:label});
        }else{
            label="News";
            this.setState({newsClass: 'border-bottom border-danger', articlesClass:'', label:label});
        }

        try{resp = await axios.get(url)}catch(error){console.log('error fetching '+type);window.location.href = '/404notfound';}
        if(resp.status == 200){
            this.setState({apiResp: resp.data, type: type, id: id, customCssTransition: 'v-transition', isLoading: false}, () => console.log("Stories set state", this.state));
            console.log('fetched '+type, this.state.apiResp);
        }else{
            console.log('error fetching '+type+'!');
            window.location.href = '/404notfound';
        }


        try{
            apiResp = await axios.get(articlesUrl);
        }
        catch(err){
            console.log('Error fetching articles');
            window.location.href = '/404notfound';
        }

        if(apiResp.status == 200){
            articles = apiResp.data.data;
            console.log(articles);
            this.setState({articles: articles},
                () => console.log("stories articles state completed", this.state));
        }else{
            console.log('Error fetching articles');
            window.location.href = '/404notfound';
        }
    }
    
    componentWillReceiveProps(nextProps) {
        console.log("Next props", nextProps);
        if (this.props.match.match.params.id!==nextProps.match.match.params.id) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            this.getDataFromApi(nextProps.match.match.params.id);
        }
    }


    getArticles(){
        return this.state.articles.map( (article, i) => {
            return (
                <Link key={'articles-'+i} className="" to={"/stories/"+this.state.type+"/"+article.id}>
                    <StoriesCard data={article} image={this.state.newsImage} type={this.state.label}></StoriesCard>
                </Link>
            )
        } );
    }
  render() {
    return (

        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
            <Helmet>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={Globals.og.title} />
                <meta property="og:description" content={this.state.apiResp.title} />
                <meta property="og:image" content={this.state.apiResp.image} />
            </Helmet>


            {
                (this.state.isLoading) ?
                <div className="main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
                </div> : <div></div>
            }
          
          <div className={this.state.customCssTransition}>

          <div className="card p-3">
            <div className="d-flex overflow-x-auto">
                
                <h4 className={this.state.newsClass+" font-weight-semi-bold p-2 m-f-14"}>
                    <Link className="alert-heading" to="/stories/news">Latest News</Link>
                </h4>

                <h4 className={this.state.articlesClass+" font-weight-semi-bold p-2 m-f-14 ml-4 "}>
                    <Link className="alert-heading" to="/stories/articles">Articles</Link>
                </h4>

                {/* <h4 className="font-weight-semi-bold p-2 ml-4 text-secondary">Articles</h4> */}
            </div>
          </div>

          <div className="card mt-2 p-3">

            <div className="article border-bottom pb-4 mb-3">
                <div className="d-flex">
                    <div className="ml-auto navbar-expand">
                        <ul class="navbar-nav ">
                        <li className="nav-item">
                            <a data-original-title="Facebook" className="nav-link text-secondary h4" target="_blank" href={"http://www.facebook.com/sharer/sharer.php?u="+window.location.href}>
                                <i class="fa fa-facebook"></i></a></li>
                        <li className="nav-item">
                            <a data-original-title="twitter" className="nav-link text-secondary h4" href={"http://www.twitter.com/share?text="+this.state.apiResp.title+"&amp;url="+window.location.href+"/"}>
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a data-original-title="google-plus" className="nav-link text-secondary h4" href={"http://plus.google.com/share?url="+window.location.href+"/"}>
                                <i class="fa fa-google-plus google"></i>
                            </a>
                        </li>
                        </ul>
                    </div>
                </div>
                <h2 className="article-title text-black m-f-14">
                    {this.state.apiResp.title}
                </h2>
                <div className="article-image">
                    {/* <img className="w-100" src={this.state.apiResp.image} /> */}
                    <ImageLoader src={this.state.apiResp.image} >
                        <img className="w-100" />
                        <div >Error!</div>
                        <Preloader />
                    </ImageLoader>
                </div>
                <div className="article-credits my-3 fs-1p2 pb-2 border-bottom text-medium-grey d-flex m-f-12">
                    <span className="">{this.state.apiResp.by}</span>
                    <span className="ml-auto">{getDate(this.state.apiResp)}</span>
                </div>
                <div className="text-black fs-1p2 m-f-12">
                    <div dangerouslySetInnerHTML={{__html: this.state.apiResp.body}}></div>
                </div>
            </div>

            <div className="">
    			<h4 className="text-uppercase text-black font-weight-normal mb-3">Related Stories</h4>
                {
                    (this.state.articles && this.state.articles.length > 0)?
                    ( this.getArticles() ):
                    // ( <div></div> ):
                    ( <div></div> )
                }
            </div>

          </div>

          </div>
          
        </section>


    );
  }
}
function Preloader(props) {
    return <img className="w-100" src={Globals.placeholder16x9} />;
}

const getDate = (props) => {
    let datestr,
    date = '';
    if (typeof props !== 'undefined' && props.created_at) {
        datestr = props.created_at;
        date = new Date(datestr).toDateString().split(' ');
        date = date[2]+" "+date[1]+", "+date[3];
    }
    
    return date;
}

export default StoriesDetail;
