import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomeSeriesCard from './HomeSeriesCard';
import { StoriesCard } from './Stories';
import { FeaturedSeriesCard } from './Leftsection';
import { Globals } from './constants';
import axios from 'axios';
import { Link } from "react-router-dom";

import { VideosListFourByThree, InHouseContent, Rankings, FullWidthCard, MobileFeaturedSeries } from './HelperFunctions';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesApiPath: Globals.ApiUrl+'api/v2/series/featured',
      seriesApiResp: [],
      homeApiResp: [],
      currentRankings: 'odi',
      customCssTransition: 'h-transition',
      isLoading: true,
      page: 1,
      pagesEnd: false,
      hasError: false
    };
    
    this.isMobile = window.innerWidth < 992;
    this.rankingsChange = this.rankingsChange.bind(this);
    this.getMarkup = this.getMarkup.bind(this);
    this.getFromApi = this.getFromApi.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.refresh = this.refresh.bind(this);

  };

  async componentDidMount () {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    // let url = this.state.seriesApiPath,
    // resp;

    // try{    
    //     resp = await axios.get(url)
    // }catch(error){
    //     console.log('error fetching series');
    // }

    // if(resp.status === 200){
    //     this.setState({seriesApiResp: resp.data.featured_series})
    //     console.log('series fetched', this.state.seriesApiResp);
    // }else{
    //     console.log('error fetching series!');
    // };

    this.getFromApi();

  }

  refresh() {
    this.setState({isLoading: true, hasError: false, customCssTransition: 'h-transition'}, this.getFromApi);
  }

  async getFromApi() {
    let homeApiResp,
    homeApiUrl = Globals.ApiUrl + "api/v3/view_lists/get_by_name?view=home&web_user=1&page=1&telco="+this.props.telco;

    try {
      homeApiResp = await axios.get(homeApiUrl);

      console.log("Home Api Response", homeApiResp);
    }
    catch(err) {
      console.log("Error fetching Home Api", err);
      // this.setState({hasError: true, customCssTransition: 'v-transition', isLoading: false})

    };

    ( homeApiResp && homeApiResp.status===200 && Array.isArray(homeApiResp.data) ) ? 
    ( this.setState({homeApiResp: homeApiResp.data, customCssTransition: 'v-transition', isLoading: false}) ) //hide loader after set state
    : ( this.setState({hasError: true, customCssTransition: 'v-transition', isLoading: false}) );
  }

  rankingsChange(e) {

    if (e.target.id!==this.state.currentRankings) this.setState({ currentRankings: e.target.id });
    
  }

  async nextPage(e) {

    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName('loading-more-results');
      if(elem.length)
        elem[0].classList.remove('d-none');
      
      const page = this.state.page + 1;
      const url = Globals.ApiUrl + "api/v3/view_lists/get_by_name?view=home&web_user=1&page="+page.toString()+"&telco="+this.props.telco;
      let resp;
      try {
        resp = await axios.get(url);
        console.log("Next page", resp);
      }
      catch(err) {
        console.log("error fetching next page");
      };

      if (resp.status === 200 && resp.data.length > 0) {
        if(elem.length)
          elem[0].classList.add('d-none');

        const homeApiResp = this.state.homeApiResp.concat(resp.data);

        this.setState({page: page, homeApiResp: homeApiResp}, () => console.log("Next page state set", this.state));

      } else {
        if(elem.length)
          elem[0].classList.add('d-none');

        if (resp.data.length===0) this.setState({pagesEnd: true});

        console.log("Api Error or no results");
      };

    }

  }

  getMarkup() {
    
    return this.state.homeApiResp.map( (categoryList, i) => {


      switch(categoryList.type) {

        case 'series':
          return <HomeSeriesCard key={'hms-'+i} image={this.bgImage} islive={true} data={categoryList}></HomeSeriesCard>
        case 'in_house_content':
          return <InHouseContent key={'inhouse-'+i} data={categoryList}></InHouseContent>
        case 'ranking':
          return <Rankings key={'rankings-'+i} data={categoryList}
           currentRankings={this.state.currentRankings} rankingsChange={this.rankingsChange}></Rankings>
        case 'generic-home':
          return <div key={'generic-section-'+i} className="card mb-2 p-3">
          <FullWidthCard type={categoryList.type} subType={categoryList.sub_type} data={categoryList.data[0]}></FullWidthCard></div>
        case 'video':
          return <VideosListFourByThree key={'vids-'+i} data={categoryList}></VideosListFourByThree>
        default:
          console.log("Other type occured in home lists", categoryList)

      }
    
    });
  
  }

  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page  align-self-start"> 
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }
        
        <div className={this.state.customCssTransition}>

          {
            (this.state.hasError) ?
            <div className="has-error card min-height-80vh p-3">
              <h5> Error or no result <button className="btn btn-default" onClick={this.refresh}>Try Again</button></h5>
            </div> : this.getMarkup()
          }

          {
            (!this.state.pagesEnd && this.state.homeApiResp.length) ?
            (
              <div>
                <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" height="30" alt="" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
              </div>
            )
            : <div></div>
          }

        </div>

        </section>
    );
  }
  
}



export default Home;