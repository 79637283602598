import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from "react-router-dom";
import { Globals } from './constants';
import BackgroundImage from 'react-background-image-loader';


class Rightsection extends Component {
  constructor(props) {
    super(props);
        this.state = {
            videosPath: Globals.ApiUrl+'api/v1/user_api/home_videos?telco='+this.props.telco,
            videosApiResp: []
        }
        this.getVideos = this.getVideos.bind(this);
    }

    async componentWillMount(){
        let url= this.state.videosPath,
        resp;

        try{ resp = await Axios.get(url)
            if(resp.status == 200){
                this.setState({videosApiResp: resp.data.timeline_videos});
                // console.log('fetched latest videos', this.state.videosApiResp)
            }else{
                // console.log('error fetching latest videos!')
            }
        }catch(error){ console.log('error fetching latest videos'); }


    }

    getVideos(){
        return this.state.videosApiResp.map( (video, i) => {
            return(
              <VideoCardSm key={'vcs-'+i} image={this.state.videosImage} data={video}></VideoCardSm>
            )
        })
    }

  render() {
    return (
      <div id="right-section" className="d-none d-lg-block jc-inner-page pl-4">

        <div className="card mb-3">
            <a href="javascript:;" className="app-download" onClick={this.props.downloadApp}>
                <img className="w-100" src={Globals.imgRightAppDownload} alt="" />
            </a>
        </div>
        {/* <!--card--> */}

        <div className="card p-3">
          <div id="tweets-conatiner-right" >
              <div className="tweets-header border-bottom pb-1 mb-3">
                  <div className="align-items-end d-flex">
                      <div className="">
                          <h3 className="tweets-heading m-0 text-black">Tweets</h3>
                      </div>
                      <div className="ml-auto text-right">
                          <p className="tweets-by text-nowrap-no-lh text-truncate m-0">
                              by
                              <a href="https://twitter.com/TheRealPCB" target="_blank" className="text-black">@TheRealPCB</a>
                          </p>
                      </div>
                  </div>
              </div>
              {/* <!-- tweets-header --> */}
              <a
                  className="twitter-timeline"
                  href="https://twitter.com/TheRealPCB"
                  data-tweet-limit="3"
                  data-chrome="nofooter noborders transparent noheader"
              >
              </a>
              {/* <div className="tweet border-top mt-2">
                  <div className="d-flex tweet-header py-3">
                      <div className="bg-medium-grey rounded-circle tweet-dp cover mr-3" style={this.state.bgImage}></div>
                      <div className="tweet-name align-self-center"><h5>Taylor Swift</h5></div>
                      <div className="tweet-birdie text-right ml-auto text-light-grey">
                          <i className="fa fa-fw" aria-hidden="true" title=""></i>
                      </div>
                  </div>
              </div>  */}
          </div>
          {/* <!--tweets-container-right--> */}
        </div>
        {/* <!--card--> */}
        {/*<div className="card mt-2 p-3">
          <h4 className="font-weight-semi-bold border-bottom pb-2">Match Stats</h4>
          <SeriesStatsTable></SeriesStatsTable>
        </div>*/}
        <div className="card mt-2 p-3">
          <h4 className="font-weight-semi-bold border-bottom pb-2 text-uppercase">Video Highlights</h4>
          {this.getVideos()}
        </div>


      </div>


    );
  }
}
const VideoCardDescription = (props) => (
    <div className="custom-clamp-text">
     <p className="text-secondary mb-0 font-weight-normal text-nowrap mb-1">{props.data.match_obj.title}</p>
     <h5 className="text-black mb-0 font-weight-normal flex-fill">{props.data.title}</h5>
    </div>
);

const VideoCardSm = (props) => (
    <div className="mb-2">
        <Link to={"/video/"+props.data.id} className="d-flex ">
            <div className="w-40 position-relative">
                {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: 'url('+props.data.thumb+')'}}></div> */}
                <BackgroundImage className="sixteen-by-nine bg-center-cover" src={props.data.thumb} placeholder={Globals.placeholderPlain4x3}>
                <div className="fa fa-play-circle play-btn-lg play-btn-xs"></div>
                </BackgroundImage>
            </div>
            <div className="pl-2 video-card-right-section">
                <VideoCardDescription data={props.data}></VideoCardDescription>
            </div>
        </Link>
    </div>
);
const SeriesStatsTable = (props) => (
    <table className="mb-3">
        <tbody>
            <SeriesStatsTitle></SeriesStatsTitle>
            <SeriesStatsRow></SeriesStatsRow>
            <SeriesStatsRow></SeriesStatsRow>
        </tbody>
    </table>
);

const SeriesStatsTitle = () => (
    <tr>
        <td valign="middle" className="text-uppercase w-50 font-weight-semi-bold py-2 px-1">Most Runs</td>
        <td valign="middle" className="text-secondary font-weight-semi-bold py-2 px-1" align="center">B</td>
        <td valign="middle" className="text-secondary font-weight-semi-bold py-2 px-1" align="center">4s</td>
        <td valign="middle" className="text-secondary font-weight-semi-bold py-2 px-1" align="center">6s</td>
        <td valign="middle" className="text-secondary font-weight-semi-bold py-2 px-1" align="center">SR</td>
    </tr>
);


const SeriesStatsRow = (props) => (
    <tr>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="left">Babar Azam</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">140</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">5</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">5</td>
        <td valign="middle" className="font-weight-normal px-1 py-1" align="center">70.99</td>
    </tr>
);

export default Rightsection;
