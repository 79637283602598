import React, { Component } from 'react';

class NotFound404 extends Component {
  render() {
    return (
        <section id="main-section" className="px-lg-0 custom-match-scorecard jc-inner-page">
          <h4 className="m-5">404 Not Found</h4>
        </section>

    );
  }
}

export default NotFound404;
