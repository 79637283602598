import React, { Component } from 'react';
//import { Link }             from "react-router-dom";
import axios                from 'axios';
//import {Redirect}           from 'react-router-dom';
import {  loginUser,
          tempUser,
          getEnterPinText}  from './HelperFunctions';

import { Globals }          from './constants';
import LeftSeriesList       from './components/leftSeriesList';
import LeftUpdatesList      from './components/leftUpdatesList';


class Leftsection extends Component {
    constructor(props){
        super(props);
        this.state = {
            seriesApiPath: Globals.ApiUrl+'api/v2/series/featured',
            seriesApiResp: [],
            updateApiPath: Globals.ApiUrl+'api/news?page=1&per_page=6',
            updateApiResp: [],
            error :null, //by Tariq
            loading:true,//by Tariq
            counter:0,   //by Tariq
            phone: '',
            pin: '',
            inputName: 'phone',
            inputValue: '',
            phonePlaceholder: Globals.login.phonePlaceholder,
            pinSent: false,
            pinPlaceholder: Globals.login.pinPlaceholder,
            task: 'phone',
            user: [],
            redirect: false,
            btnTxt: 'Continue',
            btnDisbaled: false,
            // apiPath: 'https://mobisub.cricwick.mobi/main/',
            message: '',
            heading: Globals.login.enterNumber,
            enterPin: Globals.login.enterPin,
            // heading: 'Please Enter Your Jazz Number. Prepaid users will be charged 2.38 per day.',
            strings: {
                somethingWentWrong: 'Something went wrong. Please try again later!',
                invalidPin: 'Invalid Pincode!',
                invalidPhone: Globals.login.invalidPhone
            }
        };

        this.handleKeypress = this.handleKeypress.bind(this);
        this.login = this.login.bind(this);
        this.loginValuesChange = this.loginValuesChange.bind(this);
        // this.getSeriesList = this.getSeriesList.bind(this);
        this.apiPath = Globals.NodeApi+"api/";
        // this.getLatestUpdates = this.getLatestUpdates.bind(this);
    };

    async componentDidMount() {
        //    console.log("Inside component did mount...");
        //    await this.getSeriesRecords();

        //    console.log("error CDM : ", this.state.error);
        //    if(this.state.error){
        //       this.getSeriesRecords();
        //       for(let i=0; i <  1000; i++ ){
        //            setTimeout(()=>{
        //                 console.log("Getting Connection......",i);
        //                 this.getSeriesRecords();
        //             }, 5000);

        //             console.log("Error Stat: ",this.state.error);
        //             if(!this.state.error){
        //                 return;
        //             }
        //       }
        //    }
    }

  //========================New Method By Tariq =================================
    // getSeriesRecords = async () => {
    //     let url = this.state.seriesApiPath,
    //         resp;
    //     let err = null;

    //     try {
    //         resp = await axios.get(url)
    //         // if (resp.status === 200) {
    //         await this.setState(() => { return { seriesApiResp: resp.data.featured_series, counter:0, error : null } })
    //         console.log('series fetched', this.state.seriesApiResp);
    //         // } else {
    //         //     console.log('error fetching series!');
    //         // } //Commented by Tariq

    //     } catch (error) {
    //         console.log('error fetching series - 1');
    //         await this.setState(() => { return { error : error } })
    //         err = error;
    //     }

    //     let updatesUrl = this.state.updateApiPath,
    //         updatesResp;

    //         console.log("URL Updates : ",this.state.updateApiPath, updatesResp);

    //     if (!err) {
    //         try {
    //             updatesResp = await axios.get(updatesUrl)
    //         } catch (error) {
    //             console.log('error fetching latest updates -2 ');
    //         }

    //         // console.log("Tariq", updatesResp); // Self Made and Commented by Tariq

    //         if (updatesResp.status === 200) {
    //             await this.setState({ updateApiResp: updatesResp.data.data, error: null, loading: false })
    //             // console.log('latest updates fetched', this.state.updateApiResp);
    //         } else {
    //             console.log('error fetching latest updates! -3 ');
    //         }
    //     }
    //     else {
    //         this.setState(() => { return { error: err } })
    //     }
    // }
  //==================================End========================================

    handleKeypress = function(e) {
        if(e.key === 'Enter'){
            this.login();
        }
    }

     login=async()=> {
        const phone = this.state.phone;
        let serverResponse = {};
        this.setState({ btnTxt: 'Please Wait ...', btnDisbaled: true });
        if (!this.state.pinSent) { //appstart or send pin
            try {
                const url = this.apiPath + "login_or_sendpin?telco="+this.props.telco+"&phone="+phone;
                serverResponse = await axios.get(url, {
                    // withCredentials: true,
                    // mode: 'cors',
                    // headers: {
                    //     'Access-Control-Allow-Credentials': true
                    // }
                });
                serverResponse = serverResponse.data;
            } catch (err) {
                //console.log("Error logging in", err);
            };
            // console.log("login response", serverResponse)
            if (serverResponse) {
                let generic_response = [] //{} //By Tariq
                if( (Object.keys(serverResponse).length === 0 && serverResponse.constructor === Object) || (!serverResponse.remote_response) ){
                    //empty response, means no response from server
                    generic_response = tempUser(phone);
                }
                else if (!serverResponse.status) {
                    //server responded but ERROR !
                    this.setState({ message: serverResponse.generic_response.message, btnTxt: 'Continue', btnDisbaled: false });
                } else {
                    generic_response =    serverResponse.generic_response;
                };
                if (generic_response.subscribed) {
                    this.setState({ message: '' });
                    // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
                    generic_response.phone = phone;
                    localStorage.setItem('user_logged_in', true);
                    localStorage.setItem('user', JSON.stringify(generic_response));
                    loginUser(generic_response);
                    window.location.reload();
                }
            }

            if (serverResponse.status && !serverResponse.generic_response.subscribed) { //pin sent
                this.setState({ heading: getEnterPinText(this.state.enterPin, serverResponse), phonePlaceholder: 'xxxx', inputValue: '', inputName: 'pin', pinSent: true, message: '', btnTxt: 'Continue', btnDisbaled: false  });
            }
        } else { //pin is sent so call confirm pin
            const pin = this.state.pin;
            // console.log('pin', pin);
            try {
                const url = this.apiPath + "confirmpin?telco="+this.props.telco+"&phone="+phone+"&pin="+pin;
                serverResponse = await axios.get(url, {
                    // withCredentials: true,
                    // mode: 'cors',
                    // headers: {
                    //     'Access-Control-Allow-Credentials': true
                    // }
                });
                serverResponse = serverResponse.data;
            } catch (err) { console.log("Error logging in", err); };

            // console.log("confirm pin response", serverResponse)

            if (serverResponse) {
                let generic_response = {}
                if(Object.keys(serverResponse).length === 0 && serverResponse.constructor === Object){
                    // no server response, ERROR ! login with a temporary user
                    generic_response = tempUser(phone);
                }
                else if (!serverResponse.status) {
                    this.setState({ message: serverResponse.generic_response.message, btnTxt: 'Continue', btnDisbaled: false });
                } else {
                    generic_response = serverResponse.generic_response;
                };


                if (generic_response.subscribed) {
                    this.setState({ message: '' });
                    // this.setState({ message: '', btnTxt: 'Continue', btnDisbaled: false });
                    generic_response.phone = phone;
                    localStorage.setItem('user_logged_in', true);
                    localStorage.setItem('user', JSON.stringify(generic_response));
                    loginUser(generic_response);

                    window.location.reload();
                }
            };
        };

    }


    loginValuesChange = (event) => {
        // console.log("Login value change", event.target.name, event.target.value);
        this.setState({[event.target.name]: event.target.value, inputValue: event.target.value});
    }

    // getSeriesList(){
    //     return this.state.seriesApiResp.map( (series, i) => {
    //         return(
    //             <li key={'fel-'+i}>
    //                 {/* <Link to={"/series/"+series.id}>{series.short_name}</Link> */}
    //                 {/*<a href={"/series/"+series.id}>{series.short_name}</a>*/}
    //                 {/*<Link to={"/series/"+series.id}>{series.short_name}</Link>*/}
    //                 <Link to={"/series/"+series.id+"/"+space2hyphen(series.short_name)}>{series.short_name}</Link>
    //             </li>
    //         )
    //     } )
    // }

    // getLatestUpdates(){
    //     return this.state.updateApiResp.map( (update, i) => {
    //         return(
    //             <div key={'lu-'+i} className="card-body p-0 pb-4 mb-4">
    //                 {/*<a href={"/stories/news/"+update.id}>{update.title}</a>*/}
    //                 <Link to={"/stories/news/"+update.id}>{update.title}</Link>
    //             </div>
    //         )
    //     } )
    // }

  render() {
     //IF Block Imlemented to handle unexpected NetWork Error
     return (
         <div id="left-section" className="d-none d-lg-block pr-4">
                   <div className="left-section">
                         <LeftSeriesList/>
                         <LeftUpdatesList/>
                    </div>

                    {
                        ( true ||  (this.props.userLoggedIn || !Globals.telco || Globals.disableSideLogin) ) ?
                        (
                            // dont show register-login-side if user is logged in or its a multitelco domain
                            <div></div>
                        ):
                        (
                            <div className="card mt-2">
                                <div className="p-3 register-login-side">
                                    <h5 className="mb-3">Register | Login</h5>

                                    <p className="heading mb-2">{this.state.heading}</p>

                                    <input name={this.state.inputName} className="phone bg-medium-grey px-2 mb-2 font-weight-semi-bold w-100 border-0 sub-input" type="text"
                                    value={this.state.inputValue} placeholder={this.state.phonePlaceholder}  onChange={this.loginValuesChange} onKeyPress={this.handleKeypress} />

                                    <p className="m-0 message py-2 text-danger">{this.state.message}</p>

                                    <input type="hidden" name="flow" value={this.state.task} />

                                    <button className="submit btn sub-btn text-white w-100 text-uppercase border-0 rounded-0" onClick={this.login} disabled={this.state.btnDisbaled} >
                                        <h5 className="mb-0">{this.state.btnTxt}</h5>
                                    </button>
                                </div>
                            </div>
                        )
                    }

                </div>
      );
  }
}

export default Leftsection;

// const  LoadLeftSectionComponents = Loadable({
//     loader :  ()=>import('./components/leftSeriesList'),
//     loading : ()=>(<div>Loading</div>)
//  });
