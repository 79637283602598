import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { MatchScorecard, returnDashIfNull } from './Match'; 

import { VideoCard, Scorecard, NewsBulletins, NewsCard, FullWidthCard, space2hyphen, MatchScorecardSection } from './HelperFunctions';

class HomeSeriesCard extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            newsBulletins: [],
            matches: [],
            match: [],
            articles: [],
            seriesObj: {},
            isMatchLive: false,
            isMounted: false,
            isUpdated: false
        };
        this.setSeries = this.setSeries.bind(this);
    };

    setSeries(isUpdated) {

        console.log("Home series card props", this.props)

        let videos = [],
            newsBulletins = [],
            articles = [], 
            matches = [],
            match = [],
            isMatchLive = false,
            seriesObj = this.props.data.series_obj,
            data = this.props.data.data.slice();

        return data.map( (obj, i) => {
            
            switch(obj.type) {

              case 'videos':
                // videos = val.data.slice();
                return (obj.data.length) ?
                <div key={"ser-v-"+i} className="py-2 mb-3 border-bottom"> {obj.data.map( (val, j) => {
                    if (j===0) {
                        return <div key={"outersv-"+j} className="pt-2"><FullWidthCard type="video" 
                        seriesId={seriesObj.id}
                        seriesTitle={seriesObj.short_name}
                        subType="video" data={val}></FullWidthCard></div>
                    } else {
                        
                        return <div key={"outersv-"+j} className="py-2"><VideoCard 
                        key={"sv-"+j} 
                        image={val.thumb} 
                        id={val.id}
                        seriesId={seriesObj.id}
                        seriesTitle={seriesObj.short_name}
                        seriesPropsId={this.props.seriesId}
                        matchTitle={val.match_obj.title}
                        title={val.title}
                        description={val.match_desc}
                        showDescription={true}>
                        </VideoCard></div>
                    }
                        
                    }
                
                )
                }</div> : <div key={"ser-v-"+i}></div> 
              case 'news':
                // newsBulletins = val.data.slice();
                return <NewsBulletins key={'newsb-'+i} news={obj.data}></NewsBulletins>
              case 'matches':
                // isMatchLive = obj.data.findIndex(obj2 => obj2.match_state.toLowerCase() === 'live') > -1;
                return obj.data.map( (val, j) =>
                    <Scorecard key={"ms-hs-"+j} data={val} />
                ) 
              case 'match_object':
                // match.push(val.data);
                // match = val.data.slice();
                // isMatchLive = obj.data.findIndex(obj2 => obj2.match_state.toLowerCase() === 'live') > -1;
                return <Scorecard key={"ms-hs-"+i} data={obj.data} /> 
                // return obj.data.map( (val, j) =>
                //     <Scorecard key={"ms-hs-"+j} data={val} />
                // ) 
              case 'article':
                // articles = val.data.slice();
                return obj.data.map( (val, j) =>
                    <div key={"nc-"+j} className="pt-4 px-3">
                    <NewsCard image={val.image} id={val.id} title={val.title} date={val.created_at}></NewsCard>
                    </div>
                ) 
              case 'articles':
                // articles = val.data.slice();
                return obj.data.map( (val, j) =>
                    <div key={"nc-"+j} className="pt-4 px-3">
                    <NewsCard image={val.image} id={val.id} title={val.title} date={val.created_at}></NewsCard>
                    </div>
                )  
              case 'tweets':
                return obj.data.map( (val, j) => 
                    <a key={"tweet-s-"+j} href={val.url} target="_blank" rel="noopener noreferrer" key={"tweet-"+j} dangerouslySetInnerHTML={{__html: val.body}} />
                )
              default:
                console.log("Unknown type", obj.type);
            }

        }); 

        // console.log("match array", match);

        // const seriesObj = Object.assign({}, this.props.data.series_obj);
        // console.log("Series Object", seriesObj, "props", this.props);

        // this.setState({ videos: videos, newsBulletins: newsBulletins, matches: matches, articles: articles,
        //  isMatchLive: isMatchLive, seriesObj: seriesObj, match: match, isMounted: true, isUpdated: isUpdated }, 
        //     () => console.log("Home Series set state complete", this.state));

    }

    // componentDidMount() {
    //     console.log("did mount series card", this.props);
    //     console.log("Series Card", this.props.data);
    //     if (!this.state.isMounted) {
    //         this.setSeries(false);
    //     };
    // }

    // componentDidUpdate() {
    //     console.log("did update series", this.props);
    //     if (this.state.isMounted && !this.state.isUpdated && this.state.seriesObj.hasOwnProperty('id') && (this.state.seriesObj.id !== this.props.seriesId)) {
    //         this.setSeries(true);
    //     };
    // }

  render() {
    return (
        <div className="card mb-2">
            <div className="series-card-heading mb-2">
                <Link to={"/series/"+this.props.data.series_obj.id+"/"+space2hyphen(this.props.data.series_obj.short_name)} 
                className="border-bottom d-block p-3 w-100 text-left">
                    <h4 className="font-weight-bold m-f-14 mb-0">
                    {
                        this.props.data.title
                    } 
                    {/*<span className="text-secondary"> - UAE 2018</span>*/}
                    </h4>
                </Link>
                
            </div>
            <div className="p-3">
                
                {
                    this.setSeries()
                }
                {/* {
                    (this.state.matches.length) ? 
                    this.state.matches.map( (obj, i) => <Scorecard key={"ms-hs-"+i} data={obj} /> ) :
                    <div></div>
                }
                {    
                    (this.state.match.length) ? 
                    this.state.match.map( (obj, i) => <Scorecard key={"ms-hs-"+i} data={obj} /> ) :
                    <div></div>
                    
                } */}
                {/* {
                    this.state.match.length ? 
                    <MatchScorecard data={this.state.match[0]}></MatchScorecard> 
                    : returnScorecardIfLiveMatch(this.state.matches)
                } */}
                {/* {
                    (!this.state.isMatchLive || this.state.match.length) ?
                    <FullWidthCard type='video' seriesId={this.state.seriesObj.id} 
                    seriesPropsId={this.props.seriesId}
                    seriesTitle={this.state.seriesObj.short_name}
                    data={this.state.videos[0]}></FullWidthCard> :
                    <div></div>
                }
                {
                    (this.state.newsBulletins.length) ? <NewsBulletins news={this.state.newsBulletins}></NewsBulletins> : <div></div>
                }
                {
                    this.state.videos.map( (obj, i) => 
                        (!this.state.isMatchLive && i!==0) ?
                        <VideoCard 
                        key={"sv-"+i} 
                        image={obj.thumb} 
                        id={obj.id}
                        seriesId={this.state.seriesObj.id}
                        seriesTitle={this.state.seriesObj.short_name}
                        seriesPropsId={this.props.seriesId}
                        matchTitle={obj.match_obj.title}
                        title={obj.title}
                        description={obj.match_desc}
                        showDescription={true}>
                        </VideoCard>
                        : <div key={"sv-"+i}></div>
                    )
                }
                {
                    this.state.articles.length && this.state.videos.length ?
                    <div className="mt-3 mb-3 border-top"></div>
                    : <div></div>
                }
                {
                    this.state.articles.map( (obj, i) => 
                        <NewsCard key={"nc-"+i} image={obj.image} id={obj.id} title={obj.title} date={obj.created_at}></NewsCard>)
                } */}
            </div>
            {
            (!this.props.isSeriesHome) ?
            <div className="d-flex justify-content-around series-btn-menu">
                <Link to={"/series/"+this.props.data.series_obj.id+"/"+space2hyphen(this.props.data.series_obj.short_name)} className="btn bg-white border-top w-100 py-3 rounded-0">
                    <h5 className="mb-0 font-weight-bold m-f-13">Series Home</h5>
                </Link>
                {
                    (this.props.data.series_obj.is_videos_enabled) ? 
                    <Link to={"/series/"+this.props.data.series_obj.id+"/"+space2hyphen(this.props.data.series_obj.short_name)+"/videos"} className="btn bg-white border-top border-left w-100 py-3 rounded-0">
                        <h5 className="mb-0  font-weight-bold m-f-13">Videos</h5>
                    </Link> : <div></div>
                }
                {
                    (this.props.data.series_obj.has_points_table) ?
                    <Link to={"/series/"+this.props.data.series_obj.id+"/"+space2hyphen(this.props.data.series_obj.short_name)+"/pointstable"} className="btn bg-white border-top border-left w-100 py-3 rounded-0">
                        <h5 className="mb-0  font-weight-bold m-f-13">Points Table</h5>
                    </Link> : <div></div>
                }
            </div> : <div></div>
            }
        </div>
    );
  }
}

export default HomeSeriesCard;
