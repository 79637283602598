import React, { Component } from 'react';
import { MatchNav, BallByBallDetail, BallByBall } from './Match';
import { SeriesNav } from './Series';
import axios from 'axios';
import { Globals } from './constants';
import { getDate, getMonth } from './AllSeries';
import { hyphen2space, getMatchStateText, MatchRunsAndWicketsExpression, getDateFunc, getFlagUrl } from './HelperFunctions';
import { Link } from "react-router-dom";


class SeriesSchedules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesTitle: '',
            seriesResp: [],
            seriesId: this.props.match.params.id,
            series: [],
            isLoading: true,
            page: 1,
            pagesEnd: false
        };
        // this.setSeriesFromApi(this.state.seriesId);
        this.nextPage = this.nextPage.bind(this);
    };

    async componentDidMount() {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

        let seriesTitle = this.props.match.params.title,
        page = this.state.page;

        this.setState({seriesTitle: seriesTitle});
        // const url = Globals.ApiUrl + "api/series_schedule/"+this.state.seriesId+"/schedule?page="+page;
        const url = Globals.ApiUrl + "api/v1/main/series_matches/"+this.state.seriesId+"/"+page;
        try {
            let resp = await axios.get(url);
            // if (resp.status!==200) window.location.href = '/404notfound';
            resp = resp.data;

            let mergedresp= [];
            mergedresp = resp.matches
            // if (resp.recent_matches.length) {
            //     mergedresp = resp.recent_matches;
            // }
            // if (resp.live_matches.length) {
            //     mergedresp = mergedresp.concat(resp.live_matches)
            // }
            // if (resp.upcoming_matches.length) {
            //     mergedresp = mergedresp.concat(resp.upcoming_matches)
            // }
            console.log("merged",mergedresp);

            // mergedresp = resp.recent_matches.concat(resp.live_matches).concat(resp.upcoming_matches);
            this.setState({ series: mergedresp, isLoading: false }, () => console.log("series merge", this.state));
        }
        catch (err) {
            console.log(err);
            // window.location.href = '/404notfound';
        }
    }

    async nextPage(e) {
        if (!this.state.pagesEnd) {
            let elem = document.getElementsByClassName('loading-more-results');
            if(elem.length)
                elem[0].classList.remove('d-none');

            const page = this.state.page + 1;
            // const url = Globals.ApiUrl+"api/series_schedule/"+this.state.seriesId+"/schedule?page="+page;
            const url = Globals.ApiUrl + "api/v1/main/series_matches/"+this.state.seriesId+"/"+page;


            try {
                let resp = await axios.get(url);
                console.log("series schedule2", resp.data.matches)
                // if (resp.status!==200) window.location.href = '/404notfound';
                // if (resp.status === 200 && (resp.data.recent_matches.length > 0 || resp.data.upcoming_matches.length > 0 || resp.data.live_matches.length > 0 ) ) {
                if (resp.data.matches.length > 0 ) {
                    if(elem.length)
                        elem[0].classList.add('d-none');

                    resp = resp.data;
                    let mergedresp= [];
                    mergedresp = resp.matches;

                    // if (resp.recent_matches.length) {
                    //     mergedresp = resp.recent_matches;
                    // }
                    // if (resp.live_matches.length) {
                    //     mergedresp = mergedresp.concat(resp.live_matches)
                    // }
                    // if (resp.upcoming_matches.length) {
                    //     mergedresp = mergedresp.concat(resp.upcoming_matches)
                    // }
                    // // mergedresp = resp.recent_matches.concat(resp.live_matches).concat(resp.upcoming_matches);
                    const mergedresp2 = this.state.series.concat(mergedresp);
                    this.setState({page: page, series: mergedresp2, isLoading: false }, () => console.log("series merge", this.state));
                }else{
                    if(elem.length)
                        elem[0].classList.add('d-none');

                    if ((resp.data.matches.length === 0))
                        this.setState({pagesEnd: true});

                    console.log("Api Error or no results");
                }

            }
            catch (err) {
                console.log(err);
                // window.location.href = '/404notfound';
            }
        }
    }

    async setSeriesFromApi(id) {
        let seriesId = id;
        const url = Globals.ApiUrl+"api/v2/view_lists/get_list_items_from_viewable?viewable_type=series&viewable_id="+seriesId+"&page=1&telco="+this.props.telco;
        let resp = [];
        console.log("Series Id", id)
        try {
            resp = await axios.get(url);
            console.log("series resp", resp)
            if (resp.status!==200) window.location.href = '/404notfound';

            const seriesRespCopy = resp.data.slice();
            this.setState({seriesResp: seriesRespCopy, seriesId: id, isLoading: false}, () => console.log("Series set state", this.state));
        }
        catch (err) {
            console.log("Error", err);
            window.location.href = '/404notfound';
        };

    }


  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }
            <div className="card p-3">
                <h4 className="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">{hyphen2space(this.state.seriesTitle)}</h4>
                <SeriesNav path={this.props.match}></SeriesNav>
            </div>
            <div className="card px-3 py-3 mt-2">


            {
            this.state.series.map( (obj, i) => {
                let winningTeamid = obj.match_won_by_id;
                let team1_innings = 0, team2_innings = 0, total_innings=0;
                obj.innings.forEach(function (inning){
                    if(inning.batting_team_id == obj.team_1_id){
                        team1_innings++;
                    }else{
                        team2_innings++;
                    }
                    total_innings++;
                })

                return (
                <div key={"ss-"+i} className="schedule custom-box p-3 p-lg-4 mb-3">
                    <Link
                        to={"/match/"+obj.id+"/summary/1"}
                        onClick={e => ( obj.match_state.toLowerCase() == 'scheduled' )?e.preventDefault():"" }
                        style={{cursor: ( obj.match_state.toLowerCase() == 'scheduled')? 'auto' : 'pointer'}}
                    >
                        <div className="schedule-date d-flex mb-1 mb-lg-2">
                            <div className="text-medium-grey">
                                <h3 className="font-weight-normal mb-0">
                                    {
                                        // getDate(obj.series.start_date) + ' ' + getMonth(obj.series.start_date)
                                        // + ' - '
                                        // + getDate(obj.series.end_date) + ' ' + getMonth(obj.series.end_date)
                                        getDateFunc(obj.match_start)
                                    }
                                </h3>
                            </div>
                            <div className="ml-auto text-red"><h3 className="mb-0">
                            {getMatchStateText(obj)}
                            </h3></div>
                        </div>

                        <div className="schedule-detail d-flex mb-3 mb-lg-5">
                            <div className="text-black"><h3 className="font-weight-normal mb-0">
                            { (obj.venue) ? obj.title +' - '+ obj.venue.title : obj.title}</h3></div>
                        </div>

                        <div className="schedule-teams d-flex">

                            <div className="team1">
                                <div className="d-flex">
                                    <div className="w-120px">

                                        <div className="one-by-one-square bg-center-cover" style={{backgroundImage: "url(" + getFlagUrl(obj.teamA) + ")"}}>

                                        </div>

                                    </div>
                                    <div className="align-self-center text-medium-grey ml-2 ml-lg-3">
                                        {/*<h5 className="font-weight-bold mb-1">Zim 'U19 B W </h5>
                                        <h4 className="font-weight-normal mb-1">482</h4>
                                        <h4 className="font-weight-normal mb-0">181/6d</h4> */}
                                        {MatchScorecardInningsDetails(obj, 1, team1_innings, team2_innings, winningTeamid, total_innings)}
                                    </div>
                                </div>
                            </div>

                            <div className="team2 ml-auto">
                                <div className="d-flex">
                                    <div className="align-self-center text-right text-medium-grey  mr-2 mr-lg-3">
                                        {MatchScorecardInningsDetails(obj, 2, team1_innings, team2_innings, winningTeamid, total_innings)}
                                    </div>
                                    <div className="w-120px">

                                        <div className="one-by-one-square bg-center-cover" style={{backgroundImage: "url(" + getFlagUrl(obj.teamB) + ")"}}>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="schedule-footer text-black text-center mt-2">
                            <h3 className="mb-0">{obj.match_result || obj.match_news}</h3>
                        </div>
                    </Link>
                </div>
                )
            }

            )
            }

            {
                (!this.state.pagesEnd && this.state.series.length) ?
                (
                <div>
                    <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                    <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
                </div>
                )
                : <div></div>
            }

            </div>
            {/* <!-- card--> */}
        </section>


    );
  }
}
const returnDashIfNull = (props) => (!props) ? '0' : props;


const MatchRunsAndWicketsExpressionThis = (obj, i, teamTotalInnings, winningTeamid, totalInningsCount) =>
(i===0 || i===1) ?
(
    <span key={'mrawe'+i}  className={ ( (winningTeamid && winningTeamid == obj.batting_team_id ) || (!winningTeamid && (i == totalInningsCount-1) ) )?'text-black' : '' } >
       <span dangerouslySetInnerHTML={{__html: MatchRunsAndWicketsExpression(obj, i, totalInningsCount, 1)}}></span>
    </span>
) :
(
    <span key={'mrawe'+i} >
        <span className={ ( (winningTeamid && winningTeamid == obj.batting_team_id ) || (!winningTeamid && (i == totalInningsCount-1)) )? 'text-black' : '' }>
        <span dangerouslySetInnerHTML={{__html: MatchRunsAndWicketsExpression(obj, i, totalInningsCount, 1)}}></span>
        </span>
    </span>
);

const MatchScorecardInningsDetails = (props, teamNumber, team1_innings_count, team2_innings_count, winningTeamid, total_innings_count) => {
    return <div className="px-2">
        <h5 className="text-uppercase mb-0 m-f-11">
        {
            (teamNumber===1 && props.hasOwnProperty('team_1')) ?
            (
                <span className={ ((winningTeamid && winningTeamid == props.team_1_id) || (!winningTeamid && props.innings.length && props.innings[props.innings.length-1].batting_team_id == props.team_1_id) )?'text-black' :'' } >{props.team_1.team.short_name}</span>
            ) : ''
        }
        {
            (teamNumber===2 && props.hasOwnProperty('team_2')) ?
            (
                <span className={ ( (winningTeamid && winningTeamid == props.team_2_id)  || (!winningTeamid && props.innings.length && props.innings[props.innings.length-1].batting_team_id == props.team_2_id) )?'text-black' :'' }>{props.team_2.team.short_name}</span>
            ): ''
        }
        </h5>
        <h5 className="mb-0 m-f-11">
        {
            (props.innings) ? props.innings.map( (obj, i) => {

                if (teamNumber===1 && obj.batting_team_id===props.team_1_id) {
                    return MatchRunsAndWicketsExpressionThis(obj, i, team1_innings_count, winningTeamid, total_innings_count)
                } else if (teamNumber===2 && obj.batting_team_id===props.team_2_id) {
                    return (
                        MatchRunsAndWicketsExpressionThis(obj, i, team2_innings_count, winningTeamid, total_innings_count)
                    )
                };
                return false;
            }) : ''
        }
        </h5>
    </div>
}

export default SeriesSchedules;
