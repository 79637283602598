import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { VideoCardDescription } from './HelperFunctions';
import axios from 'axios';
import BackgroundImage from 'react-background-image-loader';
import { Globals } from './constants';

class Stories extends Component {
	constructor(props) { 
        super(props);
        this.state = {
            customCssTransition: 'h-transition',
            isLoading: true,
            apiPath: Globals.ApiUrl+"api/",
            page: 1,
            per_page: 6,
            pagesEnd: false,
            articles: [],
            type: '',
            label: '',
            articlesClass: '',
            newsClass: ''
        };

        this.nextPage = this.nextPage.bind(this);
    };

    async componentDidMount () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          
        let type, label;
        if(this.props.type && this.props.type === 'articles'){
            type="articles"; label="Article";
            this.setState({type: type, label: label, articlesClass: 'border-bottom border-danger', newsClass: ''});
        }else{
            type="news"; label = "News";
            this.setState({type: type, label: label, newsClass: 'border-bottom border-danger', articlesClass:''});
        }

        let apiResp,
        articles = [],
        apiPath = this.state.apiPath,
        page = this.state.page,
        per_page = this.state.per_page,
        url = apiPath+type+"?page="+page.toString()+"&page_size="+per_page;
        
        try{
            apiResp = await axios.get(url);
        }
        catch(err){
            console.log('Error fetching articles');
            window.location.href = '/404notfound';
        }

        if(apiResp.status == 200){
            articles = apiResp.data.data;
            console.log(articles);
            this.setState({articles: articles, customCssTransition: 'v-transition', isLoading: false},
                () => console.log("stories articles state completed"));
        }else{
            console.log('Error fetching articles');
            window.location.href = '/404notfound';
        }
    };

    getArticles(){
        return this.state.articles.map( (article, i) => {
            return (
                <div className="mt-3">
                    <Link key={'articles-'+i} className="" to={"/stories/"+this.state.type+"/"+article.id} >
                        <StoriesCard2 data={article} image={this.state.newsImage} type={this.state.label}></StoriesCard2>
                    </Link>
                </div>
                
            )
        } );
    }

    async nextPage(e){
        if(!this.state.pagesEnd){
            let elem = document.getElementsByClassName('loading-more-results');
            if(elem.length)
                elem[0].classList.remove('d-none');

            let resp,
            articles= this.state.articles,
            apiPath = this.state.apiPath,
            page = this.state.page +1,
            per_page = this.state.per_page,
            type = this.state.type,
            url = apiPath+type+"?page="+page.toString()+"&page_size="+per_page;

            try {
                resp = await axios.get(url);
                console.log("Next page", resp);
            }
            catch(err) {
            console.log("error fetching next page");
            };

            if(resp.status == 200 && resp.data.data.length > 0){
                if(elem.length)
                    elem[0].classList.add('d-none');

                const Articles = this.state.articles.slice();
                resp.data.data.forEach((obj, i) => {
                    Articles.push(obj);
                });
                this.setState({page: page, articles: Articles}, () => console.log("Next page state set", this.state));
            }else{
                if(elem.length)
                    elem[0].classList.add('d-none');
                    
                if (resp.data.length===0) this.setState({pagesEnd: true});
                console.log("error fetching next page");
            }

        }
    }

  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">

        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }

        <div className={this.state.customCssTransition}>

          <div className="card p-3">
            <div className="d-flex overflow-x-auto ">
                <h4 className={this.state.newsClass+" font-weight-semi-bold p-2 m-f-14"}>
                    <Link className="alert-heading" to="/stories/news">Latest News</Link>
                </h4>
                <h4 className={this.state.articlesClass+" font-weight-semi-bold p-2 m-f-14 ml-4"}>
                    <Link className="alert-heading" to="/stories/articles">Articles</Link>
                </h4>
                {/* <h4 className="font-weight-semi-bold p-2 ml-4 text-secondary">Articles</h4> */}
            </div>
          </div>

          <div className="card mt-2 p-3 stories-list-card">
          	<div className="pt-3">
    			<h4 className="text-uppercase text-black font-weight-normal mb-4 m-f-14">{(this.state.label ==='News')? 'Latest News': 'Articles'}</h4>
                {
                    (this.state.articles && this.state.articles.length > 0)?
                    ( this.getArticles() ):
                    ( <div></div> )
                }
	          	{/* <StoriesCard image={this.state.newsImage}></StoriesCard> */}
	          	{/* <StoriesCard image={this.state.newsImage2}></StoriesCard>
	          	<StoriesCard image={this.state.videosImage}></StoriesCard> */}
	          	{/* <div className="row p-3">
		          	<StoriesFullWidthCardSm image={this.state.videosImage2}></StoriesFullWidthCardSm>
		          	<StoriesFullWidthCardSm image={this.state.videosImage3}></StoriesFullWidthCardSm>
	          	</div>
	          	<div className="row">

	          		<StoriesFullWidthCard image={this.state.newsImage}></StoriesFullWidthCard>
          		</div> */}
          	</div>
          </div>
          {
            (!this.state.pagesEnd && this.state.articles.length) ?
            (
                <div>
                    <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif?v=2" alt="" height="30" /></div>
                    <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
                </div>
            )
            : <div></div>
          }
          </div>
        </section>


    );
  }
}

const StoriesFullWidthCard = (props) => (
	<div className="col-12">
		<div className="w-100 position-relative">
			<div className="sixteen-by-nine bg-center-cover" style={props.image}></div>
			<div className="fa fa-play play-btn-lg"></div>
		</div>
		<div className="py-2">
			<h5 className="font-weight-normal text-red">Match Reports</h5>
			<h5 className="font-weight-normal text-secondary">Pakistan vs Australia in UAE</h5>
			<h3 className="text-black">A case for Pakistan to play all three openers in UAE?</h3>
		</div>
	</div>
);

const StoriesFullWidthCardSm = (props) => (
	<div className="col-6 p-1">
		<div className="w-100 position-relative">
			<div className="sixteen-by-nine bg-center-cover" style={props.image}></div>
			<div className="fa fa-play play-btn-lg play-btn-sm"></div>
		</div>
		<div className="py-2">
			<h5 className="font-weight-normal text-red">Match Reports</h5>
			<h5 className="font-weight-normal text-secondary">Pakistan vs Australia in UAE</h5>
			<h5 className="font-weight-normal text-black">A case for Pakistan to play all three openers in UAE?</h5>
		</div>
	</div>
);

const getSummary = (props) => {
    let summary;
    if(props.data.summary){
        summary = props.data.summary
    }else if(props.data.body){
        summary = stripTags(props.data.body);
    }
    return summary;
}

const stripTags = (string) => {
    var StrippedString = string.replace(/(<([^>]+)>)/ig,"");
    return StrippedString;
}

export const StoriesCard = (props) => (

	<div className="d-flex my-2">
        <div className="w-30">
            <BackgroundImage className="four-by-three bg-center-cover" src={props.data.image} placeholder={Globals.placeholder4x3}>
            </BackgroundImage>
        </div> 
        <div className="pl-3 flex-fill">
            <VideoCardDescription 
                showDescription={true} showDate={true} showRedTitle={props.type} 
                data={props.data} title={props.data.title} description={ getSummary(props)}  ></VideoCardDescription>
    	</div>
    </div>

);

export const StoriesCard2 = (props) => (

	<div className="d-flex my-2">
        <div className="w-30">
            <BackgroundImage className="four-by-three bg-center-cover" src={props.data.image} placeholder={Globals.placeholder4x3}>
            </BackgroundImage> 
        </div> 
        <div className="pl-3 flex-fill">
            <VideoCardDescription 
                showDescription={true} showDate={true} showRedTitle={true} 
                data={props.data} title={props.data.title} description={ getSummary(props)}  ></VideoCardDescription>
    	</div>
    </div>

);

export default Stories;
